import dayjs from 'dayjs';

/**
 * 검색에서의 기간(시작 일, 종료 일, max일)을 리턴
 * @returns Array<string> - [시작일, 종료일, max일]
 */
export const getPeriodDate = () => {
    let strDay = new Date();
    let endDay = new Date(strDay.getFullYear(), strDay.getMonth() + 1, 0);
    let maxDay = new Date(strDay.getFullYear(), strDay.getMonth() + 1, strDay.getDate());

    return [
        // 시작 일
        dayjs(strDay),
        // 종료 일
        dayjs(endDay),
        // max 일
        dayjs(maxDay)
    ];
};

/**
 * parameter date 기준으로 한 달(max date) 리턴
 * @returns max date
 */
export const getMaxDate = (date = null) => {
    let pivotDate = new Date(date);
    let maxDay = new Date(pivotDate.getFullYear(), pivotDate.getMonth() + 1, pivotDate.getDate());

    return dayjs(maxDay);
};

/**
 * 타임아웃 인스턴스 정보
 * @returns - 타임아웃 인스턴스별 번호 정보(key-value형태로 저장)
 */
export const timeoutInstcs = () => {
    return {};
};

/**
 * 타임아웃 인스턴스 clear
 * @param {string} target - 타겟 인스턴스 명
 */
export const clearTimeoutInstcs = (target) => {
    if(!!timeoutInstcs[target]) {
      clearTimeout(timeoutInstcs[target]);
      timeoutInstcs[target] = null;
    }
};

/**
 * From/To Time 리스트 리턴
 * @returns Array<Array<string>> - [fromtimeList, totimeList]
 */
export const getFromToTimeList = () => {
    //From, To 현재시간 기준 30분간격 select box 
    let fromtimeList = [];
    let totimeList = [];
    //현재 시간 및 일자
    let offset = new Date().getTimezoneOffset() * 60000;
    let koreaNow = new Date(Date.now() - offset);

    for (let i = 0; i < 48; i++) {
        const op = {};

        //시간
        const time = koreaNow.toISOString().slice(11, 16);
        koreaNow.setMinutes(koreaNow.getMinutes() + 30);

        op.value = time;
        op.label = time;


        fromtimeList.push(op);
        totimeList.push(op);
    }
    return [fromtimeList, totimeList];
};