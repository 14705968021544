import React, { useState, useEffect, createContext } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

// Css
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Util
import { get } from 'utils/api';

// Page
import { Main, Error, MyWork } from './pages';

// Component
import { AlertDialog, DetailModal, CreateModal, UpdateModal, WorkModal} from 'components';


import { app, Context } from "@microsoft/teams-js";

// 공통 컨텍스트 생성
export const CommonCtx = createContext();

function App() {

    //로그인 유저 정보 
    const [userId, setUserId] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    
  const userInfo = async () => {
    let UserRst;  
    const getToken = searchParams.get('token'); // id 취득

    //JIRA 접근
    if (location.search.includes('?token=')) {
      UserRst = await get('jira/loginUser', { "token": getToken })

      if(UserRst == "ntoken" ){
        setUserId(null);
      }else{
        setUserId(UserRst);
      }

      //Teams 접근
    } else {
      app.initialize().then((res) => {
        app.getContext().then(async (context) => {
          UserRst = await get('loginUser', { "email": context.user.userPrincipalName })
          setUserId(UserRst);
          if (UserRst === false) {
            //프로그래바 닫기
            setIsCallApi(false);
          }
        });
      }).catch((error) => {
        setUserId(false);
        //프로그래바 닫기
        setIsCallApi(false);
      });
    }
  }
      /* Hooks */
  useEffect(() => {
     userInfo();
 }, []);


  const [isCallApi, setIsCallApi] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState({ 
    title: null, 
    content: null, 
    isOpen: false, 
    isBackdropClose: false, 
    btnInfo: null
  });
  const [errAlertInfo, setErrAlertInfo] = useState({open: false, msg: '오류가 발생하였습니다.'});
  const [dtlModalInfo, setDtlModalInfo] = useState({
    isOpen: false,
    childNode: (
      <div>test2</div>
    ),
    isBackdropClose: true, 
    sx: null
  });
  const [ModalInfo, setModalInfo] = useState({
    isOpen: false,
    childNode: null,
    isBackdropClose: true, 
    sx: null,
    btnInfo: null
  });
  const [upModalInfo, setupModalInfo] = useState({
    isOpen: false,
    childNode: null,
    isBackdropClose: true, 
    sx: null,
    btnInfo: null
  });
  const [WorkModalInfo, setWorkModalInfo] = useState({
    isOpen: false,
    childNode: null,
    isBackdropClose: true,
    sx: null,
    btnInfo: null
  });



  return  (
    <>
      {/* 프로그래스 */}
      {<Backdrop
        sx={{ backgroundColor: '#D3D3D370', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCallApi}
      >
        <CircularProgress />
      </Backdrop>}
      
      {/* alert dialog */}
      {<AlertDialog 
        title={alertDialogInfo?.title}
        content={alertDialogInfo?.content}
        isOpen={alertDialogInfo?.isOpen}
        isBackdropClose={alertDialogInfo?.isBackdropClose}
        btnInfo={alertDialogInfo?.btnInfo}
        onClose={(isClose) => setAlertDialogInfo({isOpen: !isClose})}
      />}

      {/* error alert  */}
      {errAlertInfo?.open 
        && (
          <Stack 
            sx={{ width: '35%', zIndex: 100, position: 'absolute', top: '40%', right: '25%' }} 
            spacing={0}
          >
            <Alert 
              variant="standard" 
              severity="success"
            >
              {errAlertInfo?.msg || '오류가 발생하였습니다.'}
            </Alert>
          </Stack>
        )}
      
      {/* 디테일 모달 */}
      <DetailModal
        isOpen={dtlModalInfo.isOpen}
        isClose={dtlModalInfo.isClose}
        childNode={dtlModalInfo.childNode}
        isBackdropClose={dtlModalInfo.isBackdropClose} 
        onClose={(isClose) => setDtlModalInfo({isOpen: !isClose})}
        sx={dtlModalInfo.sx}
      />

      {/* 시간기록 추가 모달 */}
      <CreateModal
            isOpen={ModalInfo.isOpen}
            isClose={ModalInfo.isClose}
            childNode={ModalInfo.childNode}
            isBackdropClose={ModalInfo.isBackdropClose} 
            onClose={(isClose) => setModalInfo({isOpen: !isClose})}
            sx={ModalInfo.sx}
            btnInfo={ModalInfo?.btnInfo}
          />

      {/* 시간기록 추가 모달 */}
      <UpdateModal
            isOpen={upModalInfo.isOpen}
            isClose={upModalInfo.isClose}
            childNode={upModalInfo.childNode}
            isBackdropClose={upModalInfo.isBackdropClose} 
            onClose={(isClose) => setupModalInfo({isOpen: !isClose})}
            sx={upModalInfo.sx}
            btnInfo={upModalInfo?.btnInfo}
          />


      {/* 근태 등록 모달 */}
      <WorkModal
        isOpen={WorkModalInfo.isOpen}
        childNode={WorkModalInfo.childNode}
        isBackdropClose={WorkModalInfo.isBackdropClose}
        onClose={(isClose) => setWorkModalInfo({ isOpen: !isClose })}
        sx={WorkModalInfo.sx}
        btnInfo={WorkModalInfo?.btnInfo}
      />  
      {/* 라우트 */}
      <CommonCtx.Provider value={{ setIsCallApi, setAlertDialogInfo, setErrAlertInfo, setDtlModalInfo , setModalInfo, setupModalInfo, setWorkModalInfo, userId }}>
        <Routes>
        {userId === false ?
        <Route path="/*" element={<Error /> } />
        : 
        <Route path="/*" element={<Main /> }/> 
        }
        

        {userId === false ?
        <Route path="/*" element={<Error /> } />
        : 
        <Route path="/mywork/*" element={<MyWork /> }/> 
        }

        </Routes>
      </CommonCtx.Provider>
    </>
  );
};

export default App;
