import React from "react";

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css'; // needs additional webpack config!



import { Calendar } from '@fullcalendar/core'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from "@fullcalendar/interaction";


// mian import 추가
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AgGridReact } from 'ag-grid-react';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import ReactSelect, { components } from 'react-select';

import Work from '../Work/Work.js'

// Component
import { CommonCtx } from 'App';
import { SearchSelect, SearchOneSelect } from 'components';

// Css
import './MyWork.css';
import styles from './MyWork.module.css';

// Util
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import _ from 'lodash';
import { post, get, getuserId } from 'utils/api';
import { clearTimeoutInstcs, getFromToTimeList, getPeriodDate, timeoutInstcs, getMaxDate } from 'utils/utils';
import { duration, OutlinedInput } from '@mui/material';

import { app, Context } from "@microsoft/teams-js";
import { ConstructionOutlined, DeblurOutlined } from '@mui/icons-material';
import { Await } from 'react-router-dom';

import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';

//tooltip
import tippy from "tippy.js";

import ReactDOM from 'react-dom';


import bootstrapPlugin from '@fullcalendar/bootstrap';

//한글 적용
import'dayjs/locale/ko';

//import ReactDOM from 'react-dom/client';
// must manually import the stylesheets for each plugin
//import "@fullcalendar/core/main.css";
//import "@fullcalendar/daygrid/main.css";
//import "@fullcalendar/timegrid/main.css";

import $, { data } from "jquery";
import { Color } from "@ag-grid-community/all-modules";


const MyWork = (props) => {

  const calendarRef = useRef();

  // 셀 mouser over 시, 셀 row 시간을 나타냄.
  $(document).on({


    mouseenter: function() {
      
        let cellWidth = $('th.fc-col-header-cell').width();
        let cellHeight = $(this).height();
        let columnCount = $('thead table.fc-col-header th.fc-col-header-cell').children().length;

        if (!$(this).html()) {
            for (var i = 0; i < columnCount; i++) {
                $(this).append('<td class="temp-cell" style="border:0px; height:' + (cellHeight - 1) + 'px;width:' + (cellWidth + 3) + 'px"></td>');
            }
        }
        $(this).children('td').each(function() {
            $(this).hover(function() {
                let dtime = $(this).parent().data('time').slice(0, -3);
                $(this).html('<div class="current-time">' + dtime + '</div>');
            }, function() {
                $(this).html('');
            });
        });

    },

    mouseleave: function() {
        $(this).children('.temp-cell').remove();
    }

}, 'td.fc-timegrid-slot.fc-timegrid-slot-lane');

  // 일주일 날짜 카운트
  // const [dayCount, setDayCount] = useState(0);
  let dayCount = 0;
  let count = 0;
  let flg = true;

  // 현재 날짜
  // const [nowDay, setNowDay] = useState('');
  let nowDay = '';


  //main 
  let selectCtgOptions = [
    { value: "분석(Study)", label: "분석(Study)" },
    { value: "설계(Design)", label: "설계(Design)" },
    { value: "개발(Development)", label: "개발(Development)" },
    { value: "테스트(Test)", label: "테스트(Test)" },
    { value: "배포(Deployment)", label: "배포(Deployment)" },
    { value: "기타(ETC)", label: "기타(ETC)" }
  ]

  const { setIsCallApi, setAlertDialogInfo, setErrAlertInfo, setDtlModalInfo, setModalInfo, setupModalInfo, setWorkModalInfo, userId } = useContext(CommonCtx);


  //이슈 검색 시 GET CONTEXT USERID 
  const oneselectuserid = userId;

  //주간 근태 리스트
  const [caldata, setCalData] = useState([]);

  //Datail select data
  const [rowDtlData, setRowDtlData] = useState([]);

  //캘린더 전체 data (기간 , 달력 종류 등)
  const [allCalData, setAllCalData] = useState([]);

  
  //주간 근무 시간 리스트 2023-08-24 4 / 8 
  const [weekSumTiem, setWeekSumTime] = useState([]);

  //근무 시간 businessHours 셋팅
  const [busHours, setBusHours] = useState([]);

  /* Hooks */
  // 디폴트 훅

  useEffect(() => {
  }, [userId, allCalData,weekSumTiem,nowDay]);


  

  //현재 날짜 기준 ,주간 근태 리스트 가져오기
  const getCurrentMonth = async (arg) => {

    //딜레이 true
    setIsCallApi(true)

    //현재 캘린더 정보 셋팅
    setAllCalData(arg)

    //arg.view.type="dayGridMonth" // 월달력
    //arg.view.type="timeGridWeek" // 타임달력

    //시작 일자
    let startdate = dayjs(arg.start);

    //종료 일자 (종료 일자의 경우 +1day 00시로 가져오기 때문에 -1day 처리)
    let enddate = dayjs(arg.end).add(-1, 'day');


    //시작 일자 YYYY-MM-DD 포맷 처리 
    let startDateChk = startdate.format("YYYY-MM-DD");

    //종료 일자 YYYY-MM-DD 포맷 처리 
    let endDateChk = enddate.format("YYYY-MM-DD");

    let weekTime =[];

    //근무 시간 businessHours 셋팅
    let businessHours = [];

    //주간 근태 리스트 API 호출
    let tempRst = await post('select/weekShrsDate', {
      "startDate": startDateChk,
      "endDate": endDateChk,
      "userName": String(userId)
    });

      //딜레이 false
      setIsCallApi(false)

    //주간 시간기록 리스트 API 호출
    let trtempRst = await post('select/getWeekTrWotklog', {
      "startDate": startDateChk,
      "endDate": endDateChk,
      "userName": String(userId)
    });


    //주간 SHRS(근태), TR(작업량) 데이터 가져오기 리스트 API 호출
    let totaltempRst = await post('select/getWeekShrsTrWorkLog', {
     "startDate": startDateChk,
     "endDate": endDateChk,
    "userName": String(userId)
    });

    //일주일 일자 처리 (start,end)
    let total_date = [];
    let curDate = new Date(startDateChk);
    while (curDate <= new Date(endDateChk)) {
      total_date.push(curDate.toISOString().split("T")[0]);
      curDate.setDate(curDate.getDate() + 1);
    }


    let checkshrs = [];

    //주간 근태 리스트 데이터 체크 (근태 일자가 같은 경우 , total 값 합치기)
    for (let i = 0; i < total_date.length; i++) {

            //totaltempRst.shrs 값이 없으면 0으로 셋팅
            if ( totaltempRst.shrs.length > 0){

      for (let j = 0; j < totaltempRst.shrs.length; j++) {

        if (total_date[i] == totaltempRst.shrs[j].ymd) {

          checkshrs.push({ date: total_date[i], total: totaltempRst.shrs[j].timeworked });
         break;
        } else {
          if( j == totaltempRst.shrs.length-1){
          checkshrs.push({ date: total_date[i], total: 0 });
        }
        }
      }
    }else{
      checkshrs.push({ date: total_date[i], total: 0 });
    }
    }

    let checktr = [];


    //주간 시간기록 리스트 데이터 체크 (근태 일자가 같은 경우 , total 값 합치기)
    for (let i = 0; i < total_date.length; i++) {

      //totaltempRst.tr 값이 없으면 0으로 셋팅
      if ( totaltempRst.tr.length > 0){
      for (let j = 0; j < totaltempRst.tr.length; j++) {

        if (total_date[i] == totaltempRst.tr[j].ymd) {

          checktr.push({ date: total_date[i], trtotal: totaltempRst.tr[j].timeworked });
          break;

        } else {
          if( j == totaltempRst.tr.length-1){
          checktr.push({ date: total_date[i], trtotal: 0 });
          }
        }
      }
    }else{
      checktr.push({ date: total_date[i], trtotal: 0 });
    }
    }


  
    //주간 근태, 시간기록 총합 계산 
    let weekTimeTotal = [];

    for (let i = 0; i < total_date.length; i++) {

  
        if (checkshrs[i].date == checktr[i].date) {

          weekTimeTotal.push({ date: checkshrs[i].date, total: checkshrs[i].total, trtotal: checktr[i].trtotal });

      }
    }

   



    //주간 근태 리스트 데이터가 없을 때
    if (tempRst.length == 0)
      businessHours.push({ daysOfWeek: [0, 1, 2, 3, 4, 5, 6], startTime: '00:00', endTime: '00:00' })

    //주간 근태 리스트 데이터가 있을 때
    for (let i = 0; i < tempRst.length; i++) {

      // tempRst[i].display = 'background'


      //근무 시간 businessHours 데이터 처리
      businessHours.push({ daysOfWeek: tempRst[i].daysOfWeek, startTime: tempRst[i].startTime, endTime: tempRst[i].endTime })
    }



    //근무 시간 셋팅
    setBusHours(businessHours)



    //주간 작업량 리스트 API 호출
    let tempRst2 = await post('select/weekTrDate', {
      "startDate": startDateChk,
      "endDate": endDateChk,
      "userName": String(userId)
    });



    //주간 근무 시간 
    setWeekSumTime(weekTimeTotal)


 
  let issueList =[];

    let num = tempRst2.length;
    let list = [];
    let newSt = null;


    for (let i = 0; i < num; i++) {

      let date = tempRst2[i].startdate; //date
      let st = tempRst2[i].starttime; //start time
      let et = tempRst2[i].endtime; //end time
      let wid = tempRst2[i].wid; // worklog id
      let summary = tempRst2[i].summary; //summary 

      let rs = tempRst2[i].rstarttime; //real start time
      let re = tempRst2[i].rendtime; //real end time


      if (et == '00:00') { et = '24:00' }

      if (newSt != null) {
        st = newSt;
        newSt = null;
      }


      let count = 1;

      let widList = [];

      for (let j = i + 1; j < num; j++) {

        if (i < num) {
          let nextDate = tempRst2[i + 1].startdate; //date 
          let nextSt = tempRst2[i + 1].starttime; //start time
          let nextEt = tempRst2[i + 1].endtime; //end time
          let nextwid = tempRst2[j].wid; //end time
          if (nextEt == '00:00') { nextEt = '24:00' };
          if (date == nextDate) {
            if (et > nextSt) {
              //현재(값 가져오기
              widList[widList.length] = nextwid;

              //startdate 기준 21:00 ~ 24:20 인 경우 --> 24:00~ endate 기준 체크 필요
              count++;
              if (et >= nextEt) {
                i++;
              } else {
                newSt = et;
                break;
              }
            } else {
              // et <= nextSt 인 경우 현재값 가져오기

              widList[widList.length] = wid;

              break;
            }
          } else {


            break;
          }
        }
      }


      //현재 i의 첫번째 wid 값 가져오기
      widList[widList.length] = wid;


      //중복 값 체크 
      widList = [...new Set(widList)];


      //이슈 리스트 push 처리 
      issueList.push({ title: summary, start: new Date(date + " " + st), end: new Date(date + " " + et), rstarttime: rs, rendtime: re,  id: String(wid), wid: widList, count: count, backgroundColor: '#00a65a', description: "ㅎㅎㅎㅎㅎ" })


    }

    //event 셋팅
    setCalData(issueList)



  }

  /**
   * when we click on event we are displaying event details
   */
  const eventClick = async (event) => { //이벤트 클릭

    //display인 경우는 null이므로 return 처리
    if (event.event.extendedProps.wid == null) return

    //상세 보기 
    let tempRst = await post('select/getMyworkDetail', {
      "worklogList": event.event.extendedProps.wid
    });


    let datas_select = tempRst ?? [];
    if (_.isArray(tempRst?.[0])) {
      datas_select = tempRst[0];
    }


    let tmpSeletList = _.map(datas_select, x => {
      return x;
    });

    setRowDtlData(tmpSeletList);

    //프로그래스 닫기
    setIsCallApi(false);
    // 디테일 모달 오픈
    setDtlModalInfo({
      isOpen: true,
      isClose: false,
      childNode: ( // 모달 내부 컴포넌트
        <div>
          {/* <h2>시간기록 상세보기</h2> */}
          <div className={styles.modalContent}>
            <table className="issue-dtl-table">
              <thead>
                {tmpSeletList.length > 0 &&
                  <tr>
                    <th> 이슈 </th>
                    <th> 날짜 </th>
                    <th> 시작시간 </th>
                    <th> 종료시간 </th>
                    <th className="calendar-issue-dtl-tr-description"> 설명 </th>
                    <th > 작성자 </th>
                    <th> 작업시간 </th>
                    <th> 수정/삭제 </th>

                  </tr>
                }
              </thead>
              <tbody>
                {
                  //tmpSeletList.lenth > 0 && 
                  tmpSeletList.map((res, index) => (
                    <tr key={res.wid}>
                      <td title={res.issuekey} className="issue-dtl-td-link" onClick={() => window.open("https://jira.sinc.co.kr/browse/"+res.issuekey)}> {res.issuekey.length < 5 ? res.issuekey : res.issuekey.slice(0, 5) + '...'}</td>
                      <td> {res.startdate}</td>
                      <td> {res.starttime}</td>
                      <td> {res.endtime}</td>
                      <td className="issue-dtl-td-description" title={res.worklogbody}> {(res.worklogbody == null || res.worklogbody.length) < 24 ? res.worklogbody : res.worklogbody.slice(0, 24) + '...'}</td>
                      <td title={res.displayname}>  {res.displayname.length < 3
                        ? res.displayname
                        : res.displayname.slice(0, 3) + '...'}
                      </td>
                      <td> {res.timeworked}</td>
                      {
                        res.username == userId
                          ?
                          <td className="issue-dtl-td-btn">
                            <button className="update" value={index} onClick={(e) => openUpdateModal(e, tmpSeletList)}>수정</button>
                            <button className="delete" value={res.wid} onClick={(e) => handleNewDtlModal(e)}>삭제</button>
                          </td>
                          :
                          <td className="issue-dtl-td-btn">
                          </td>
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      ),
      isBackdropClose: true,
      sx: {} // 모달 style
    });

    //프로그래스 닫기
    setIsCallApi(false);
    return;
  }


  //삭제 이벤트
  const handleNewDtlModal = useCallback((e, rowDtlData) => {
    // alert dialog test
    setAlertDialogInfo({
      title: '시간 기록을 삭제하시겠습니까?',
      content: null,
      isOpen: true,
      isBackdropClose: false,
      btnInfo: {
        confirm: {
          name: '삭제',
          callback: async () => {

            let tempRst = await post('worklog/deleteWorklog', {
              "worklogId": e.target.value,
              "userName": "223855" //userId""
            });

            if (tempRst[0] === false) {
              alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
            } else {
              //상세보기 팝업창 닫기
              setDtlModalInfo({ isClose: true });

              //현재 화면 재 리스트 불러오기 
              getCurrentMonth(allCalData);

            }
          }
        },
        cancle: {
          name: '취소'
        }
      }
    });
  }, [allCalData]);




  // 시간기록 등록 모달 오픈
  const openModal = (e) => {
    // from/to time list 세팅

    if (e.jsEvent.target.classList.contains('fc-non-business')) {
      alert('작업 기록 가능한 시간이 아닙니다.')
      return false;
    }


    const [fromtimeList, totimeList] = getFromToTimeList();

    // Create Modal View 컴포넌트
    const CreateModalView = memo(({
      setAddInfo = (props) => {},
      chkInfo = null
     }) => {

      //현재 일자 
      let dateNow = new Date();

      //event 클릭한 일자 YYYY-MM-DD 포맷 처리 
      let checkdate = dayjs(e.date).format("YYYY-MM-DD");

      //event 클릭한 시간 HH:mm 포맷 처리
      let checktime = dayjs(e.date).format("HH:mm")

      const [newdate, setNewDate] = useState(checkdate);

      const [fromTime, setFromTime] = useState(checktime);
      const [toTime, setToTime] = useState(checktime);

      const [searchFromTime, setSearchFromTime] = useState("");
      const [searchToTime, setSearchToTime] = useState("");

      const [ctgValue, setCtgValue] = useState('');

      const [comTime, setComTime] = useState("");

      const [issueValue, setIssueValue] = useState("");

      const [cworklogbodyValue, setcWorklogbodyValue] = useState("");

      const [chkdIssue, setChkdIssue] = useState([]);

      const [totaltimechk, setTotaltimechk] = useState("");

      //업무구분 체크 YN
      const [divisionwork, setDivisionWork] = useState(true);

      //SHRS 근태 시간 (작업 기록)
      const [shrsWorkTimeList, setShrsWorkTimeList] = useState([]);

      //SHRS 근태 시간 (작업 기록)
      const [trWorkTimeList, settrWorkTimeList] = useState([]);

      //SHRS 근태, 작업량 시간 체크 
      const [shrstrTimeList, setShrsTrTimeList] = useState([]);


      // 등록된 시간 선택 가능 시간 클릭 이벤트
      const WorkTimeClick = useCallback((from, to) => {

        //from 시간 셋팅
        setFromTime(from);

        //to 시간 셋팅
        setToTime(to);

      }, []);



      //작업량 근태 체크 및 api 호출 
      const chkWorkTime = async () => {


        //작업량 근태 from,to 리스트 api 호출 
        let worktimechk = await post('etc/getFromTo', {
          "userName": String(userId),
          "date": newdate
        });

      //작업, 근태 (0h)
        setShrsTrTimeList(worktimechk)


        //작업 근태 시간 
        let workShrs = worktimechk.shrs;

        //작업 기록 시간
        let workTr = worktimechk.tr;



        //test langth 체크 
        for (let i = 0; i < workShrs.length; i++) {
          workShrs[i] = workShrs[i][0].substr(0, 2) + ":" + workShrs[i][0].substr(2, 4) + " ~ " + workShrs[i][1].substr(0, 2) + ":" + workShrs[i][1].substr(2, 4) + "  (" + workShrs[i][2] + ")";
        }

        //근태시간 셋팅
        setShrsWorkTimeList(workShrs)

        //작업시간 셋팅
        settrWorkTimeList(workTr)


      }


      //rendering 
      const rendering = () => {
        const result = [];

        for (let i = 0; i < trWorkTimeList.length; i++) {
          result.push(

            trWorkTimeList[i][2] == "선택가능"
              ?
              <MenuItem key={i} style={{ fontSize: '0.84615rem' }} title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3]} onClick={() => {
                WorkTimeClick(
                  (trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4))
                  ,
                  (trWorkTimeList[i][1].substr(0, 2) + ":" + trWorkTimeList[i][1].substr(2, 4))
                )
              }}>
                <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} (<b style={{color:"#28a745", fontWight:"200"}}>{trWorkTimeList[i][2]}</b>)&nbsp;&nbsp;</div>
                <div> {trWorkTimeList[i][3]}  </div>
              </MenuItem>
              :
              <div title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3] + ' ' + trWorkTimeList[i][4] + ' ' + trWorkTimeList[i][5]} >
                <MenuItem key={i} style={{ fontSize: '0.84615rem' }} disabled>
                  <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} ({trWorkTimeList[i][2]})&nbsp;</div>
                  <div > {trWorkTimeList[i][3]} </div>&nbsp;
                  <div>
                    {trWorkTimeList[i][4].length < 10 ? trWorkTimeList[i][4] : trWorkTimeList[i][4].slice(0, 10) + '...'}

                  </div>&nbsp;
                  <div>
                    {trWorkTimeList[i][5].length < 10 ? trWorkTimeList[i][5] : trWorkTimeList[i][5].slice(0, 10) + '...'}
                  </div>
                </MenuItem>
              </div>
          )

        }
        return result;
      };
      //rendering return






      //업무구분 체크 및 api 호출 
      const chkDivisionWork = async () => {

        //이슈 빈 값 체크 
        if (chkdIssue == null || chkdIssue.length < 1) {
        } else {
          //업무 구분 체크 api 호출 
          let YNcheck = await get('etc/divisionofwork', { "issueKey": chkdIssue.issuekey });

          if (YNcheck == "false") {
            //true일 경우 set false 설정
            setDivisionWork(false)
          } else {
            //true일 경우 set true 설정
            setDivisionWork(true)
          }
        }
      }

      useEffect(() => {
        //기본 chkInfo == false 이나 다른 작업량 생성 버튼 클릭 시, true 처리 
        if (chkInfo?.isChk === true) {
          chkWorkTime();
          rendering();
          chkInfo = null;
        }
      }, [chkInfo])

      //업무유형 구분 셋팅하기 위한 useEffect (divisionwork)
      useEffect(() => {
      }, [divisionwork]);


      //날짜 클릭 시 셋팅하기 위한 useEffect (newdate)
      useEffect(() => {
        chkWorkTime();
        rendering();
      }, [newdate]);


      //시간 입력 시 worked를 셋팅하기 위한 useEffect (fromTime)
      useEffect(() => {
        changeInputTimeValue("from", fromTime);
      }, [fromTime]);

      //시간 입력 시 worked를 셋팅하기 위한 useEffect (toTime)
      useEffect(() => {
        changeInputTimeValue("to", toTime);
      }, [toTime]);

      //이슈 클릭 시 카테고리 yn 여부 체크
      useEffect(() => {
        chkDivisionWork();
      }, [chkdIssue]);

      // comTime 변경 감지 훅
      useEffect(() => {

        setAddInfo({ totaltimechk, chkdIssue, ctgValue, cworklogbodyValue, newdate, fromTime, toTime, comTime, divisionwork });
      }, [totaltimechk, chkdIssue, ctgValue, cworklogbodyValue, newdate, fromTime, toTime, comTime, divisionwork]);


      const changeCtgValue = useCallback((event) => {
        setCtgValue(event)
      }, []);

      const changeWorklogbodyValue = useCallback((event) => {
        setcWorklogbodyValue(event.target.value)
      }, []);

      const changenewDateValue = useCallback((event) => {
        if (event == null) {
          return;
        }
        setNewDate(dayjs(event).format("YYYY-MM-DD"))
        //api 호출

      }, []);


      const changeIssueValue = useCallback((event) => {
        setIssueValue(event)

      }, []);




      // from time input 값 변경 시, 호출
      const changeFromTimeInput = (val) => {
        setSearchFromTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // fromTime state 변경
        setFromTime(val);
        // time list 변경
        changeInputTimeValue("from", val);
      };

      // to time input 값 변경 시, 호출
      const changeToTimeInput = (val) => {
        setSearchToTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // toTime state 변경
        setToTime(val);
        // time list 변경
        changeInputTimeValue("to", val);
      };


      const checkToTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-insert-to-helper').style.display = 'none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-insert-to-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-insert-to-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setToTime(time);
            } else {
              time = hours + ":" + minute;
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-insert-to-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setToTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-insert-to-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else {
              time = replaceTime + ":00";
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setToTime(time);
          } else {
            time = "00:00";
            setToTime(time);
            document.getElementById('list-select-insert-to-helper').style.display = 'flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }

          // From, To 시간 데이터 가져오기
          const fromTimeValue = fromTime.replace(':', '');
          const toTimeValue = time.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue >= toTimeValue) {
            setFromTime(time);
            setComTime("");
          }
        }
      }


      //To 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkToTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-insert-to-helper').style.display = 'none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-insert-to-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-insert-to-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setToTime(time);
          } else {
            time = hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-insert-to-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setToTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-insert-to-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else {
            time = replaceTime + ":00";
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setToTime(time);
        } else {
          time = "00:00";
          setToTime(time);
          document.getElementById('list-select-insert-to-helper').style.display = 'flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(time);
          setComTime("");
        }
      }


      const checkWorkedKeydown = (e) => {

        if (e.key === 'Enter') {
          // 조건에 맞게 형변환
          checkWorkedTimeColon(e.target.value);
        };

      }

      //worked 시간 입력 시 체크 하는 로직 
      const checkWorkedTimeColon = (time) => {

        //값이 없으면 return
        if (time == "") return;

        document.getElementById('list-select-insert-from-helper').style.display = 'none';
        document.getElementById('list-select-insert-to-helper').style.display = 'none';

        let worked = time.replace(/h/i, "h");


        worked = time.replace(/m/i, "m");
        worked = time.replace(" ", "");

        // From Time 데이터값 가져오기
        const fromValue = fromTime;
        const fromValueArr = fromValue.toString().split(":");
        const fromValue_h = fromValueArr[0];
        const fromValue_m = fromValueArr[1];


        let worked_h = "";
        let worked_m = "";

        // 시간이 실수인 경우
        if (worked.match(/^\d{1,2}\.\d{1,2}$/)) {
          const worked_time = worked.toString().split(".");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1]
          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = worked_m * 6;        // 입력된 분
          } else {    // 입력된 분이 10분 이상인 경우
            worked_m = worked_m * 0.6;           // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}\:\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(":");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}\,\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(",");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}h\d{1,2}m$/)) {       // 시간, 분 모두 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = worked.substring(worked.indexOf("h") + 1, worked.indexOf("m"));

          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = "0" + worked_m;        // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}h$/)) {       // 시간만 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = 0;
        } else if (worked.match(/^\d{1,2}m$/)) {       // 분만 입력한 경우
          worked_h = 0;
          worked_m = worked.substring(0, worked.indexOf("m"));
        } else if (worked.match(/^\d{1,2}$/)) {       // 숫자만 입력한 경우 -> 시간으로 체크
          worked_h = worked;
          worked_m = 0;
        } else {
          alert("잘못된 값입니다.(Invalid value)");
          setComTime("");
          return false;
        }

        let time_click_to = new Date();
        time_click_to.setHours(fromValue_h, fromValue_m); // to 현재 값 date 초기화
        time_click_to.setHours(time_click_to.getHours() + parseInt(worked_h));
        time_click_to.setMinutes(time_click_to.getMinutes() + parseInt(worked_m));

        let toValue;

        //to 시간이 24:00 인 경우, Date에서는 00시로 표기되기 때문에 24시로 처리한다.
        if (time_click_to.getHours() == 0 && time_click_to.getMinutes() == 0) {
          toValue = '24' + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
          //그 외는 그대로 처리한다.
        } else {
          toValue = ('0' + time_click_to.getHours()).slice(-2) + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
        }

        /* 24시간 이상 여부 체크 로직 */

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = toValue.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          let hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = hours + ":00";
            setToTime(toValue);
          } else {
            toValue = hours + ":" + minute;
            setToTime(toValue);
            compare_worked();
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          let hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = "0" + hours + ":00";
            setToTime(toValue);
          } else {
            toValue = "0" + hours + ":" + minute;
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else {
            toValue = replaceTime + ":00";
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          toValue = "0" + replaceTime + ":00";
          setToTime(toValue);
        } else {
          toValue = "00:00";
          setToTime(toValue);
          alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        let fromTimeValue = fromTime.replace(':', '');
        let toTimeValue = toValue.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(toValue);
          setComTime("");
        }
      }


      const checkFromTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-insert-from-helper').style.display = 'none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-insert-from-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-insert-from-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setFromTime(time);
            } else {
              time = hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-insert-from-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setFromTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-insert-from-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else {
              time = replaceTime + ":00";
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setFromTime(time);
          } else {
            time = "00:00"
            setFromTime(time);
            document.getElementById('list-select-insert-from-helper').style.display = 'flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }
          // From, To 시간 데이터 가져오기
          const fromTimeValue = time.replace(':', '');
          const toTimeValue = toTime.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue > toTimeValue) {
            setToTime(time);
            setComTime("");
          }
        }
      }


      //From 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkFromTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-insert-from-helper').style.display = 'none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-insert-from-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-insert-from-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setFromTime(time);
          } else {
            time = hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-insert-from-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setFromTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-insert-from-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else {
            time = replaceTime + ":00";
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setFromTime(time);
        } else {
          time = "00:00"
          setFromTime(time);
          document.getElementById('list-select-insert-from-helper').style.display = 'flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time);
          setComTime("");
        }
      }

      //select box From 시간 체크 
      const onchangeFromTime = (time) => {

        document.getElementById('list-select-insert-from-helper').style.display = 'none';

        setFromTime(time.value);
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.value.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time.value);
          setComTime("");
        }
      }


      //select box to 시간 체크 
      const onchangeToTime = (time) => {

        document.getElementById('list-select-insert-to-helper').style.display = 'none';

        setToTime(time.value);

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.value.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setFromTime(time.value);
          setComTime("");
        }
      }

      const changeInputTimeValue = (target, val) => {
        if (target === "from") {
          fromtimeList.length = 0;
        } else {
          totimeList.length = 0;
        }
        const time = val;

        const time_click = new Date();

        const timeArr = time.split(":");

        time_click.setHours(timeArr[0], timeArr[1]);

        for (let j = 0; j < 48; j++) {
          const op = {};
          //시간
          const time_slice = ('0' + time_click.getHours()).slice(-2) + ":" + ('0' + time_click.getMinutes()).slice(-2);
          time_click.setMinutes(time_click.getMinutes() + 30);

          op.value = time_slice;
          op.label = time_slice;

          if (target === "from") {
            fromtimeList.push(op);
            // e.target.defaultValue =fromtimeList[0].value;
          } else {
            totimeList.push(op);
          }
        }
        compare_worked();
      };

      // From, To 데이터 변경에 따른 Worked데이터 입력
      const compare_worked = () => {

        //from,to 시간 차이 계산
        let compare_from = fromTime.split(":");
        let compare_to = toTime.split(":");

        const compare_time_from = new Date();
        compare_time_from.setHours(compare_from[0], compare_from[1]);

        const compare_time_to = new Date();
        compare_time_to.setHours(compare_to[0], compare_to[1]);

        var compare_time = compare_time_to.getTime() - compare_time_from.getTime();
        var compare_time_hour = compare_time / 1000 / 60 / 60;
        var compare_time_min = compare_time / 1000 / 60 - (parseInt(compare_time_hour) * 60);


        if (parseInt(compare_time_hour) == 0 && parseInt(compare_time_min) == 0) {
          return;
        }
        else if (parseInt(compare_time_hour) != 0 && parseInt(compare_time_min) != 0) {
          setComTime(parseInt(compare_time_hour) + "h " + parseInt(compare_time_min) + "m");
        }
        else if (parseInt(compare_time_hour) == 0) {
          setComTime(parseInt(compare_time_min) + "m");
        }

        else {
          setComTime(parseInt(compare_time_hour) + "h");
        }
      };


      const customStyles = useMemo(
        () => ({
          control: (provided, state) => ({
            ...provided,
            background: '#F7F7F7',
            height: '2.46153rem',
            fontSize: '0.84615rem',
            boxShadow: state.isFocused ? null : null,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "rgba(25, 118, 210, 0.08)"
              : state.isFocused
                ? 'rgba(0, 0, 0, 0.04)'
                : null,
            color: '#222',
            fontSize: '0.84615rem'
          }),

          valueContainer: (provided, state) => ({
            ...provided,
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
          }),
        }),
        []
      );

      const DropdownIndicator = (props) => {
        const { menuIsOpen } = props.selectProps;
        if (menuIsOpen) {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        } else {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        }
      };

      return (
        <div className={styles.modalContent}>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>이슈</div>
            {/* 이슈 셀렉트 */}
            <SearchOneSelect
              id="issueOneSelect"
              label="이슈"
              inputLabel="이슈 선택"
              isEssential={false}
              mappingInfo={{ key: 'id', value: 'summary', preValue: 'issuekey', fId: 'fid' }}
              apiName="filter/getIssueList"
              isGetMethod={true}
              handleChkdList={setChkdIssue}
              oneUserId={oneselectuserid}
              formSx={{ m: 1, width: 400, margin: '8px 0px 0 8px' }}
            />
          </div>
          {divisionwork == true &&
            <div className={styles.createRow}>
              <div className={styles.createLabel}>Category</div>
              <div className={styles.createCategory}>
                <ReactSelect
                  onChange={(e) => changeCtgValue(e.value)}
                  placeholder="카테고리 선택"
                  options={selectCtgOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
                <div className={styles.createcategoryhelper}>업무 구분이 (프로그램 개선/개발) 의 경우 선택 가능합니다.</div>
              </div>
            </div>
          }
          <div className={styles.createRow}>
            <div className={styles.createLabel}>Description</div>
            <textarea
              placeholder="여기에 입력하세요"
              value={cworklogbodyValue}
              className={styles.createTextArea}
              onChange={(e) => changeWorklogbodyValue(e)}
              rows='4'
            /*id='create-modal-select-textarea'*/
            ></textarea>
          </div>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>날짜</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <div className={styles.datePicker}>
                  <DatePicker
                    value={dayjs(newdate)}
                    onChange={changenewDateValue}
                    format="YYYY-MM-DD"
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '2.46153rem',
                        fontSize: '0.84615rem',
                        overflow: 'hidden',
                        width: '400px'
                      }
                    }}
                    shrink={false}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>근태 시간<br/>
              {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totalshrs+')'
                : ' (0h)'
              }
            </div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "100px" }}>
              {shrsWorkTimeList.length > 0
                ? shrsWorkTimeList.map((list) => (
                  <MenuItem style={{ fontSize: '0.84615rem' }} disabled>{list}</MenuItem>
                ))
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 근태 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>등록된 시간<br/>
            {Object.keys(shrstrTimeList).length > 0
                ?'('+shrstrTimeList.totaltr+')'
                : ' (0h)'
              }</div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "150px", fontSize: '0.84615rem' }} >
              {trWorkTimeList.length > 0
                ? rendering()
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 작업량 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>








          <div className={styles.createRow}>
            <div className={styles.createLabel}>시간 선택</div>
            <div className={styles.multiItem}>
              <div className={styles.createFlex}>
                <ReactSelect
                  placeholder="시간 선택"
                  options={fromtimeList}
                  defaultValue={fromTime}
                  inputValue={searchFromTime}
                  value={{ label: fromTime, value: fromTime }}
                  onInputChange={changeFromTimeInput}
                  onKeyDown={checkFromTimeKeydown}
                  onBlur={(e) => checkFromTimeBlur(e)}
                  onChange={onchangeFromTime}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
                <FormHelperText id="list-select-insert-from-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
              </div>
              {/* <p>
            fromTime: <b>{fromTime}</b>
            </p> */}
              <span className={styles.span}>~</span>
              <div className={styles.createFlex}>
                <ReactSelect
                  placeholder="시간 선택"
                  options={totimeList}
                  defaultValue={toTime}
                  inputValue={searchToTime}
                  value={{ label: toTime, value: toTime }}
                  onInputChange={changeToTimeInput}
                  onKeyDown={checkToTimeKeydown}
                  onBlur={(e) => checkToTimeBlur(e)}
                  onChange={onchangeToTime}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
                <FormHelperText id="list-select-insert-to-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
              </div>
            </div>

          </div>



          <div className={styles.createRow}>
            <div className={styles.createLabel}>Worked</div>
            <input
              placeholder="0h"
              className={styles.createInput}
              onChange={(e) => setComTime(e.target.value)}
              onKeyDown={checkWorkedKeydown}
              onBlur={(e) => checkWorkedTimeColon(e.target.value)}
              value={comTime}
            />
          </div>
          <div className={styles.createRowWrap}>
            <div className={styles.createLabel} />
            {/* <div className={styles.createhelper}>근태와 동일하게 등록 바랍니다.</div> */}
            <div className={styles.createhelper}>실제 근태와 지라 근태는 차이가 있을 수 있습니다. 실제 근태는 SHRS 시스템, 차이나는 이유는 HELP를 참고해주세요.</div>

          </div>
        </div>
      );
    });

    // 시간기록 모달 오픈
    setModalInfo({
      isOpen: true,
      isClose: false,
      childNode: ({ setAddInfo, chkInfo}) => <CreateModalView setAddInfo={setAddInfo} chkInfo={chkInfo} />,
      isBackdropClose: true,
      sx: {}, // 모달 style
      btnInfo: {
        confirm: {
          name: '등록',
          callback: async (props) => {

            let date = dayjs(props.addInfo.newdate);


            //업무구분 체크 여부가 false일 경우, 카테고리 기타(ETC) 처리 
            if (props.addInfo.divisionwork == false) {
              props.addInfo.ctgValue = "기타(ETC)";
            }

            //Description이 null이면 카테고리 값으로 처리
            let cworklogbodychk = props.addInfo.cworklogbodyValue;
            if (cworklogbodychk == "") {
              cworklogbodychk = props.addInfo.ctgValue;
            }

            //startdate 
            let fromdate = dayjs(props.addInfo.newdate);
            let fromchk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", 0).format("YYYY-MM-DD HH:mm:ss");

            //enddate
            let todate = dayjs(props.addInfo.newdate);
            let tochk;


            //timeworked 시간 계산
            let timeworked = props.addInfo.comTime;
            let timework_h, timework_m;

            //timeworked 형변환
            if (timeworked.includes('h') && timeworked.includes('m')) {     // 시간, 분 모두 입력된 경우
              timeworked = timeworked.toString().split(" ");
              timework_h = timeworked[0].replace(/h/g, "");
              timework_m = timeworked[1].replace(/m/g, "");
              timeworked = ((parseInt(timework_h) * 60) * 60) + (parseInt(timework_m) * 60);
            } else if (timeworked.includes('h')) {      // 시간만 입력된 경우
              timework_h = timeworked.toString().split("h");
              timeworked = (parseInt(timework_h) * 60) * 60;
            } else {    // 분만 입력된 경우
              timework_m = timeworked.toString().split("m");
              timeworked = (parseInt(timework_m) * 60);
            }

            //tochk = fromTime + timeworked 
            tochk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", timeworked).format("YYYY-MM-DD HH:mm:ss");

            //유효성 체크 
            if (props.addInfo.chkdIssue == null) {
              alert("이슈 선택하세요.");
            } else if (props.addInfo.ctgValue == "") {
              alert("카테고리를 선택하세요.");
            } else if (props.addInfo.comTime == "0h" || props.addInfo.comTime == "") {
              alert("시간입력을 하세요.");
            } else {


              let datechk = date.format("YYYY-MM-DD");

              //근태 등록 체크 
              const statechk = 'insert';

              //근태 등록 from 
              let hrfromchk = props.addInfo.fromTime.substr(0, 2) + props.addInfo.fromTime.substr(3, 5);

              //근태 등록 to
              let hrtochk = props.addInfo.toTime.substr(0, 2) + props.addInfo.toTime.substr(3, 5);

              // 프로그레스 오픈
              setIsCallApi(true);


              //근태 등록 여부 체크 API 호출
              let tempRst = await post('etc/timecuchk', {
                "userName": userId,
                "date": datechk,
                "fromTime": hrfromchk,
                "toTime": hrtochk,
                "timeSpent": timeworked,
                "division": statechk,
                "updateWorklogId": ""
              });

              // 프로그레스 닫기
              setIsCallApi(false);

              //근태 등록이 필요하면 호출 [Y(시간기록 가능), NSFT(근태시간 부족), NT(작업량 존재), NST(Total 시간 초과)]
              //totaltimechk == return 값 체크 [WORK(근태화면), STAY(팝업 그대로), OK(성공 - 등록/수정)]
              if (tempRst == 'NSFT') {
                alert("입력하신 시간은 근무 시간이 아닙니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk, "", props.addInfo.chkdIssue.issuekey, props.addInfo.ctgValue, encodeURI(cworklogbodychk), timeworked);
                return props.addInfo.totaltimechk = "WORK";
              } else if (tempRst == 'NT') {

                alert("입력하신 시간에 이미 기록된 시간이 있습니다. 확인 후 입력 부탁 드립니다.")
                return props.addInfo.totaltimechk = "STAY"

              } else if (tempRst == 'NST') {

                alert("근태 시간이 부족합니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk, "", props.addInfo.chkdIssue.issuekey, props.addInfo.ctgValue, encodeURI(cworklogbodychk), timeworked);
                return props.addInfo.totaltimechk = "WORK"
              } else {

                // 프로그레스 오픈
                setIsCallApi(true);

                let tempRstCreate = await post('worklog/createWorklog', {
                  "issueKey": props.addInfo.chkdIssue.issuekey,
                  "userName": userId,
                  "startDate": fromchk,
                  "endDate": tochk,
                  "category": props.addInfo.ctgValue,
                  "worklogBody": encodeURI(cworklogbodychk),
                  "timeSpent": timeworked
                });

                // 프로그레스 닫기
                setIsCallApi(false);

                if (tempRstCreate[0] === false) {
                  alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
                }
                else {

                  // 타임아웃 인스턴스 존재 체크
                  if (!!timeoutInstcs.errAlert) return false;
                  // errAlert 오픈
                  setErrAlertInfo({ open: true, msg: '등록되었습니다.' });
                  // errAlert에 대한 인스턴스 세팅
                  timeoutInstcs.errAlert = setTimeout(() => {
                    // errAlert 닫기
                    setErrAlertInfo({ open: false, msg: null });
                    // clear 인스턴스
                    clearTimeoutInstcs('errAlert');
                  }, 1200);

                  /*  //기존에 검색한 경우, 작업기록 등록처리 후 검색 처리
                    if(searchChk == true) {
                      //getWlrList(1);
                    }
                    */
                  //달력 기간 이벤트 재검색 
                  getCurrentMonth(allCalData);
                  return false;
                }

                return props.addInfo.totaltimechk = "OK";

              }
            }
          }
        },
        cancle: {
          name: 'Cancle'
        }
      },
    })
  }


  // 시간기록 수정 모달 오픈
  const openUpdateModal = (e, rowDtlData) => {

    // from/to time list 세팅
    const [fromtimeList, totimeList] = getFromToTimeList();

    // Update Modal View 컴포넌트
    const UpdateModalView = memo(({
      setAddInfo = (props) => { }
    }) => {

      const [fromTime, setFromTime] = useState(rowDtlData[e.target.value].starttime);

      const [toTime, setToTime] = useState(rowDtlData[e.target.value].endtime);

      const [searchFromTime, setSearchFromTime] = useState("");
      const [searchToTime, setSearchToTime] = useState("");

      const [ctgValue, setCtgValue] = useState(rowDtlData[e.target.value].category);

      const [comTime, setComTime] = useState(rowDtlData[e.target.value].timeworked);

      const [issueValue, setIssueValue] = useState(rowDtlData[e.target.value].summary);

      const [worklogbodyValue, setWorklogbodyValue] = useState(rowDtlData[e.target.value].worklogbody);

      const [dateValue, setDateValue] = useState(rowDtlData[e.target.value].startdate);

      const [totaltimechk, setTotaltimechk] = useState("");

      //업무구분 체크 YN
      const [divisionwork, setDivisionWork] = useState(false);

      //SHRS 근태 시간 (작업 기록)
      const [shrsWorkTimeList, setShrsWorkTimeList] = useState([]);

      //SHRS 근태 시간 (작업 기록)
      const [trWorkTimeList, settrWorkTimeList] = useState([]);

      //SHRS 근태, 작업량 시간 체크 
      const [shrstrTimeList, setShrsTrTimeList] = useState([]);

      // 등록된 시간 선택 가능 시간 클릭 이벤트
      const WorkTimeClick = useCallback((from, to) => {

        //from 시간 셋팅
        setFromTime(from);

        //to 시간 셋팅
        setToTime(to);

      }, []);

           // comTime 변경 감지 훅
           useEffect(() => {
           },[shrsWorkTimeList,shrstrTimeList]);



      //작업량 근태 체크 및 api 호출 
      const chkWorkTime = async () => {


        //작업량 근태 from,to 리스트 api 호출 
        let worktimechk = await post('etc/getFromTo', {
          "userName": String(userId),
          "date": dayjs(dateValue).format("YYYY-MM-DD")
        });

        //작업, 근태 (0h)
        setShrsTrTimeList(worktimechk)

        
        //작업 근태 시간 
        let workShrs = worktimechk.shrs;

        //작업 기록 시간
        let workTr = worktimechk.tr;

        //test langth 체크 
        for (let i = 0; i < workShrs.length; i++) {
          workShrs[i] = workShrs[i][0].substr(0, 2) + ":" + workShrs[i][0].substr(2, 4) + " ~ " + workShrs[i][1].substr(0, 2) + ":" + workShrs[i][1].substr(2, 4) + "  (" + workShrs[i][2] + ")";
        }

        //근태시간 셋팅
        setShrsWorkTimeList(workShrs)

        //작업시간 셋팅
        settrWorkTimeList(workTr)


      }


      //rendering 
      const rendering = () => {
        const result = [];


        for (let i = 0; i < trWorkTimeList.length; i++) {
          result.push(

            trWorkTimeList[i][2] == "선택가능"
              ?
              <MenuItem key={i} style={{ fontSize: '0.84615rem' }} title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3]} onClick={() => {
                WorkTimeClick(
                  (trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4))
                  ,
                  (trWorkTimeList[i][1].substr(0, 2) + ":" + trWorkTimeList[i][1].substr(2, 4))
                )
              }}>
                <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} (<b style={{color:"#28a745", fontWight:"200"}}>{trWorkTimeList[i][2]}</b>)&nbsp;&nbsp;</div>
                <div> {trWorkTimeList[i][3]}  </div>
              </MenuItem>
              :
              <div title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3] + ' ' + trWorkTimeList[i][4] + ' ' + trWorkTimeList[i][5]} >
                <MenuItem key={i} style={{ fontSize: '0.84615rem' }} disabled>
                  <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} ({trWorkTimeList[i][2]})&nbsp;</div>
                  <div > {trWorkTimeList[i][3]} </div>&nbsp;
                  <div>
                    {trWorkTimeList[i][4].length < 10 ? trWorkTimeList[i][4] : trWorkTimeList[i][4].slice(0, 10) + '...'}

                  </div>&nbsp;
                  <div>
                    {trWorkTimeList[i][5].length < 10 ? trWorkTimeList[i][5] : trWorkTimeList[i][5].slice(0, 10) + '...'}
                  </div>
                </MenuItem>
              </div>
          )

        }
        return result;
      };
      //rendering return


      const chkDivisionWork = async () => {

        let YNcheck = await get('etc/divisionofwork', { "issueKey": rowDtlData[e.target.value].issuekey });


        if (YNcheck == "false") {
          //true일 경우 set false 설정
          setDivisionWork(false)
        } else {
          //true일 경우 set true 설정
          setDivisionWork(true)
        }
      }

      //업무유형 구분 셋팅하기 위한 useEffect (divisionwork)
      useEffect(() => {
        chkDivisionWork();
      }, [divisionwork]);

      //날짜 클릭 시 셋팅하기 위한 useEffect (newdate)
      useEffect(() => {
        chkWorkTime();
        rendering();
      }, [dateValue]);


      //시간 입력 시 worked를 셋팅하기 위한 useEffect (fromTime)
      useEffect(() => {
        changeInputTimeValue("from", fromTime);
      }, [fromTime]);

      //시간 입력 시 worked를 셋팅하기 위한 useEffect (toTime)
      useEffect(() => {
        changeInputTimeValue("to", toTime);
      }, [toTime]);

      const changeCtgValue = useCallback((event) => {
        setCtgValue(event)
      }, []);

      const changeupdateWorklogbodyValue = useCallback((event) => {
        setWorklogbodyValue(event.target.value)
      }, []);

      // comTime 변경 감지 훅
      useEffect(() => {

        setAddInfo({ totaltimechk, issueValue, ctgValue, worklogbodyValue, dateValue, fromTime, toTime, comTime, divisionwork });
      }, [totaltimechk, issueValue, ctgValue, worklogbodyValue, dateValue, fromTime, toTime, comTime, divisionwork]);

      const changenewDateValue = useCallback((event) => {
        if (event == null) {
          return;
        }
        setDateValue(event)



      }, []);



      // from time input 값 변경 시, 호출
      const changeFromTimeInput = (val) => {
        setSearchFromTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // fromTime state 변경
        setFromTime(val);
        // time list 변경
        changeInputTimeValue("from", val);
      };

      // to time input 값 변경 시, 호출
      const changeToTimeInput = (val) => {
        setSearchToTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // toTime state 변경
        setToTime(val);
        // time list 변경
        changeInputTimeValue("to", val);
      };


      const checkToTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-update-to-helper').style.display = 'none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-update-to-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-update-to-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setToTime(time);
            } else {
              time = hours + ":" + minute;
              setToTime(time);
              compare_worked();
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-update-to-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setToTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-update-to-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else {
              time = replaceTime + ":00";
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setToTime(time);
          } else {
            time = "00:00";
            setToTime(time);
            document.getElementById('list-select-update-to-helper').style.display = 'flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }

          // From, To 시간 데이터 가져오기
          const fromTimeValue = fromTime.replace(':', '');
          const toTimeValue = time.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue >= toTimeValue) {
            setFromTime(time);
            setComTime("");
          }
        }
      }

      //To 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkToTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-update-to-helper').style.display = 'none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-update-to-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-update-to-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setToTime(time);
          } else {
            time = hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-update-to-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setToTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-update-to-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else {
            time = replaceTime + ":00";
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setToTime(time);
        } else {
          time = "00:00";
          setToTime(time);
          document.getElementById('list-select-update-to-helper').style.display = 'flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(time);
          setComTime("");
        }
      }


      const checkWorkedKeydown = (e) => {

        if (e.key === 'Enter') {
          // 조건에 맞게 형변환
          checkWorkedTimeColon(e.target.value);
        };

      }

      //worked 시간 입력 시 체크 하는 로직 
      const checkWorkedTimeColon = (time) => {

        //값이 없으면 return
        if (time == "") return;

        document.getElementById('list-select-update-from-helper').style.display = 'none';
        document.getElementById('list-select-update-to-helper').style.display = 'none';

        let worked = time.replace(/h/i, "h");


        worked = time.replace(/m/i, "m");
        worked = time.replace(" ", "");

        // From Time 데이터값 가져오기
        const fromValue = fromTime;
        const fromValueArr = fromValue.toString().split(":");
        const fromValue_h = fromValueArr[0];
        const fromValue_m = fromValueArr[1];


        let worked_h = "";
        let worked_m = "";

        // 시간이 실수인 경우
        if (worked.match(/^\d{1,2}\.\d{1,2}$/)) {
          const worked_time = worked.toString().split(".");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1]
          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = worked_m * 6;        // 입력된 분
          } else {    // 입력된 분이 10분 이상인 경우
            worked_m = worked_m * 0.6;           // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}\:\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(":");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}\,\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(",");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}h\d{1,2}m$/)) {       // 시간, 분 모두 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = worked.substring(worked.indexOf("h") + 1, worked.indexOf("m"));

          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = "0" + worked_m;        // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}h$/)) {       // 시간만 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = 0;
        } else if (worked.match(/^\d{1,2}m$/)) {       // 분만 입력한 경우
          worked_h = 0;
          worked_m = worked.substring(0, worked.indexOf("m"));
        } else if (worked.match(/^\d{1,2}$/)) {       // 숫자만 입력한 경우 -> 시간으로 체크
          worked_h = worked;
          worked_m = 0;
        } else {
          alert("잘못된 값입니다.(Invalid value)");
          setComTime("");
          return false;
        }

        let time_click_to = new Date();
        time_click_to.setHours(fromValue_h, fromValue_m); // to 현재 값 date 초기화
        time_click_to.setHours(time_click_to.getHours() + parseInt(worked_h));
        time_click_to.setMinutes(time_click_to.getMinutes() + parseInt(worked_m));

        let toValue;

        //to 시간이 24:00 인 경우, Date에서는 00시로 표기되기 때문에 24시로 처리한다.
        if (time_click_to.getHours() == 0 && time_click_to.getMinutes() == 0) {
          toValue = '24' + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
          //그 외는 그대로 처리한다.
        } else {
          toValue = ('0' + time_click_to.getHours()).slice(-2) + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
        }

        /* 24시간 이상 여부 체크 로직 */

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = toValue.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          let hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = hours + ":00";
            setToTime(toValue);
          } else {
            toValue = hours + ":" + minute;
            setToTime(toValue);
            compare_worked();
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          let hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = "0" + hours + ":00";
            setToTime(toValue);
          } else {
            toValue = "0" + hours + ":" + minute;
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else {
            toValue = replaceTime + ":00";
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          toValue = "0" + replaceTime + ":00";
          setToTime(toValue);
        } else {
          toValue = "00:00";
          setToTime(toValue);
          alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        let fromTimeValue = fromTime.replace(':', '');
        let toTimeValue = toValue.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(toValue);
          setComTime("");
        }
      }


      const checkFromTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-update-from-helper').style.display = 'none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-update-from-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-update-from-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setFromTime(time);
            } else {
              time = hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-update-from-helper').style.display = 'flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setFromTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-update-from-helper').style.display = 'flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else {
              time = replaceTime + ":00";
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setFromTime(time);
          } else {
            time = "00:00"
            setFromTime(time);
            document.getElementById('list-select-update-from-helper').style.display = 'flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }
          // From, To 시간 데이터 가져오기
          const fromTimeValue = time.replace(':', '');
          const toTimeValue = toTime.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue > toTimeValue) {
            setToTime(time);
            setComTime("");
          }
        }
      }

      //From 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkFromTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-update-from-helper').style.display = 'none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-update-from-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-update-from-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setFromTime(time);
          } else {
            time = hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-update-from-helper').style.display = 'flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setFromTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-update-from-helper').style.display = 'flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else {
            time = replaceTime + ":00";
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setFromTime(time);
        } else {
          time = "00:00"
          setFromTime(time);
          document.getElementById('list-select-update-from-helper').style.display = 'flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time);
          setComTime("");
        }
      }

      //select box From 시간 체크 
      const onchangeFromTime = (time) => {

        document.getElementById('list-select-update-from-helper').style.display = 'none';

        setFromTime(time.value);
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.value.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time.value);
          setComTime("");
        }
      }


      //select box to 시간 체크 
      const onchangeToTime = (time) => {

        document.getElementById('list-select-update-to-helper').style.display = 'none';

        setToTime(time.value);

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.value.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setFromTime(time.value);
          setComTime("");
        }
      }

      const changeInputTimeValue = (target, val) => {
        if (target === "from") {
          fromtimeList.length = 0;
        } else {
          totimeList.length = 0;
        }
        const time = val;

        const time_click = new Date();

        const timeArr = time.split(":");

        time_click.setHours(timeArr[0], timeArr[1]);

        for (let j = 0; j < 48; j++) {
          const op = {};
          //시간
          const time_slice = ('0' + time_click.getHours()).slice(-2) + ":" + ('0' + time_click.getMinutes()).slice(-2);
          time_click.setMinutes(time_click.getMinutes() + 30);

          op.value = time_slice;
          op.label = time_slice;

          if (target === "from") {
            fromtimeList.push(op);
            // e.target.defaultValue =fromtimeList[0].value;
          } else {
            totimeList.push(op);
          }
        }
        compare_worked();
      };

      // From, To 데이터 변경에 따른 Worked데이터 입력
      const compare_worked = () => {

        //from,to 시간 차이 계산
        let compare_from = fromTime.split(":");
        let compare_to = toTime.split(":");

        const compare_time_from = new Date();
        compare_time_from.setHours(compare_from[0], compare_from[1]);

        const compare_time_to = new Date();
        compare_time_to.setHours(compare_to[0], compare_to[1]);

        var compare_time = compare_time_to.getTime() - compare_time_from.getTime();
        var compare_time_hour = compare_time / 1000 / 60 / 60;
        var compare_time_min = compare_time / 1000 / 60 - (parseInt(compare_time_hour) * 60);


        if (parseInt(compare_time_hour) == 0 && parseInt(compare_time_min) == 0) {
          return;
        }
        else if (parseInt(compare_time_hour) != 0 && parseInt(compare_time_min) != 0) {
          setComTime(parseInt(compare_time_hour) + "h " + parseInt(compare_time_min) + "m");
        }
        else if (parseInt(compare_time_hour) == 0) {
          setComTime(parseInt(compare_time_min) + "m");
        }

        else {
          setComTime(parseInt(compare_time_hour) + "h");
        }
      };



      const customStyles = useMemo(
        () => ({
          control: (provided, state) => ({
            ...provided,
            background: '#F7F7F7',
            height: '2.46153rem',
            minWidth: '6.667rem',
            fontSize: '0.84615rem',
            boxShadow: state.isFocused ? null : null,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "rgba(25, 118, 210, 0.08)"
              : state.isFocused
                ? 'rgba(0, 0, 0, 0.04)'
                : null,
            color: '#222',
            fontSize: '0.84615rem'
          }),

          valueContainer: (provided, state) => ({
            ...provided,
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
          }),
        }),
        []
      );

      const DropdownIndicator = (props) => {
        const { menuIsOpen } = props.selectProps;
        if (menuIsOpen) {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        } else {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        }
      };

      return (
        <div className={styles.modalContent}>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>이슈</div>
            {/* 이슈 셀렉트 */}
            <input
              label="이슈"
              value={issueValue}
              className={styles.createIssueInput}
              readOnly />
          </div>
          {divisionwork == true &&
            <div className={styles.createRow}>
              <div className={styles.createLabel}>Category</div>
              <div className={styles.createCategory}>
                <ReactSelect
                  onChange={(e) => changeCtgValue(e.value)}
                  placeholder="카테고리 선택"
                  options={selectCtgOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  defaultValue={ctgValue}
                  value={{ label: ctgValue, value: ctgValue }}
                />
              </div>
            </div>
          }
          <div className={styles.createRow}>
            <div className={styles.createLabel}>Description</div>
            <textarea
              placeholder="여기에 입력하세요"
              type="text"
              defaultValue={worklogbodyValue}
              className={styles.createTextArea}
              onChange={(e) => changeupdateWorklogbodyValue(e)}
              rows='4'
            /*id='create-modal-select-textarea'*/
            ></textarea>
          </div>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>날짜</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <div className={styles.datePicker}>
                  <DatePicker
                    value={dayjs(dateValue)}
                    onChange={changenewDateValue}
                    format="YYYY-MM-DD"
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '2.46153rem',
                        fontSize: '0.84615rem',
                        overflow: 'hidden',
                        width: '400px'
                      }
                    }}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>근태 시간<br/>
              {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totalshrs+')'
                : ' (0h)'
              }
            </div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "100px" }}>
              {shrsWorkTimeList.length > 0
                ? shrsWorkTimeList.map((list) => (
                  <MenuItem style={{ fontSize: '0.84615rem' }} disabled>{list}</MenuItem>
                ))
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 근태 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>등록된 시간<br/>
            {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totaltr+')'
                : ' (0h)'
              }</div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "150px", fontSize: '0.84615rem' }} >
              {trWorkTimeList.length > 0
                ? rendering()
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 작업량 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>








          <div className={styles.createRow}>
            <div className={styles.createLabel}>시간 선택</div>
            <div className={styles.multiItem}>
              <div className={styles.createFlex}>
                <ReactSelect
                  placeholder="시간 선택"
                  options={fromtimeList}
                  defaultValue={fromTime}
                  inputValue={searchFromTime}
                  value={{ label: fromTime, value: fromTime }}
                  onInputChange={changeFromTimeInput}
                  onKeyDown={checkFromTimeKeydown}
                  onBlur={(e) => checkFromTimeBlur(e)}
                  onChange={onchangeFromTime}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
                <FormHelperText id="list-select-update-from-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
              </div>
              {/* <p>
            fromTime: <b>{fromTime}</b>
            </p> */}
              <span className={styles.span}>~</span>
              <div className={styles.createFlex}>
                <ReactSelect
                  placeholder="시간 선택"
                  options={totimeList}
                  defaultValue={toTime}
                  inputValue={searchToTime}
                  value={{ label: toTime, value: toTime }}
                  onInputChange={changeToTimeInput}
                  onKeyDown={checkToTimeKeydown}
                  onBlur={(e) => checkToTimeBlur(e)}
                  onChange={onchangeToTime}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
                <FormHelperText id="list-select-update-to-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>Worked</div>
            <input
              placeholder="0h"
              className={styles.createInput}
              onChange={(e) => setComTime(e.target.value)}
              onKeyDown={checkWorkedKeydown}
              onBlur={(e) => checkWorkedTimeColon(e.target.value)}
              value={comTime}
            />
          </div>
          <div className={styles.createRowWrap}>
            <div className={styles.createLabel} />
            {/* <div className={styles.createhelper}>근태와 동일하게 등록 바랍니다.</div> */}
            <div className={styles.createhelper}>실제 근태와 지라 근태는 차이가 있을 수 있습니다. 실제 근태는 SHRS 시스템, 차이나는 이유는 HELP를 참고해주세요.</div>

          </div>
        </div>
      );
    });

    // 시간기록 수정 모달 오픈
    setupModalInfo({
      isOpen: true,
      isClose: false,
      childNode: ({ setAddInfo }) => <UpdateModalView setAddInfo={setAddInfo} />,
      isBackdropClose: true,
      sx: {}, // 모달 style
      btnInfo: {
        confirm: {
          name: '수정',
          callback: async (props) => {
            let date = dayjs(props.addInfo.dateValue);

            //Description이 null이면 카테고리 값으로 처리
            let worklogbodychk = props.addInfo.worklogbodyValue;
            if (worklogbodychk == "") {
              worklogbodychk = props.addInfo.ctgValue;
            }

            //startdate 
            let fromdate = dayjs(props.addInfo.dateValue);
            let fromchk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", 0).format("YYYY-MM-DD HH:mm:ss");

            //enddate
            let todate = dayjs(props.addInfo.dateValue);
            let tochk;


            //timeworked 시간 계산
            let timeworked = props.addInfo.comTime;
            let timework_h, timework_m;

            //timeworked 형변환
            if (timeworked.includes('h') && timeworked.includes('m')) {     // 시간, 분 모두 입력된 경우
              timeworked = timeworked.toString().split(" ");
              timework_h = timeworked[0].replace(/h/g, "");
              timework_m = timeworked[1].replace(/m/g, "");
              timeworked = ((parseInt(timework_h) * 60) * 60) + (parseInt(timework_m) * 60);
            } else if (timeworked.includes('h')) {      // 시간만 입력된 경우
              timework_h = timeworked.toString().split("h");
              timeworked = (parseInt(timework_h) * 60) * 60;
            } else {    // 분만 입력된 경우
              timework_m = timeworked.toString().split("m");
              timeworked = (parseInt(timework_m) * 60);
            }

            //tochk = fromTime + timeworked 
            tochk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", timeworked).format("YYYY-MM-DD HH:mm:ss");


            if (props.addInfo.comTime == "0h" || props.addInfo.comTime == "") {
              alert("시간입력을 하세요.");
              return;
            } else {

              // 프로그레스 오픈
              setIsCallApi(true);

              let datechk = date.format("YYYY-MM-DD");

              //근태 등록 체크 
              const statechk = 'update';

              //근태 등록 from 
              let hrfromchk = props.addInfo.fromTime.substr(0, 2) + props.addInfo.fromTime.substr(3, 5);

              //근태 등록 to
              let hrtochk = props.addInfo.toTime.substr(0, 2) + props.addInfo.toTime.substr(3, 5);


              //근태 등록 여부 체크 API 호출
              let tempRst = await post('etc/timecuchk', {
                "userName": userId,
                "date": datechk,
                "fromTime": hrfromchk,
                "toTime": hrtochk,
                "timeSpent": timeworked,
                "division": statechk,
                "updateWorklogId": rowDtlData[e.target.value].wid
              });


              // 프로그레스 닫기
              setIsCallApi(false);

              //근태 등록이 필요하면 호출 [Y(시간기록 가능), NSFT(근태시간 부족), NT(작업량 존재), NST(Total 시간 초과)]
              //totaltimechk == return 값 체크 [WORK(근태화면), STAY(팝업 그대로), OK(성공 - 등록/수정)]
              if (tempRst == 'NSFT') {
                alert("입력하신 시간은 근무 시간이 아닙니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk, rowDtlData[e.target.value].wid, rowDtlData[e.target.value].issuekey, props.addInfo.ctgValue, encodeURI(worklogbodychk), timeworked);
                return props.addInfo.totaltimechk = "WORK";
              } else if (tempRst == 'NT') {
                alert("입력하신 시간에 이미 기록된 시간이 있습니다. 확인 후 입력 부탁 드립니다.")
                return props.addInfo.totaltimechk = "STAY";
              } else if (tempRst == 'NST') {
                alert("근태 시간이 부족합니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk, rowDtlData[e.target.value].wid, rowDtlData[e.target.value].issuekey, props.addInfo.ctgValue, encodeURI(worklogbodychk), timeworked);
                return props.addInfo.totaltimechk = "WORK";
              } else {




                // 프로그레스 오픈
                setIsCallApi(true);

                let tempRstUpdate = await post('worklog/updateWorklog', {
                  "issueKey": rowDtlData[e.target.value].issuekey,
                  "userName": userId,
                  "startDate": fromchk,
                  "endDate": tochk,
                  "worklogId": rowDtlData[e.target.value].wid,
                  "category": props.addInfo.ctgValue,
                  "worklogBody": encodeURI(worklogbodychk),
                  "timeSpent": timeworked
                });


                // 프로그레스 닫기
                setIsCallApi(false);

                if (tempRstUpdate[0] === false) {
                  alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
                } else {


                  // 타임아웃 인스턴스 존재 체크
                  if (!!timeoutInstcs.errAlert) return false;
                  // errAlert 오픈
                  setErrAlertInfo({ open: true, msg: '수정되었습니다.' });
                  // errAlert에 대한 인스턴스 세팅
                  timeoutInstcs.errAlert = setTimeout(() => {
                    // errAlert 닫기
                    setErrAlertInfo({ open: false, msg: null });
                    // clear 인스턴스
                    clearTimeoutInstcs('errAlert');
                  }, 1200);
                  //상세보기 팝업창 닫기
                  setDtlModalInfo({ isClose: true });

                  //달력 기간 이벤트 재검색 
                  getCurrentMonth(allCalData);

                  //getWlrList(1);
                  return false;
                }
                return props.addInfo.totaltimechk = "OK";
              }
            }
          }
        },
        cancle: {
          name: '취소'
        }
      }
    })
  }

  const OpenWorkModal = (statechk, datechk, fromchk, tochk, wid, issuekey, ctgValue, worklogbody, timeworked) => {

    // Work Modal View 컴포넌트
    const WorkModalView = memo(({
      setAddInfo = (props) => { }
    }) => {
      const [WorkData, setWorkData] = useState([]);
      const [WorkOption, setWorkOption] = useState('');

      const [totaltimechk, setTotaltimechk] = useState("");

      const [Date, setDate] = useState(datechk);

      // setAddInfo props 
      useEffect(() => {
        setAddInfo({ totaltimechk, statechk, WorkData, Date, fromchk, tochk, wid, issuekey, ctgValue, worklogbody, timeworked });
      }, [totaltimechk, statechk, WorkData, Date, fromchk, tochk, wid, issuekey, ctgValue, worklogbody, timeworked]);


      useEffect(() => {
        workch();
      }, []);


      const workch = async () => {

        // 프로그레스 오픈
        setIsCallApi(true);

        let tmpSeletList;

        // 근태 시간 데이터 조회
        let tempRst = await post('hr/getBydatehrlogtime ', {
          "date": datechk,
          "userName": userId,
        });

        // 프로그레스 닫기
        setIsCallApi(false);

        let datas_select = tempRst ?? [];
        if (_.isArray(tempRst?.[0])) {
          datas_select = tempRst[0];
        }

        tmpSeletList = _.map(datas_select, x => {
          return x;
        });

        //근태 시간 데이터 셋팅
        setWorkData(tmpSeletList)
      }



      //if(WorkData.length >0) {

      //근태 시간 조회 API 값 정상여부 체크 
      if (WorkData) {
        return (
          <Work workDatacheck={WorkData} date={datechk} userId={userId} />
        );
        //근태 시간 조회 API 값 없을 시에 해당 메시지 호출
      } else {
        alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.")
      }
    });
    // 시간기록 모달 오픈
    setWorkModalInfo({
      isOpen: true,
      childNode: ({ setAddInfo }) => <WorkModalView setAddInfo={setAddInfo} />,
      isBackdropClose: true,
      sx: {}, // 모달 style
      btnInfo: {
        confirm: {
          name: '작업량 등록',
          callback: async (props) => {

            // 프로그레스 오픈
            setIsCallApi(true);

            //근태 등록 from 
            let hrfromchk = props.addInfo.fromchk.substr(11, 2) + props.addInfo.fromchk.substr(14, 2);

            //근태 등록 to
            let hrtochk = props.addInfo.tochk.substr(11, 2) + props.addInfo.tochk.substr(14, 2);


            //근태 등록 여부 체크 API 호출
            let tempRst = await post('etc/timecuchk', {
              "userName": userId,
              "date": props.addInfo.Date,
              "fromTime": hrfromchk,
              "toTime": hrtochk,
              "timeSpent": props.addInfo.timeworked,
              "division": statechk,
              "updateWorklogId": props.addInfo.wid
            });

            // 프로그레스 닫기
            setIsCallApi(false);

            //근태 등록이 필요하면 호출 [Y(시간기록 가능), NSFT(근태시간 부족), NT(작업량 존재), NST(Total 시간 초과)]
            //totaltimechk == return 값 체크 [WORK(근태화면), STAY(팝업 그대로), OK(성공 - 등록/수정)]
            if (tempRst == 'NSFT') {
              alert("입력하신 시간은 근무 시간이 아닙니다. 근태를 먼저 등록해주시길 바랍니다.")
              return props.addInfo.totaltimechk = "STAY";
            } else if (tempRst == 'NT') {
              alert("입력하신 시간에 이미 기록된 시간이 있습니다. 확인 후 입력 부탁 드립니다.")
              return props.addInfo.totaltimechk = "STAY";
            } else if (tempRst == 'NST') {
              alert("근태 시간이 부족합니다. 근태를 먼저 등록해주시길 바랍니다.")
              return props.addInfo.totaltimechk = "STAY";
            } else {

              //시간 등록일 때, props.addInfo.wid  == '' 
              if (statechk == 'insert') {

                // 프로그레스 오픈
                setIsCallApi(true);

                let tempRst = await post('worklog/createWorklog', {
                  "issueKey": props.addInfo.issuekey,
                  "userName": userId,
                  "startDate": props.addInfo.fromchk,
                  "endDate": props.addInfo.tochk,
                  "category": props.addInfo.ctgValue,
                  "worklogBody": props.addInfo.worklogbody,
                  "timeSpent": props.addInfo.timeworked

                });


                // 프로그레스 닫기
                setIsCallApi(false);

                if (tempRst[0] === false) {
                  alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
                } else {

                  // 타임아웃 인스턴스 존재 체크
                  if (!!timeoutInstcs.errAlert) return false;
                  // errAlert 오픈
                  setErrAlertInfo({ open: true, msg: '등록되었습니다.' });
                  // errAlert에 대한 인스턴스 세팅
                  timeoutInstcs.errAlert = setTimeout(() => {
                    // errAlert 닫기
                    setErrAlertInfo({ open: false, msg: null });
                    // clear 인스턴스
                    clearTimeoutInstcs('errAlert');
                  }, 1200);

                  //등록 팝업창 닫기
                  setModalInfo({ isClose: true });

                  /*  //기존에 검색한 경우, 작업기록 등록처리 후 검색 처리
                    if(searchChk == true) {
                      //getWlrList(1);
                    }
                    */
                  //달력 기간 이벤트 재검색 
                  getCurrentMonth(allCalData);

                  return false;

                }


                // 시간 변경일 때, props.addInfo.wid  == 특정 값
              } else if (statechk == 'update') {

                // 프로그레스 오픈
                setIsCallApi(true);

                let tempRst = await post('worklog/updateWorklog', {
                  "issueKey": props.addInfo.issuekey,
                  "userName": userId,
                  "startDate": props.addInfo.fromchk,
                  "endDate": props.addInfo.tochk,
                  "worklogId": props.addInfo.wid, //추가필요
                  "category": props.addInfo.ctgValue,
                  "worklogBody": props.addInfo.worklogbody,
                  "timeSpent": props.addInfo.timeworked
                });


                // 프로그레스 닫기
                setIsCallApi(false);

                if (tempRst[0] === false) {
                  alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
                } else {

                  // 타임아웃 인스턴스 존재 체크
                  if (!!timeoutInstcs.errAlert) return false;
                  // errAlert 오픈
                  setErrAlertInfo({ open: true, msg: '수정되었습니다.' });
                  // errAlert에 대한 인스턴스 세팅
                  timeoutInstcs.errAlert = setTimeout(() => {
                    // errAlert 닫기
                    setErrAlertInfo({ open: false, msg: null });
                    // clear 인스턴스
                    clearTimeoutInstcs('errAlert');
                  }, 1200);

                  //수정 팝업창 닫기
                  setupModalInfo({ isClose: true });

                  //상세보기 팝업창 닫기
                  setDtlModalInfo({ isClose: true });

                  //달력 기간 이벤트 재검색 
                  getCurrentMonth(allCalData);
                  //getWlrList(1);
                }

              } else {
                //차이 시간 클릭 후 근태 등록 시
              }
              //작업량 등록 --> 시간등록 시 입력한 데이터를 가지고 와서 처리 (props)
              return props.addInfo.totaltimechk = "OK";
            }
          },
          cancle: {
            name: 'Cancle'
          }
        },
      }
    })
  }

  const getEvent = (eventInfo) => {

    let count = eventInfo.event.extendedProps.count-1;
    //이벤트 가져오기
    return (
      <>
        {eventInfo.event.extendedProps.count != null &&
          <>
           <div style={{fontSize: '12px' }}><b>{eventInfo.event.extendedProps.rstarttime+' ~ '+eventInfo.event.extendedProps.rendtime}</b>
            {eventInfo.event.extendedProps.count > 1 
            &&
            <div style={{ float: "right" , color:"blue", fontSize: '12px' }}>{'   + '+count+' more...'}</div>
             }</div>
            <div style={{fontSize: '12px' }}><p>{eventInfo.event.title}</p></div>
          </>
        }
      </>
    )

  }

  return (
    <div className={styles.bodyStyle}>
      <div className="list-grid-div">     
        {userId != "" &&
          <FullCalendar
            customButtons= {{
              customPrevButton: {
                hint: "이전",
                text: '<',
                click:  function (){

                  clearTimeoutInstcs('PrevButton');
                  // 타임아웃 인스턴스 세팅
                  timeoutInstcs.PrevButton = setTimeout(() => {

                    // calendarApi 조회
                    const calendarAPI = calendarRef?.current?.getApi();
                    // 이전 버튼 
                    calendarAPI?.prev();

                    // 인스턴스 clear
                    clearTimeoutInstcs('PrevButton');
                  }, 500)
                
                },
              },  
              customNextButton: {
                hint: "다음",
                text: '>',
                click: function (){
                  
                  clearTimeoutInstcs('NextButton');
                  // 타임아웃 인스턴스 세팅
                  timeoutInstcs.NextButton = setTimeout(() => {

                    // calendarApi 조회
                    const calendarAPI = calendarRef?.current?.getApi();
                    // 다음 버튼 
                    calendarAPI?.next();

                    // 인스턴스 clear
                    clearTimeoutInstcs('NextButton');
                  }, 500)

                },
              },
              customRequestButton: {
                text: '개선요청',
                click: function (){
                  
                  window.open("https://jira.sinc.co.kr/servicedesk/customer/portal/81/group/1607")

                },
              },
              customHelpButton: {
                text: 'HEIP',
                click: function (){
                  
                  window.open("https://confl.sinc.co.kr/pages/viewpage.action?pageId=236169639")

                },
              },
            }}
            headerToolbar={{
              left: "today customPrevButton customNextButton",
              center: "title",
              end: "customRequestButton customHelpButton",
            }}
            plugins={[timeGridPlugin, interactionPlugin, bootstrapPlugin, momentPlugin ]}
            views={{
              timeGridWeek: {
                  dayHeaderContent: (date) => {
                    if(dayCount == 0){
                      const nowDayFormat = dayjs(date.date).format('YYYY-MM-DD');
                      for(let list of weekSumTiem) {

                        if(list.date == nowDayFormat){
                          flg = false;

                          return <div><div style={{ fontSize: "1.2em" }}>{dayjs(list.date).locale('ko').format('MM.DD')+'('+dayjs(list.date).locale('ko').format('ddd')+')'}</div><div style={{ fontSize: "0.9em",
                            fontWeight: "500" }}>{parseFloat((list.trtotal/3600).toFixed(2))+'h'+ ' / ' + parseFloat((list.total/3600).toFixed(2))+'h'}</div></div>
                        }
                      }

                      return <div><div style={{ fontSize: "1.2em" }}>{dayjs(nowDayFormat).locale('ko').format('MM.DD')+'('+dayjs(nowDayFormat).locale('ko').format('ddd')+')'}</div><div style={{ fontSize: "0.9em",
                      fontWeight: "500" }}>{'0h / 0h'} </div></div>
                    }
                    
                  },
                  titleFormat: "YYYY" + "년 " + "MM" + "월 " + "DD" + "일",  //title
              }
            }}
            events={caldata}
            //annotations={shrsdata}
            slotLabelFormat={{   //24시간 지정
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            //locale='en-GB' //24:00 , 24:30 방지 
            locale='en-GB' //24:00 , 24:30 방지 
            slotDuration='00:15' // 시간 간격 (15분)
            scrollTime='09:00'    // 처음 시작하는 위치
            eventClick={eventClick}  //일정 클릭시 발생할 이벤트 (기 이벤트)
            dateClick={openModal} //날짜 클릭 시 발생할 이벤트
            // dateClick={(e) => dateselect_test(e)} //날짜 클릭 시 발생할 이벤트
            //select={(e) => dateselect_test(e)} //현재 클릭한 일자 및 시간 (from~to)
            // initialDate ="2023-04-23" //대표하는 날짜 (테스트용)
            datesSet={getCurrentMonth} //달력의 날짜 범위가 처음설정되거나, 변경된 후 호출

            eventMouseEnter={function (mouseEnterInfo) {   //event에 마우스 올리면 활성화

              tippy(mouseEnterInfo.el, {

                content: mouseEnterInfo.event.title,
                placement: "bottom", //툴팁 방향
                theme: 'yellow', //css테마 설정된 데이터 값
                arrow: true //툴팁에서 말풍선 모양처럼 화살표 출력여부
              });

            }}

            eventContent={getEvent} //event 화면에 노출 처리
            allDaySlot={false}  //allday slot false 처리 

            //dayHeaderContent={(e) => getHederInput(e,1)}
            themeSystem='bootstrap'
            nowIndicator={true} //현재 시간 캘린더 표시
            selectMirror={true} 
            //selectable={true} //현재 클릭한 셀 표시
            buttonText={{      //버튼 텍스트
              today: "오늘",
            }}

            defaultView="week"

            businessHours={busHours}  //비즈니스 시간 = 출퇴근 시간
            selectConstraint={busHours} // 선택 가능한 시간
            ref={calendarRef} //ref calendar api
          />
        }
        {<div className={styles.workComment}> * 실제 근태와 지라 근태는 차이가 있을 수 있습니다. 실제 근태는 SHRS 시스템, 차이나는 이유는 HELP를 참고해주세요. </div>}
       </div>
    </div>
  );
}
export default MyWork;
