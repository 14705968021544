import axios from 'axios';

const API_BASE_URL = 'https://allview.sinc.co.kr/api';

const getuserId = async (url) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${url}`);
    return response.data;
  } catch (error) {
    console.error(error);
  } finally { }
};

const post = async (url, data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/${url}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  } finally { }
};

const get = async (url, params) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${url}`, { params });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  } finally { }
};

export {
  get,
  post,
  getuserId
};