import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormHelperText from '@mui/material/FormHelperText';

// Css
import './Work.css';
import styles from './Work.module.css';


// Util
import _ from 'lodash';
import { Button } from '@mui/material';

import React, { Component } from 'react';
import ReactSelect, { components } from 'react-select';

import { useEffect, useState } from 'react';




const Work = (props) => {

  //console.log(props)
  //console.log("사번:"+props.userId)
  //console.log("일자:"+props.date)

  //근태종류
  const [WorkOption, setWorkOption] = useState('');

  let selectWorkOptions = [
    { value: "P_DTM_MS10_10", label: "근무시간조정신청" },
    { value: "P_DTM_MS10_20", label: "VPN 신청" },
    { value: "P_DTM_M10_060", label: "시간외근무신청" }
  ];

  /* Hooks */
  // 디폴트 훅
  useEffect(() => {
  },);


  //근태종류 체크
  const click_work = (e) => {
    setWorkOption(e.value)
  };

  //SHRS 이동 버튼 클릭
  const click_shrs = () => {
    //근태 종류 선택하지 않은 경우
    if (WorkOption == '') {
      alert('근태 종류 체크해주세요.')
      return;
    }
    //SHRS 링크 연동
    //alert("click==>사번: " + props.userId + ",근무일자: " + props.date + ",근태종류: " + WorkOption)
    window.open("https://hr.shinsegae.com/SSOClient/sso.jsp?menuId="+WorkOption)
  }
  return (
    <div>
      <div className={styles.modalContent}>
        <div className={styles.createRow}>
          <div className={styles.createLabel}>SHRS 근태 종류</div>
          <div className={styles.createCategory}>
            <ReactSelect
              placeholder="선택하세요"
              options={selectWorkOptions}
              onChange={(e) => click_work(e)}
            />
          </div>
        </div>
        <div className={styles.createRowWrap}>
          <div className={styles.createLabel}>SHRS 근태 시간</div>
          <table className="issue-dtl-table">
            <thead>
              <tr>
                <th> 근태 일자 </th>
                <th> 근태 시작 시간 </th>
                <th> 근태 종료 시간 </th>
                <th> Total </th>
              </tr>
            </thead>
            <tbody>
              {props.workDatacheck.map(res => (
                <tr key={res.rownum}>
                  <td>{res.hday}</td>
                  <td>{res.starttime}</td>
                  <td>{res.endtime}</td>
                  <td>{res.worktime}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>SHRS 근태 등록</div>
            <div className={styles.createCategory}>
              <button onClick={click_shrs}>   SHRS 근태 등록 (SHRS 페이지 이동)</button>
            </div>


          </div>
          <br />
          <FormHelperText id="list-select-insert-work-helper"
            sx={{ fontSize: '0.8rem', display: 'flex', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
          >
            근태 반영 후 작업량 등록 부탁 드립니다.<br />
            순서{')'} SHRS 근태 등록 버튼 클릭 {'>'} SHRS 근태 상신 {'>'} 작업량등록 버튼 클릭
          </FormHelperText>
        </div>


      </div>
    </div>

  );

}

export default Work;