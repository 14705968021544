import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// Css
import styles from './UpdateModal.module.css';

// Util
import _ from 'lodash';
import { Button } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const UpdateModal = ({
  isOpen = false,
  isClose = false,
  childNode = (addInfo) => {},
  isBackdropClose = true, 
  onClose,
  sx = {
    width: '60%',
    height: '60%',
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '1.5rem'
  },
  btnInfo = {
    confirm: {
      name: null,
      callback: null
    },
    cancle: {
      name: null
    }
  } 
}) => {
  /* states */
  const [open, setOpen] = React.useState(false);
  const [addInfo, setAddInfo] = React.useState(null);
  const [isCallApi, setIsCallApi] = useState(false);
    
  /* Hooks */
  // props > isOpen 감지
  useEffect(() => {
    if(!isOpen) return;
    handleOpen();
  }, [isOpen]);

  useEffect(() => {
    if(!isClose) return;
    handleClose();
  }, [isClose]);

  /* 로컬 함수 */
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose(true);
    };

  /*
    const test = useCallback(() => {
      //debugger
      btnInfo.confirm.callback({
        "addInfo": addInfo 
      });
    }, [addInfo]);

    */

  // confirm 버튼 동작 함수
  const handleConfirm = useCallback(async () => {
    setIsCallApi(true);
    await btnInfo.confirm.callback({
      "addInfo": addInfo
    });

    //유효성 체크 
    if (addInfo.comTime == "0h" || addInfo.comTime == "") {
      setIsCallApi(false);
      return;
      //근태 관련 유효성 체크 (api - timecuchk)
    } else if (addInfo.totaltimechk == "WORK") {
      setIsCallApi(false);
    } else if (addInfo.totaltimechk == "STAY") {
      setIsCallApi(false);
      return
    } else if (addInfo.totaltimechk == "OK") {
      setIsCallApi(false);
      handleClose();
    } else {
      setIsCallApi(false);
      handleClose();
    }


  }, [addInfo]);

  return (
    <Modal
        className="common-modal"
        open={open}
        //onClose={isBackdropClose && handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box 
          sx={
            _.assign({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '550px',
              bgcolor: '#F7F7F7',
              boxShadow: 24,
              borderRadius: '0.5rem',
            }, sx)
          }
         >
          <div className="header">
            <div>시간기록 수정</div>
            <svg onClick={handleClose} xmlns="http://www.w3.org/2000/svg" width="1.23076rem" height="1.23076rem" fill="#fff" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          {/* 프로그래스 */}
          {<Backdrop
            sx={{ backgroundColor: '#D3D3D370', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isCallApi}
          >
            <CircularProgress />
          </Backdrop>}
        </div>
        {childNode && childNode?.({ setAddInfo: setAddInfo })}
        <div className="footer">
          {btnInfo?.confirm?.name && (
            <Button
              className="search xsmall"
              onClick={handleConfirm}
            >
              {btnInfo?.confirm?.name}
            </Button>
          )}
          <Button
            className="cancel xsmall"
            onClick={handleClose}
          >
            {'취소'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default memo(UpdateModal);