import errorimg from '../../images/Errorimg.png';

const Error = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <img 
            src={errorimg}
            />
        </div>
    );
};

export default Error;