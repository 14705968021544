import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

// Css
import './SearchOneSelect.css';
import styles from './SearchOneSelect.module.css';

// Util
import { timeoutInstcs, clearTimeoutInstcs } from 'utils/utils';
import { get, post, getuserId } from 'utils/api';
import _ from 'lodash';
import { OutlinedInput } from '@mui/material';


import { app, Context } from "@microsoft/teams-js";

import Button from '@mui/material/Button';

import Backdrop from '@mui/material/Backdrop';

const SearchOneSelect = ({
  id = 'testSelect',
  label = '테스트', 
  inputLabel = 'Test select', 
  isEssential = false, 
  formSx = { m: 1, width: 200, margin: '8px 8px 0 8px' },
  mappingInfo = { key: 'ID', value: 'PNAME', preValue: null, postValue: null , fId: null},
  apiName = null, // API Name
  isGetMethod = true, // GET 방식 호출 여부
  isCreateModal = false, //Create모달에 라벨 제거
  handleChkdList = (list = []) => {}, // 체크된 항목 부모 컴포넌트로 보내기 위한 핸들링 함수
  oneUserId = null
}) => {
  /* state */
  const theme = useTheme();
  const [itemList, setItemList] = useState([]);
  const [filtrdItemList, setFiltrdItemList] = useState([]);
  const [chkdItemList, setChkdItemList] = useState([]);

  const [chkdItem, setChkdItem] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [bottomProgress, setBottomProgress] = useState(false);
  const pageCntRef = useRef(1);
  const pageMaxCntRef = useRef(1);

  //이슈 리스트 종류 구분 셋팅
  const ChkdIssueSearch = useRef('rec');

  const [open, setOpen] = useState(false);

  const userId = oneUserId;

  const [isCallApi, setIsCallApi] = useState(false);


  /* Hooks */
  // searchKeyword 변경 감지 훅
  useEffect(() => {

  }, [userId,filtrdItemList]);

  useEffect(() => {
    // 검색어 null 이면 리턴
    if (searchKeyword == null) return;

    // 유저Id null 이면 리턴
    if (userId == null || userId == '') return;

    // page count 1로 초기화
    pageCntRef.current = 1;

    // 데이터 조회
    getDataList();
  }, [userId, searchKeyword]);

  // 체크된 리스트 state 감지
  useEffect(() => {
    handleChkdList(chkdItem);
    //debugger
  }, [chkdItem]);

// 키워드 있는 훅
  useEffect(() => {
    if(!itemList) return;

    // 체크된 항목 키워드 필터링
    // let filterdChkdItemList = _.filter(chkdItem, 
    //   x => _.includes(x[mappingInfo.value], searchKeyword)
    //     || mappingInfo.preValue != null && _.includes(_.toUpper(x[mappingInfo.preValue]), _.toUpper(searchKeyword))
    //     || mappingInfo.postValue != null && _.includes(_.toUpper(x[mappingInfo.postValue]), _.toUpper(searchKeyword))
    // ) ?? [];

    // 체크되지 않은 항목 필터링
    // let filterdUnchkdItemList = _.filter(itemList, tItem =>
    //   // 체크된 항목의 key리스트에 존재하지 않은 항목만 필터링
    //   !_.includes(
    //     // 체크된 리스트에서 key값 만 추출
    //     _.map(chkdItem, chkdItemKey => chkdItemKey[mappingInfo.key]), 
    //     tItem[mappingInfo.key]
    //   )
    // ) ?? [];

    //체크된
    // let filterdUnchkdItemList = _.filter(itemList, tItem =>
    //   // 체크된 항목의 key리스트에 존재하지 않은 항목만 필터링
    //   chkdItem?.[mappingInfo.key] == null || !_.includes(chkdItem[mappingInfo.key], tItem[mappingInfo.key])
    // ) ?? [];
    let filterdUnchkdItemList = chkdItem?.[mappingInfo.key] == null
      ? _.cloneDeep(itemList) 
      : _.filter(itemList, tItem =>
      // 체크된 항목의 key리스트에 존재하지 않은 항목만 필터링
      !_.includes(chkdItem[mappingInfo.key], tItem[mappingInfo.key])
    ) ?? [];

    //debugger
    // 필터드 리스트 세팅
    // setFiltrdItemList([...filterdChkdItemList, ...filterdUnchkdItemList]);
    // setFiltrdItemList([filterdChkdItemList, ...filterdUnchkdItemList]);
    setFiltrdItemList(
      (chkdItem == null 
        ? [] 
        : []
      ).concat([...filterdUnchkdItemList]));
  // }, [itemList, chkdItemList, searchKeyword]);
  }, [itemList, chkdItem]);

  /* 로컬 함수 */
  // 행 스타일
  const getRowStyles = useCallback((item, chkdItem, theme) => {
    return {
      fontWeight:
        _.indexOf(chkdItem, item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }, []);

  // 체크 리스트 변경
  const changeChkedSelectList = useCallback((event) => {
    const {
      target: { value },
    } = event;

    // 널 체크
    if(!value) return;


    // 체크된 리스트 세팅
    setChkdItem(value);

  }, [chkdItem]);

  // 검색 키워드 변경
  const changeSearchKeyword = _.debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    // 검색 키워드 변경
    setSearchKeyword(e.target.value);
  }, 300);

  // 선택된 리스트 초기화
  const clearChked = useCallback(() => {
    setChkdItem([]);
  }, []);

  // 셀렉트 박스 팝오버 오픈 시 호출
  const handleSelectOpen = useCallback((e) => {
    // 인풋 포커싱 타임아웃 인스턴스 clear
    clearTimeoutInstcs('SearchOneSelectInput');
    // 타임아웃 인스턴스 세팅
    timeoutInstcs.SearchOneSelectInput = setTimeout(() => {
      // input에 포커싱
      document.getElementById(id + 'Input')?.focus();
      // 인스턴스 clear
      clearTimeoutInstcs('SearchOneSelectInput');
    }, 300);

    //open 
    setOpen(true);
    // 키워드 초기화
    setSearchKeyword('');

    ChkdIssueSearch.current = 'rec';
  }, []);

  const handleSelectClose = useCallback((e) => {

    //이슈 검색 창 클릭 시, open 처리 
    if (e.currentTarget.className == 'search-select-input-area')
      return;

    //즐겨찾기 클릭 시 , open 처리 
    if (e.target.id == "fav-btn") 
    return;
     
    // 키워드 null 세팅
    // (원래 빈 값일때도 Hook 탈 수 있게 하기 위해)
    setSearchKeyword(null);

    //close 
    setOpen(false);
  }, []);



  // 데이터 조회
  const getDataList = useCallback((count) => {
    // validate check
    if(apiName == null) return;

    if(count == 1 ) {
        // page count 1로 초기화
        pageCntRef.current = 1;
      }
      
    setIsCallApi(true);
    // call api
    (isGetMethod 
      ? get 
      : post
    )(apiName, { range: pageCntRef.current, param: searchKeyword, userName: userId, option: ChkdIssueSearch.current })
      .then(res => {


        const temp = res[0].map((data, index) => {
          if (index == res[0].length - 1) {
            return ({ total: data.total, page: data.page })
          } else {
            return ({ id: data.id, summary: data.summary, issuekey: data.issuekey, fid: data.fid, check: data.fid == null ? false : true })
          }
        });



        let datas = temp; 
      //  let datas = res ?? [];


        // pageMaxCnt 세팅
        pageMaxCntRef.current = datas[datas.length - 1]?.page ?? 1;

        // page 정보를 제외한 나머지 세팅
        datas = _.slice(datas, 0, datas.length - 1);

        // pageCnt에 따라 데이터 세팅
        setItemList(prev => 
          pageCntRef.current > 1 
            ? prev.concat(datas) 
            : datas
        );

        // 하단 프로그레스 닫기
      //  if(pageCntRef.current > 1 ) {
     //     setBottomProgress(false);
     //   }
        setIsCallApi(false);
      });
  }, [userId, searchKeyword]);

  // 스크롤 하단 도달 시 호출
  const handleSelectScroll = useCallback((e) => {
    // 타임아웃 인스턴스 존재 시 리턴
    if(timeoutInstcs.bottomScrollDelay) return;
    // 프로그레스바 돌고 있으면 리턴
    if(bottomProgress) return;
    // pageMaxCnt 체크
    if(pageCntRef.current >= pageMaxCntRef.current) return;

    const target = e.target;
    if ((target.scrollTop + target.clientHeight) >= target.scrollHeight * 0.85) {
     // setBottomProgress(true);
      // pageCnt 증가
      pageCntRef.current += 1;
      // 조회
      getDataList();
      // 스크롤 지연 타임아웃 인스턴스 세팅
      timeoutInstcs.bottomScrollDelay = setTimeout(() => {
        // 인스턴스 clear
        clearTimeoutInstcs('bottomScrollDelay');
      }, 500);
    }
  }, [bottomProgress, userId, searchKeyword]);


  //즐겨찾기 버튼 클릭 시 즐겨찾기 추가/삭제 처리 
  const changeFav = useCallback(async (e, idx) => {

    let arr = [...filtrdItemList];


    if (filtrdItemList[idx].check == true) {

      //즐겨찾기 삭제 api
      let tempRst2 = await get('filter/deletev2/favorite', {
        "item": e.target.name,
        "userName": userId
      })

      if (tempRst2[0] === false) {
        alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
      } else {

        //삭제 정상 처리 후, fav 즐겨찾기이면 데이터 새로 불러오기
        if (ChkdIssueSearch.current == 'fav'){
          getDataList(1);
       }else{
        //삭제 정상 처리 후, 그 외는 값만 변경 
        arr[idx].check = false;
       }
      }



    } else {

      //즐겨찾기 생성 api
      let tempRst1 = await get('filter/create/favorite', {
        "item": e.target.name,
        "type": "ISSUE",
        "userName": userId
      })

      if (tempRst1[0] === false) {
        alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
      } else {
        arr[idx].check = true;
      }

    }

    setFiltrdItemList(arr)

   

  }, [filtrdItemList]);

    

  // 즐겨찾기 리스트 버튼 클릭 시, ListItemText 에 새로운 값 넣어주기
  const handleSelectFavorites = useCallback((e) => {

    //상단 버튼 종류에 따른 background color 변경 처리 
    e.target.style.backgroundColor = "#007eff45"

    document.getElementById('w-btn-indigo-rec').style.backgroundColor= 'aliceblue';

    document.getElementById('w-btn-indigo-ass').style.backgroundColor= 'aliceblue';
    
    document.getElementById('w-btn-indigo-all').style.backgroundColor= 'aliceblue';
 
    // page count 1로 초기화
    pageCntRef.current = 1;

    //이슈 리스트 종류 구분 셋팅
    ChkdIssueSearch.current = 'fav';

    
    setIsCallApi(true);

    //api 뿌려주기

    // call api
    (isGetMethod
      ? get
      : post
    )(apiName, { range: pageCntRef.current, param: searchKeyword, userName: userId, option: ChkdIssueSearch.current })
      .then(res => {

        const temp = res[0].map((data, index) => {
          if (index == res[0].length - 1) {
            return ({ total: data.total, page: data.page })
          } else {
            return ({ id: data.id, summary: data.summary, issuekey: data.issuekey, fid: data.fid, check: data.fid == null ? false : true })
          }
        });

        setIsCallApi(false);
        let datas = temp;  


        //let datas = res ?? [];
      //  if (_.isArray(res?.[0])) {
     //     datas = res[0];
     //   }

        // pageMaxCnt 세팅
        pageMaxCntRef.current = datas[datas.length - 1]?.page ?? 1;

        // page 정보를 제외한 나머지 세팅
        datas = _.slice(datas, 0, datas.length - 1);

        // pageCnt에 따라 데이터 세팅
        setItemList(prev =>
          pageCntRef.current > 1
            ? prev.concat(datas)
            : datas
        );

        // 하단 프로그레스 닫기
        if (pageCntRef.current > 1) {
          setBottomProgress(false);
        }
      });
  },[searchKeyword]);

  // 최근 리스트 버튼 클릭 시, ListItemText 에 새로운 값 넣어주기
  const handleSelectRecent = useCallback((e) => {

    //상단 버튼 종류에 따른 background color 변경 처리 
    e.target.style.backgroundColor = "#007eff45"

    document.getElementById('w-btn-indigo-fav').style.backgroundColor= 'aliceblue';

    document.getElementById('w-btn-indigo-ass').style.backgroundColor= 'aliceblue';
    
    document.getElementById('w-btn-indigo-all').style.backgroundColor= 'aliceblue';

    // page count 1로 초기화
    pageCntRef.current = 1;

    //이슈 리스트 종류 구분 셋팅
    
    //setChkdIssueSearch('rec')
    setIsCallApi(true);
   
    //api 뿌려주기
  
    ChkdIssueSearch.current = 'rec';

    // call api
    (isGetMethod
      ? get
      : post
    )(apiName, { range: pageCntRef.current, param: searchKeyword, userName: userId, option: ChkdIssueSearch.current })
      .then(res => {

        const temp = res[0].map((data, index) => {
          if (index == res[0].length - 1) {
            return ({ total: data.total, page: data.page })
          } else {
            return ({ id: data.id, summary: data.summary, issuekey: data.issuekey, fid: data.fid, check: data.fid == null ? false : true })
          }
        });

        setIsCallApi(false);
        let datas = temp;  

        //let datas = res ?? [];
      //  if (_.isArray(res?.[0])) {
     //     datas = res[0];
       // }

        // pageMaxCnt 세팅
        pageMaxCntRef.current = datas[datas.length - 1]?.page ?? 1;

        // page 정보를 제외한 나머지 세팅
        datas = _.slice(datas, 0, datas.length - 1);

        // pageCnt에 따라 데이터 세팅
        setItemList(prev =>
          pageCntRef.current > 1
            ? prev.concat(datas)
            : datas
        );

        // 하단 프로그레스 닫기
        if (pageCntRef.current > 1) {
          setBottomProgress(false);
        }
      });
  },[searchKeyword]);

  
  // 담당자할당 리스트 버튼 클릭 시, ListItemText 에 새로운 값 넣어주기
  const handleSelectAssigned  = useCallback((e) => {

    //상단 버튼 종류에 따른 background color 변경 처리 
    e.target.style.backgroundColor = "#007eff45"

    document.getElementById('w-btn-indigo-fav').style.backgroundColor= 'aliceblue';

    document.getElementById('w-btn-indigo-rec').style.backgroundColor= 'aliceblue';
    
    document.getElementById('w-btn-indigo-all').style.backgroundColor= 'aliceblue';

    // page count 1로 초기화
    pageCntRef.current = 1;

    //이슈 리스트 종류 구분 셋팅
    //setChkdIssueSearch('')


    //api 뿌려주기

    ChkdIssueSearch.current = 'ass';
    
    setIsCallApi(true);

    // call api
    (isGetMethod
      ? get
      : post
    )(apiName, { range: pageCntRef.current, param: searchKeyword, userName: userId, option:  ChkdIssueSearch.current })
      .then(res => {

        const temp = res[0].map((data, index) => {
          if (index == res[0].length - 1) {
            return ({ total: data.total, page: data.page })
          } else {
            return ({ id: data.id, summary: data.summary, issuekey: data.issuekey, fid: data.fid, check: data.fid == null ? false : true })
          }
        });

        setIsCallApi(false);
        let datas = temp;

        //let datas = res ?? [];
      //  if (_.isArray(res?.[0])) {
      //    datas = res[0];
      //  }

        // pageMaxCnt 세팅
        pageMaxCntRef.current = datas[datas.length - 1]?.page ?? 1;

        // page 정보를 제외한 나머지 세팅
        datas = _.slice(datas, 0, datas.length - 1);

        // pageCnt에 따라 데이터 세팅
        setItemList(prev =>
          pageCntRef.current > 1
            ? prev.concat(datas)
            : datas
        );

        // 하단 프로그레스 닫기
        if (pageCntRef.current > 1) {
          setBottomProgress(false);
        }
      });
  },[searchKeyword]);

  // 전체 리스트 버튼 클릭 시, ListItemText 에 새로운 값 넣어주기
  const handleSelectAll = useCallback((e) => {

    //상단 버튼 종류에 따른 background color 변경 처리 
    e.target.style.backgroundColor = "#007eff45"

    document.getElementById('w-btn-indigo-fav').style.backgroundColor= 'aliceblue';

    document.getElementById('w-btn-indigo-rec').style.backgroundColor= 'aliceblue';
    
    document.getElementById('w-btn-indigo-ass').style.backgroundColor= 'aliceblue';

    // page count 1로 초기화
    pageCntRef.current = 1;

    //이슈 리스트 종류 구분 셋팅
    //setChkdIssueSearch('')
    ChkdIssueSearch.current = 'com';
    //api 뿌려주기

    setIsCallApi(true);

    // call api
    (isGetMethod
      ? get
      : post
    )(apiName, { range: pageCntRef.current, param: searchKeyword, userName: userId, option:  ChkdIssueSearch.current })
      .then(res => {

        const temp = res[0].map((data, index) => {
          if (index == res[0].length - 1) {
            return ({ total: data.total, page: data.page })
          } else {
            return ({ id: data.id, summary: data.summary, issuekey: data.issuekey, fid: data.fid, check: data.fid == null ? false : true })
          }
        });

       setIsCallApi(false);
        let datas = temp;  

        //let datas = res ?? [];
     //   if (_.isArray(res?.[0])) {
       //   datas = res[0];
      //  }

        // pageMaxCnt 세팅
        pageMaxCntRef.current = datas[datas.length - 1]?.page ?? 1;

        // page 정보를 제외한 나머지 세팅
        datas = _.slice(datas, 0, datas.length - 1);

        // pageCnt에 따라 데이터 세팅
        setItemList(prev =>
          pageCntRef.current > 1
            ? prev.concat(datas)
            : datas
        );

        // 하단 프로그레스 닫기
      //  if (pageCntRef.current > 1) {
      //    setBottomProgress(false);
      //  }
      });
    },[searchKeyword]);


  return (
    <>
   
    <FormControl sx={formSx} size='small'>
      
      <InputLabel 
        id={id + 'Label'}
        sx={{ fontSize: '0.84615rem'}}
        shrink={!isCreateModal} 
      >
        {inputLabel + (isEssential ? ' *' : '')}
      </InputLabel>
          
      <Select
      
        sx={{ 
          fontSize: '0.84615rem',
          minWidth: '6.667rem',
          height: '2.46153rem',
          alignItems: 'centerm',
        }}
        labelId={id + 'Label'}
        id={id}
        value={chkdItem || ''}
        label={inputLabel + (isEssential ? ' *' : '')}
        input={isCreateModal ? null : <OutlinedInput notched label={inputLabel + (isEssential ? ' *' : '')}/>}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 350,
              width: '500px',
              whiteSpace: 'nowrap',
              fontSize: '0.84615rem',
              '::-webkit-scrollbar': {
                width: '0.4rem',
                height: '0.4rem'
              },
              '::-webkit-scrollbar-track': {
                background: '#f5f5f5',
                borderRadius: '10px'
              },
              '::-webkit-scrollbar-thumb': {
                background: '#666',
                borderRadius: '10px'
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: '#5a5a5a',
              },
            },
            onScroll: handleSelectScroll
          }
        }}
        onChange={changeChkedSelectList}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        open={open}
        renderValue={(selected) => {
          //debugger    
          return '('+selected.issuekey+') '+selected.summary;
        }}
        
      > 
        {/* 검색 인풋 영역 */}
        <div className="search-select-input-area">
          <input
            id={id + 'Input'}
            className="search-select-input"
            type="text"
            placeholder={label + ' 검색'}
            onKeyUp={changeSearchKeyword}
          />
        </div>
        <div class="search-w-btn">
       

        <Button id="w-btn-indigo-rec" class="w-btn w-btn-indigo" style={{backgroundColor:"#007eff45"}}  onClick={ handleSelectRecent }  >최근</Button>
        <Button id="w-btn-indigo-ass" class="w-btn w-btn-indigo" onClick={ handleSelectAssigned } >나에게 할당된</Button>
        <Button id="w-btn-indigo-fav" class="w-btn w-btn-indigo" onClick={handleSelectFavorites}>즐겨찾기</Button>
        <Button id="w-btn-indigo-all" class="w-btn w-btn-indigo" onClick={ handleSelectAll } >전체</Button>
        </div>

             {/* 프로그래스 */}
      {<Backdrop
        sx={{ backgroundColor: '#D3D3D370', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCallApi}
      >
        <CircularProgress />
      </Backdrop>}

        {/* 리스트 출력 영역 */}
        {filtrdItemList.length == 0 
          ? <p className="search-select-no-rlt-txt">검색 결과가 없습니다.</p> 
          : _.map(filtrdItemList, (tItem, idx) => (
            <MenuItem
              className="search-select-list"
              style={getRowStyles(tItem, chkdItem, theme)}
              key={tItem[mappingInfo.key]}
              value={tItem}
            >
      
              <ListItemText 
                className="search-select-txt" 
                primary={
                  (mappingInfo.preValue ? '(' + tItem[mappingInfo.preValue] + ') ' : '') 
                    + tItem[mappingInfo.value]
                    + (mappingInfo.postValue ? ' (' + tItem[mappingInfo.postValue] + ')' : '')
                }
                title={(mappingInfo.preValue ? '(' + tItem[mappingInfo.preValue] + ') ' : '')
                + tItem[mappingInfo.value]
                + (mappingInfo.postValue ? ' (' + tItem[mappingInfo.postValue] + ')' : '')}
              />

              <input type="checkbox" className="btn1" id="fav-btn" key={idx} name={tItem[mappingInfo.preValue]} value={tItem[mappingInfo.fId]} checked={tItem.check} onChange={(e) => changeFav(e, idx)} />

                    
            </MenuItem>))}
        {/* 프로그레스바 */}
        {bottomProgress && 
          <div 
            key={id + 'BottomProgress' }
            className="bottom-progress-area"
          >
            <CircularProgress size={'10%'} />
          </div>}
      </Select>
      {!isCreateModal &&
        <FormHelperText 
          sx={{fontSize: '0.65rem', display:'flex', alignItems:'flex-start', marginLeft:'0'}}
        >
          {label + ' 선택해주세요'}
        </FormHelperText>}
    </FormControl>
    </>
  );
};

export default memo(SearchOneSelect);