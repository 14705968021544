import React, { useState, useEffect, memo, useCallback, useRef, useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { CommonCtx } from 'App';

// Css
import './SearchSelect.css';
import styles from './SearchSelect.module.css';

// Util
import { timeoutInstcs, clearTimeoutInstcs } from 'utils/utils';
import { get, post, getuserId } from 'utils/api';
import _ from 'lodash';
import { OutlinedInput } from '@mui/material';


import { app, Context } from "@microsoft/teams-js";

const SearchSelect = ({
  id = 'testSelect',
  label = '테스트', 
  inputLabel = 'Test select', 
  isEssential = false, 
  formSx = { m: 1, width: 200, margin: '8px 8px 0 8px' },
  mappingInfo = { key: 'ID', value: 'PNAME', preValue: null, postValue: null},
  apiName = null, // API Name
  isGetMethod = true, // GET 방식 호출 여부
  isCreateModal = false, //Create모달에 라벨 제거
  handleChkdList = (list = []) => {}, // 체크된 항목 부모 컴포넌트로 보내기 위한 핸들링 함수
  sendIsReadyCpnt = (isReadyCpnt) => {},
  initChkdList = null
}) => {
  /* state */
  const theme = useTheme();
  const [itemList, setItemList] = useState([]);
  const [filtrdItemList, setFiltrdItemList] = useState([]);
  const [chkdItemList, setChkdItemList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [bottomProgress, setBottomProgress] = useState(false);
  const [isReadyCpnt, setIsReadyCpnt] = useState(false);
  const pageCntRef = useRef(1);
  const pageMaxCntRef = useRef(1);
  
  const {userId} = useContext(CommonCtx);


  /* Hooks */
  // searchKeyword 변경 감지 훅
  useEffect(() => {
    
  }, [userId]);
  
  useEffect(() => {
    // 검색어 null 이면 리턴
    if(searchKeyword == null) return;
    // 검색어 null 이면 리턴
    if(userId == null || userId == '') return;

    // page count 1로 초기화
    pageCntRef.current = 1;

    // 데이터 조회
    getDataList();

  }, [userId, searchKeyword]);

  // 체크된 리스트 state 감지
  useEffect(() => {
    handleChkdList(chkdItemList);
  }, [chkdItemList]);
  
  // 컴포넌트 준비 state 감지
  useEffect(() => {
    sendIsReadyCpnt(isReadyCpnt);
  }, [isReadyCpnt]);

// 키워드 있는 훅
  useEffect(() => {
    if(!itemList) return;

    // 체크된 항목 키워드 필터링
    let filterdChkdItemList = _.filter(chkdItemList, 
      x => _.includes(x[mappingInfo.value], searchKeyword)
        || mappingInfo.preValue != null && _.includes(_.toUpper(x[mappingInfo.preValue]), _.toUpper(searchKeyword))
        || mappingInfo.postValue != null && _.includes(_.toUpper(x[mappingInfo.postValue]), _.toUpper(searchKeyword))
    ) ?? [];

    // 체크되지 않은 항목 필터링
    let filterdUnchkdItemList = _.filter(itemList, tItem =>
      // 체크된 항목의 key리스트에 존재하지 않은 항목만 필터링
      !_.includes(
        // 체크된 리스트에서 key값 만 추출
        _.map(chkdItemList, chkdItem => chkdItem[mappingInfo.key]), 
        tItem[mappingInfo.key]
      )
    ) ?? [];

  
    // 필터드 리스트 세팅
    setFiltrdItemList([...filterdChkdItemList, ...filterdUnchkdItemList]);
  // }, [itemList, chkdItemList, searchKeyword]);
  }, [itemList, chkdItemList]);

  //분류구분 - 근태 선택 시 초기화 처리
  useEffect(() => {

    clearChkedList()

  }, [initChkdList])
  /* 로컬 함수 */
  // 행 스타일
  const getRowStyles = useCallback((item, chkdItemList, theme) => {
    return {
      fontWeight:
        _.indexOf(chkdItemList, item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }, []);

  // 체크 리스트 변경
  const changeChkedSelectList = useCallback((event) => {
    const {
      target: { value },
    } = event;

    // 널 체크
    if(!value) return;

    // 빈 배열 체크
    if((value?.length ?? 0) <= 0) {
      setChkdItemList([]);
      return;
    }

    // 빈 값 체크
    if(!value[value.length - 1]) return;

    let tValue = _.filter(value, (x) => !x?._isAll);

    // 체크된 리스트 세팅
    setChkdItemList(tValue);
  }, []);

  // 검색 키워드 변경
  const changeSearchKeyword = _.debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    // 검색 키워드 변경
    setSearchKeyword(e.target.value);
  }, 300);

  // 선택된 리스트 초기화
  const clearChkedList = useCallback(() => {
    setChkdItemList([]);
  }, []);

  // 셀렉트 박스 팝오버 오픈 시 호출
  const handleSelectOpen = useCallback((e) => {
    // 인풋 포커싱 타임아웃 인스턴스 clear
    clearTimeoutInstcs('searchSelectInput');
    // 타임아웃 인스턴스 세팅
    timeoutInstcs.searchSelectInput = setTimeout(() => {
      // input에 포커싱
      document.getElementById(id + 'Input')?.focus();
      // 인스턴스 clear
      clearTimeoutInstcs('searchSelectInput');
    }, 300);

    // 키워드 초기화
    setSearchKeyword('');
  }, []);

  const handleSelectClose = useCallback(() => {
    // 키워드 null 세팅
    // (원래 빈 값일때도 Hook 탈 수 있게 하기 위해)
    setSearchKeyword(null);
  }, []);

  // 데이터 조회
  const getDataList = useCallback(() => {
    // validate check
    if(apiName == null) return;
  
    // call api
     (isGetMethod 
      ? get 
      : post
    )(apiName, { range: pageCntRef.current, param: searchKeyword, userName: userId, option: "com" })
      .then(res => {
        if(!isReadyCpnt) {
          setIsReadyCpnt(true)
        }

        let datas = res ?? [];
        if(_.isArray(res?.[0])) {
          datas = res[0];
        }

        // pageMaxCnt 세팅
        pageMaxCntRef.current = datas[datas.length - 1]?.page ?? 1;

        // page 정보를 제외한 나머지 세팅
        datas = _.slice(datas, 0, datas.length - 1);

        // pageCnt에 따라 데이터 세팅
        setItemList(prev => 
          pageCntRef.current > 1 
            ? prev.concat(datas) 
            : datas
        );

        // 하단 프로그레스 닫기
        if(pageCntRef.current > 1 ) {
          setBottomProgress(false);
        }
      });
  }, [userId, searchKeyword]);

  // 스크롤 하단 도달 시 호출
  const handleSelectScroll = useCallback((e) => {
    // 타임아웃 인스턴스 존재 시 리턴
    if(timeoutInstcs.bottomScrollDelay) return;
    // 프로그레스바 돌고 있으면 리턴
    if(bottomProgress) return;
    // pageMaxCnt 체크
    if(pageCntRef.current >= pageMaxCntRef.current) return;

    const target = e.target;
    if ((target.scrollTop + target.clientHeight) >= target.scrollHeight * 0.85) {
      setBottomProgress(true);
      // pageCnt 증가
      pageCntRef.current += 1;
      // 조회
      getDataList();
      // 스크롤 지연 타임아웃 인스턴스 세팅
      timeoutInstcs.bottomScrollDelay = setTimeout(() => {
        // 인스턴스 clear
        clearTimeoutInstcs('bottomScrollDelay');
      }, 500);
    }
  }, [bottomProgress, userId , searchKeyword]);

  return (
    <FormControl sx={formSx} size='small'>
      <InputLabel 
        id={id + 'Label'}
        sx={{ fontSize: '0.84615rem'}}
        shrink={!isCreateModal}
      >
        {inputLabel + (isEssential ? ' *' : '')}
      </InputLabel>
      <Select
        sx={{ 
          fontSize: '0.84615rem',
          minWidth: '6.667rem',
          height: '2.46153rem',
          alignItems: 'centerm',
        }}
        multiple
        labelId={id + 'Label'}
        id={id}
        value={chkdItemList?.length == 0 
          ? [{ _isAll: true, [mappingInfo.value]: "전체" }] 
          : chkdItemList
        }
        label={inputLabel + (isEssential ? ' *' : '')}
        input={isCreateModal ? null : <OutlinedInput notched label={inputLabel + (isEssential ? ' *' : '')}/>}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 350,
              width: '500px',
              whiteSpace: 'nowrap',
              fontSize: '0.84615rem',
              '::-webkit-scrollbar': {
                width: '0.4rem',
                height: '0.4rem'
              },
              '::-webkit-scrollbar-track': {
                background: '#f5f5f5',
                borderRadius: '10px'
              },
              '::-webkit-scrollbar-thumb': {
                background: '#666',
                borderRadius: '10px'
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: '#5a5a5a',
              },
            },
            onScroll: handleSelectScroll
          }
        }}
        onChange={changeChkedSelectList}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        renderValue={(selected) => _.map(selected, x => x[mappingInfo.value]).join(', ')}
      >
        {/* 검색 인풋 영역 */}
        <div className="search-select-input-area">
          <input
            id={id + 'Input'}
            className="search-select-input"
            type="text"
            placeholder={label + ' 검색'}
            onKeyUp={changeSearchKeyword}
          />
        </div>
        {/* 선택된 항목 지우기 영역 */}
        <div className="search-selected-area">
          <div className="selected-item-del-area">
            <p 
              className="selected-del-font"
              onClick={clearChkedList}
            >
              선택된 항목 지우기
            </p>
          </div>
        </div>
        {/* 리스트 출력 영역 */}
        {filtrdItemList.length == 0 
          ? <p className="search-select-no-rlt-txt">검색 결과가 없습니다.</p> 
          : _.map(filtrdItemList, (tItem, idx) => (
            <MenuItem
              className="search-select-list"
              style={getRowStyles(tItem, chkdItemList, theme)}
              key={tItem[mappingInfo.key]}
              value={tItem}
            >
              <Checkbox 
                className="search-select-chk"
                checked={!!_.find(chkdItemList, cItem => cItem[mappingInfo.key] == tItem[mappingInfo.key])} 
              />
              <ListItemText 
                className="search-select-txt" 
                primary={
                  (mappingInfo.preValue ? '(' + tItem[mappingInfo.preValue] + ') ' : '') 
                    + tItem[mappingInfo.value]
                    + (mappingInfo.postValue ? ' (' + tItem[mappingInfo.postValue] + ')' : '')
                  }
                title={(mappingInfo.preValue ? '(' + tItem[mappingInfo.preValue] + ') ' : '')
                  + tItem[mappingInfo.value]
                  + (mappingInfo.postValue ? ' (' + tItem[mappingInfo.postValue] + ')' : '')}
              />
            </MenuItem>))}
        {/* 프로그레스바 */}
        {bottomProgress && 
          <div 
            key={id + 'BottomProgress' }
            className="bottom-progress-area"
          >
            <CircularProgress size={'10%'} />
          </div>}
      </Select>
      {!isCreateModal &&
        <FormHelperText 
          sx={{fontSize: '0.65rem', display:'flex', alignItems:'flex-start', marginLeft:'0'}}
        >
          {label + ' 선택해주세요'}
        </FormHelperText>}
    </FormControl>
  );
};

export default memo(SearchSelect);