import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AgGridReact } from 'ag-grid-react';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import ReactSelect, { components } from 'react-select';

import Work from '../Work/Work.js'

// Component
import { CommonCtx } from 'App';
import { SearchSelect, SearchOneSelect } from 'components';

// Css
import './Main.css';
import styles from './Main.module.css';

// Util
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import _ from 'lodash';
import { post, get, getuserId } from 'utils/api';
import { clearTimeoutInstcs, getFromToTimeList, getPeriodDate, timeoutInstcs, getMaxDate } from 'utils/utils';
import { duration, OutlinedInput } from '@mui/material';

import { app, Context } from "@microsoft/teams-js";
import { ConstructionOutlined, DeblurOutlined } from '@mui/icons-material';
import { Await } from 'react-router-dom';

import MenuList from '@mui/material/MenuList';
import { CSVLink } from 'react-csv';
const CustomPinnedRowRenderer = (props) => {
  return (<span style={props.style}>{props.value}</span>);
};

const Main = () => {
  dayjs.extend(isSameOrBefore);

  //csvlink ref (.cureent를 통해 기본값 불러오기)
  const csvLink = useRef();

  //excel 데이터 (header, data)
  const [excelHead, setExcelHead] = useState([]);
  const [excelData, setExcelData] = useState([]);


useEffect(() => {

  //excel 데이터 전달받은 legnth가 0보다 클 때 
  if(excelData.length > 0){
    //엑셀 다운로드 링크 호출
    csvLink.current.link.click();
    //엑셀 데이터 초기화 처리
    setExcelHead([]);
    setExcelData([]);
  }
}, [excelHead])

  /* states */
  const gridRef = useRef();
  const [gridHeaderInfo, setGridHeaderInfo] = useState('프로젝트/이슈/유저/카테고리별 집계');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [isDtlOpen, setIsDtlOpen] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);

  //엑셀 다운로드 From, To Date
  const [excelfromDate, setExcelFromDate] = useState(null);
  const [execeltoDate, setExcelToDate] = useState(null);

  //엑셀 다운로드  Division
  const [excelDivision, setExcelDivision] = useState(null);

    //엑셀 다운로드 Project
  const [excelProject, setExcelProject] = useState(null);

    //엑셀 다운로드 Group
  const [excelGroup, setExcelGroup] = useState(null);

  //엑셀 다운로드 Issue
  const [excelIssue, setExcelIssue] = useState(null);

  //엑셀 다운로드 User
  const [excelUser, setExcelUser] = useState(null);

  //엑셀 다운로드 Team
  const [excelTeam, setExcelTeam] = useState(null);

  //엑셀 다운로드 WorkRange
  const [excelWorkRange, setExcelWorkRange] = useState(null);

  //selectbox List 초기화
  const [initList, setinitList] = useState(false);

  //Total row data
  const [rowTotalData, setRowTotalData] = useState([]);

  //Work row data
  const [rowWorkData, setRowWorkData] = useState([]);

  //HR Work row data
  const [rowHrWorkData, setRowHrWorkData] = useState([]);

  //Datail select data
  const [rowDtlData, setRowDtlData] = useState([]);

  const [pageSize, setPageSize] = useState(10);

  const [projectList, setProjectList] = useState([]);
  const [chkdProjectList, setChkdProjectList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [chkdTeamList, setChkdTeamList] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [chkdIssueList, setChkdIssueList] = useState([]);

  

  const [userList, setUserList] = useState([]);
  const [chkdUserList, setChkdUserList] = useState([]);

  const [chkdGroup, setChkdGroup] = useState('전체');
  const [workRange, setWorkRange] = useState('mine');

  const [srchSltReadyList, setSrchSltReadyList] = useState([]);

  //검색 실행 여부 체크 YN
  const [searchChk, setSearchChk] = useState(false);

  //시간기록 등록 date 
  const [pickDate, setpickDate] = useState('');

 //검색 시, 분류구분 셋팅
 const [selectChk, setSelectChk] = useState(''); 

  let selectCtgOptions = [
    { value: "분석(Study)", label: "분석(Study)" },
    { value: "설계(Design)", label: "설계(Design)" },
    { value: "개발(Development)", label: "개발(Development)" },
    { value: "테스트(Test)", label: "테스트(Test)" },
    { value: "배포(Deployment)", label: "배포(Deployment)" },
    { value: "기타(ETC)", label: "기타(ETC)" }
  ];

  const { setIsCallApi, setAlertDialogInfo, setErrAlertInfo, setDtlModalInfo, setModalInfo, setupModalInfo, setWorkModalInfo, userId} = useContext(CommonCtx);

    //이슈 검색 시 GET CONTEXT USERID 
    const oneselectuserid = userId;

  /* Local Variables */
  // 그리드 헤더 리스트
  const gridHeaderList = [
    '프로젝트별 집계',
    '프로젝트/이슈별 집계',
    '프로젝트/이슈/유저별 집계',
    '프로젝트/유저/이슈별 집계',
    '프로젝트/이슈/유저/카테고리별 집계',
    '나의 근태 작업량 관리',
    '팀 근태 작업량 관리'
  ];

  // 그리드 헤더 ID Map
  const gridHeaderIdMap = {
    Project: 'pname',
    Issue: 'jsummary',
    User: 'displayname',
    Category: 'category',
    LogTime: 'logtime',
    Issuekey: 'issuekey',
    Summary: 'summary',
    From: 'startdate',
    To: 'enddate',
    Description: 'worklogbody',
    Management: 'viewclass',
    Team: 'tname',
    Worklog: 'displayname'
  };

  // 그룹 리스트
  const groupList = [
    '전체',
    'I&C',
    'GDC'
  ];

  // ALL/MY WORK 리스트
  const workRangeList = [
    { id: 'all', nm: '전체' },
    { id: 'mine', nm: '나의 업무' }
  ];

  /* Hooks */
  // 디폴트 훅
  useEffect(() => {
    const [strDay, endDay, maxDay] = getPeriodDate();
    setFromDate(strDay);
    setToDate(endDay);
    setMaxDate(maxDay);
  }, [userId]);

  useEffect(() => {
    // 프로그레스 오픈
    setIsCallApi(true);
    if(srchSltReadyList.length >= 4) {
      // 프로그레스 닫기
      setIsCallApi(false);
    }
  }, [srchSltReadyList.length]);


  const defaultColDef = useMemo(() => ({
    editable: false,
    resizable: true,
    sortable: true,
    suppressMovable: true,
    cellStyle: { textAlign: 'center' },
    cellClassRules: {
      //Total < 근태(SHRS) 비교 후 CSS 적용 로직 
      'cell-rules-rowbottom': (params) => {
        //차이 시간 ROW의 경우 
        if (params.data.issuekey == '차이 시간') {
   
          //고정 row 값 0번째 = Total 값 
          //let totalRowInfo = params.api.getPinnedBottomRow(0);

          //고정 row 값 1번째 = 근태 값 
          //let worktotalRowInfo = params.api.getPinnedBottomRow(1);

          //고정 row 값 2번째 = 시간 차이 값 
          //let hrtotalRowInfo = params.api.getPinnedBottomRow(2);

       
          //시간 차이 값이 0 이상인 경우 false
          if (params.data.issuekey == '차이 시간' && params.data[params.colDef.field] <= 0) return false;

          //true면 css 적용 
          return true;

       //팀 별 근태 조회 시, 차이시간인 경우
        } else if(params.data.viewclass == '차이 시간'){
          if(params.data.viewclass == '차이 시간' && params.data[params.colDef.field] <= 0) return false;
          //true면 css 적용
          return true;
        //차이 시간 ROW 외 다른 ROW인 경우
        }else {
          //고정된 row가 없는 경우 false 
          if (!params.node.isRowPinned()) return false;
          //고정 row index 2 이하인 경우 false
          if (params.api.getPinnedBottomRowCount() < 2) return false;
          //field 값 중에 아래 값들이 포함인 경우 false
          //if (_.includes(['pname', 'jsummary', 'displayname', 'category'], params.colDef.field)) return false;
          //분류구분 - 근태 외 : 고정 row의 값이 Total 인 경우 false 
          if (params.data.pname == 'Total 작업 시간') return false;
          //분류구분 - 근태 : 고정 row의 값이 Total 인 경우 false 
          if (params.data.issuekey == 'Total 작업 시간') return false;
          //분류구분 - 근태 : 고정 row의 값이 근태 시간 인 경우 false
          if (params.data.issuekey == 'SHRS 근태 시간') return false;
          //분류구분 - 팀별 근태 근태 시간 인 경우 false
          if(params.data.viewclass == '근태 시간') return false;
          //분류구분 - 팀별 근태 작업 시간 인 경우 false
          if(params.data.viewclass == '작업 시간') return false;

          //고정 row 값 0번째 = Total 값 
          //let totalRowInfo = params.api.getPinnedBottomRow(0);

          //Total , 근태 row 값 비교하여 total 값이 더 큰 경우 false 
          //if (totalRowInfo.data[params.colDef.field] >= params.data[params.colDef.field]) return false;
          //true면 css 적용 
          return true;
        }

      },

      'cell-rules-rowbottom-bottom': (params) => {
        //차이 시간 ROW의 경우 
        if (params.data.issuekey == '차이 시간') {
   
          //고정 row 값 0번째 = Total 값 
          //let totalRowInfo = params.api.getPinnedBottomRow(0);

          //고정 row 값 1번째 = 근태 값 
          //let worktotalRowInfo = params.api.getPinnedBottomRow(1);

          //고정 row 값 2번째 = 시간 차이 값 
          //let hrtotalRowInfo = params.api.getPinnedBottomRow(2);

       
          //시간 차이 값이 0 이상인 경우 false
          if (params.data.issuekey == '차이 시간' && params.data[params.colDef.field] >= 0) return false;

          //true면 css 적용 
          return true;

       //팀 별 근태 조회 시, 차이시간인 경우
        } else if(params.data.viewclass == '차이 시간'){
          if(params.data.viewclass == '차이 시간' && params.data[params.colDef.field] >= 0) return false;
          //true면 css 적용
          return true;
        //차이 시간 ROW 외 다른 ROW인 경우
        }else {
          //고정된 row가 없는 경우 false 
          if (!params.node.isRowPinned()) return false;
          //고정 row index 2 이하인 경우 false
          if (params.api.getPinnedBottomRowCount() < 2) return false;
          //field 값 중에 아래 값들이 포함인 경우 false
          //if (_.includes(['pname', 'jsummary', 'displayname', 'category'], params.colDef.field)) return false;
          //분류구분 - 근태 외 : 고정 row의 값이 Total 인 경우 false 
          if (params.data.pname == 'Total 작업 시간') return false;
          //분류구분 - 근태 : 고정 row의 값이 Total 인 경우 false 
          if (params.data.issuekey == 'Total 작업 시간') return false;
          //분류구분 - 근태 : 고정 row의 값이 근태 시간 인 경우 false
          if (params.data.issuekey == 'SHRS 근태 시간') return false;
          //분류구분 - 팀별 근태 근태 시간 인 경우 false
          if(params.data.viewclass == '근태 시간') return false;
          //분류구분 - 팀별 근태 작업 시간 인 경우 false
          if(params.data.viewclass == '작업 시간') return false;

          //고정 row 값 0번째 = Total 값 
          //let totalRowInfo = params.api.getPinnedBottomRow(0);

          //Total , 근태 row 값 비교하여 total 값이 더 큰 경우 false 
          //if (totalRowInfo.data[params.colDef.field] >= params.data[params.colDef.field]) return false;
          //true면 css 적용 
          return true;
        }

      },
    }
 
    
    }), []);


  
  /* 로컬 함수 */
  // 리스트 셀렉트 변경 시 호출
  const changeGridHeaderInfo = useCallback((event) => {

    //setErrAlertInfo({ open: true, msg: '등록되었습니다.'});
    /*
      // 타임아웃 인스턴스 존재 체크
      if (!!timeoutInstcs.errAlert) return false;
      // errAlert 오픈
     
      // errAlert에 대한 인스턴스 세팅
      timeoutInstcs.errAlert = setTimeout(() => {
        // errAlert 닫기
        setErrAlertInfo({ open: false, msg: null });
        // clear 인스턴스
        clearTimeoutInstcs('errAlert');
      }, 1200);
      return false;

*/


    //프로젝트 초기화 처리
    setinitList(false);

    //전체, 나의업무 선택 버튼 기본 flex 처리
    document.getElementById('select-work-mut').style.display = 'flex';
    document.getElementById('select-detail-btn').style.display = 'flex';

    //팀별 근태 분류구분 관련 팀 선택 기본 none 처리
    document.getElementById('select-team-mut').style.display = 'none';

    //업무구분 초기화 처리
    setWorkRange('mine');

    //전 상태가 팀별 근태 조회면
    if (gridHeaderInfo == "팀 근태 작업량 관리") {
      //Page size default 처리
      setPageSize(10);

      //현재 페이지에 적용
      //gridRef.current.api.paginationSetPageSize(Number(10));
    }



    //분류구분 Issuekey 체크 시  
    //전체, 나의업무 선택 버튼 none 처리
    if (event.target.value == '나의 근태 작업량 관리') {
      document.getElementById('select-work-mut').style.display = 'none';
      document.getElementById('select-detail-btn').style.display = 'none';

      //상세 open 시, 상태 disable 처리
      if (isDtlOpen == true) {
        setIsDtlOpen(false)
      }


      //그룹 초기화 처리 
      setChkdGroup('전체');

      //프로젝트, 유저, 이슈, 팀 초기화 처리
      setinitList(true);

      setGridHeaderInfo(event.target.value);

    }

    //분류구분 Team Worklog Management 체크 시  
    if (event.target.value == '팀 근태 작업량 관리') {
     //전체, 나의업무 선택 버튼 none 처리
      document.getElementById('select-work-mut').style.display = 'none';
      document.getElementById('select-detail-btn').style.display = 'none';

      //팀별 근태 분류구분 관련 팀 선택 flex 처리
      document.getElementById('select-team-mut').style.display = 'flex';

      //상세 open 시, 상태 disable 처리
      if (isDtlOpen == true) {
        setIsDtlOpen(false)
      }

      //업무구분 초기화 처리
      setWorkRange('all');

      //그룹 초기화 처리 
      setChkdGroup('전체');

      //프로젝트, 유저, 이슈, 팀 초기화 처리
      setinitList(true);

      setGridHeaderInfo(event.target.value);

    }

    setGridHeaderInfo(event.target.value);

  }, [isDtlOpen,chkdProjectList,chkdGroup,workRange,chkdTeamList,chkdIssueList,chkdUserList,srchSltReadyList,gridHeaderInfo,initList]);


  // 그룹 변경 시 호출
  const changeGroup = useCallback((event) => {
    setChkdGroup(event.target.value);
  }, []);

  // from 날짜 변경 시 호출
  const changeFromDate = useCallback((newValue) => {
    if (newValue == null) {
      return;
    }

    setFromDate(newValue);

    // 새로운 fromDate로 인한 maxDate 가져오기
    let newMaxDate = getMaxDate(newValue);
    // 종료일 maxDate 세팅
    setMaxDate(newMaxDate);

    // fromDate가 이후 날짜 설정 시 toDate 보다 높을때
    if (newValue > toDate) {
      setToDate(newValue);
    }
    // fromDate가 이전 날짜 설정 시 maxDate 보다 toDate가 높을때
    else if (newMaxDate < toDate) {
      setToDate(newMaxDate);
    }
  }, [toDate]);

  // 상세 오픈 여부 토글 함수
  const toggleDtlOpen = useCallback(() => {
    setIsDtlOpen((prev) => !prev);
  }, []);

  // 그리드 컬럼 정보 변경
  const changeColumnDefs = useCallback(() => {
    // 빈 값 체크
    if (fromDate == null || fromDate == '') return false;
    if (toDate == null || toDate == '') return false;
    
    /* 분류구분 선택 default 값 설정으로 인한 주석 처리 
    if (gridHeaderInfo == null || gridHeaderInfo == '') {
      // 타임아웃 인스턴스 존재 체크
      if (!!timeoutInstcs.errAlert) return false;
      // errAlert 오픈
      setErrAlertInfo({ open: true, msg: '분류구분을 선택해주세요.' });
      // errAlert에 대한 인스턴스 세팅
      timeoutInstcs.errAlert = setTimeout(() => {
        // errAlert 닫기
        setErrAlertInfo({ open: false, msg: null });
        // clear 인스턴스
        clearTimeoutInstcs('errAlert');
      }, 1200);
      return false;
    }
    */
    // pivot 일자 세팅
    let pivotDate = dayjs(fromDate);

    // from - to 일자에 의한 헤더 세팅
    let daysHeaderList = [];
    while (pivotDate.isSameOrBefore(toDate, 'day')) {
      let tYear = pivotDate.format('YY');
      let tMonth = pivotDate.format('M');
      let tDay = pivotDate.format('D');
      let tWeek = pivotDate.locale('ko').format('ddd');

      //분류구분 Team Worklog Management 정렬 off 처리로 인해 분기 처리
      if (gridHeaderInfo == '팀 근태 작업량 관리') {
        daysHeaderList.push({
          field: `${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`,
          headerName: `${tMonth}월 ${tDay}일(${tWeek})`,
          width: 120,
          //헤더 정렬 off 
          sortable: false,
          //기존 정렬 off
          sort : null,
          cellRendererSelector: (params) => {
            if (params.node.rowPinned) {
              return {
                component: CustomPinnedRowRenderer,
                params: {
                  // style: { color: 'blue' },
                },
              };
            } else {
              // rows that are not pinned don't use any cell renderer
              return undefined;
            }
          }
        });
      } else {
        daysHeaderList.push({
          field: `${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`,
          headerName: `${tMonth}월 ${tDay}일(${tWeek})`,
          width: 120,
          cellRendererSelector: (params) => {
            if (params.node.rowPinned) {
              return {
                component: CustomPinnedRowRenderer,
                params: {
                  // style: { color: 'blue' },
                },
              };
            } else {
              // rows that are not pinned don't use any cell renderer
              return undefined;
            }
          }
        });
      }

      pivotDate = pivotDate.add(1, 'day');
    }

    // 리스트에 의한 그리드 헤더 세팅 null
    let gridHeaderList  = null;

    let gridHeaderInfoValue = '';

    switch(gridHeaderInfo){
      case '프로젝트별 집계':
        gridHeaderInfoValue = 'Project';
        break;
      case '프로젝트/이슈별 집계':
        gridHeaderInfoValue = 'Project Issue';
        break;
      case '프로젝트/이슈/유저별 집계':
        gridHeaderInfoValue = 'Project Issue User';
        break;
      case '프로젝트/유저/이슈별 집계':
        gridHeaderInfoValue = 'Project User Issue';
        break;
      case '프로젝트/이슈/유저/카테고리별 집계':
        gridHeaderInfoValue = 'Project Issue User Category';
        break;
      case '나의 근태 작업량 관리':
        gridHeaderInfoValue = 'Issuekey Summary From To Description';
        break;
      case '팀 근태 작업량 관리':
        gridHeaderInfoValue = 'Team Worklog Management';
        break;
    }


  //분류구분 Team Worklog Management Cell class 적용 변경으로 인해 분기 처리
  if(gridHeaderInfo == '팀 근태 작업량 관리'){
    // 리스트에 의한 그리드 헤더 세팅
    gridHeaderList = _.map(gridHeaderInfoValue.split(' '), hItem => {
      return {
        field: gridHeaderIdMap[hItem] ?? hItem,
        headerName: 
         (hItem =="Issuekey") ? '이슈' : (hItem =="Summary") ? '제목' : (hItem =="From") ? '작업 시작 시간' : (hItem =="To") ? '작업 종료 시간' : (hItem =="Description") ? '작업 내용' : hItem , 
        tooltipField: gridHeaderIdMap[hItem] ?? hItem,
        tooltipComponentParams: {
          color: '#ececec'
        },
        headerClass: 'header-align-center',
        //헤더 정렬 off 
        sortable : false,
        //기존 정렬 off
        sort : null,
        //작업 시작 시간, 작업 종료 시간, Category cell 중앙정렬 / 그외 왼쪽 정렬
        cellClass: (hItem =="Team") || (hItem =="Worklog") || (hItem =="Category")  ? 'show-cell': 'cell-align-left' ,
        //row 병합 처리
        rowSpan: (params) => {

          if (gridHeaderIdMap[hItem] == "tname" && params.data.tname == "Total") {
            return 3;
          }
           //pname == Project row == pnameshow 체크해서 병합 처리
          else if (gridHeaderIdMap[hItem] == "tname" && params.data.tname != null && params.data.tname != "Total") {
            return params.node.data.trow;
          }
          //displayname == User row == displaynameshow 체크해서 병합 처리
          else if (gridHeaderIdMap[hItem] == "displayname"  && params.data.displayname != null ) {
            return params.node.data.urow;
          } 
          //그 외 기본 값 처리       
          else {
            return 1;
          }
        },
 
        //cell 병합 처리
        colSpan: (params) => {
          //Total 리턴 받아서 해당 Cell 2로 병합 처리
          if (gridHeaderIdMap[hItem] == "tname" && params.data.tname == "Total") {
            return 2;
          } else {
            return 1;
          }
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: CustomPinnedRowRenderer,
              params: {         
                // style: { color: 'blue' },
              },
            };
          } else {
            // rows that are not pinned don't use any cell renderer
            return undefined;
          }
        }
      };
    });

  //분류 구분이 Team Worklog Management가 아니면
  } else {
    // 리스트에 의한 그리드 헤더 세팅
    gridHeaderList = _.map(gridHeaderInfoValue.split(' '), hItem => {
      return {
        field: gridHeaderIdMap[hItem] ?? hItem,
        headerName:
          (hItem == "Issuekey") ? '이슈' : (hItem == "Summary") ? '제목' : (hItem == "From") ? '작업 시작 시간' : (hItem == "To") ? '작업 종료 시간' : (hItem == "Description") ? '작업 내용' : hItem,
        tooltipField: gridHeaderIdMap[hItem] ?? hItem,
        tooltipComponentParams: {
          color: '#ececec'
        },
        headerClass: 'header-align-center',
        //작업 시작 시간, 작업 종료 시간, Category cell 중앙정렬 / 그외 왼쪽 정렬
        cellClass: (hItem == "From") || (hItem == "To") || (hItem == "Category") ? 'cell-align-center' : 'cell-align-left',
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: CustomPinnedRowRenderer,
              params: {         
                // style: { color: 'blue' },
              },
            };
          } else {
            // rows that are not pinned don't use any cell renderer
            return undefined;
          }
        }
      };
    });
  }

    // 빈 배열 체크
    if (gridHeaderInfoValue == null || gridHeaderInfoValue.length == 0) return;

    //분류구분 선택 - 근태가 아니고 팀별 근태가 아닌 경우 , 로그타임 추가 
    if (gridHeaderInfoValue != 'Issuekey Summary From To Description' && gridHeaderInfoValue != 'Team Worklog Management') {
      // 로그타임 추가
      gridHeaderList.push({
        field: gridHeaderIdMap.LogTime,
        headerName: 'LogTime',
        headerClass: 'header-align-center',
        cellClass: 'cell-align-center'
      });
    }

    //분류구분 선택 - 근태인 경우, 로그타임 추가
    if(gridHeaderInfoValue === 'Team Worklog Management'){
        // 로그타임 추가
        gridHeaderList.push({
        field: gridHeaderIdMap.LogTime,
        headerName: 'LogTime',
        headerClass: 'header-align-center',
        cellClass: 'cell-align-center',
        //헤더 정렬 off 
        sortable: false,
        //기존 정렬 off
        sort : null,
      });
    }

    // 그리드 해더 컬럼정보 세팅
    setColumnDefs(gridHeaderList.concat(daysHeaderList));

    return true;
  }, [fromDate, toDate, gridHeaderInfo]);

  // 그리드 행 리스트 조회
  const getWlrList = useCallback(async(flg) => {

  //검색 실행 여부 true 처리 
  setSearchChk(true);

    // 그리드 컬럼 정보 변경 --> 그리드 컬럼 정보 변경이 있으므로 제거 
    if(flg == 0 ) 
    if (!changeColumnDefs()) return;

    // 프로그레스 오픈
    setIsCallApi(true);

    //현재 페이지 사이즈에 맞게 처리 (팀 근태 작업량 관리 추가로 인한 처리)
    gridRef.current.api.paginationSetPageSize(Number(pageSize));


    //페이징 사이즈 박스 기본 flex 처리
    document.getElementById('grid-page-size-select').style.display = 'flex';

    //분류에 따른 division 값 체크
    let divisiondata = "";

    if (gridHeaderInfo === '프로젝트별 집계') {
      divisiondata = 'p'
    } else if (gridHeaderInfo === '프로젝트/이슈별 집계') {
      divisiondata = 'pi'
    } else if (gridHeaderInfo === '프로젝트/이슈/유저별 집계') {
      divisiondata = 'piu'
    } else if (gridHeaderInfo === '프로젝트/유저/이슈별 집계') {
      divisiondata = 'pui'
    } else if (gridHeaderInfo === '프로젝트/이슈/유저/카테고리별 집계'){
      divisiondata = 'piuc'
    } else if (gridHeaderInfo === '팀 근태 작업량 관리') {
      //페이징 사이즈 박스 none 처리 
      document.getElementById('grid-page-size-select').style.display = 'none';
      divisiondata = 'pu'
    }else{
      //Issuekey Summary From To Description
      divisiondata = 'hr'
    }

    //검색 시 선택된 분류구분 
    setSelectChk(divisiondata);
    
    //이슈 리스트 
    let tempIssue = "";

    chkdIssueList.map(res => {

      if (tempIssue == "") {
        tempIssue = res.id;
      } else {
        tempIssue = tempIssue + "," + res.id;
      }
    });

    //프로젝트 리스트 
    let tempProject = "";

    chkdProjectList.map(res => {

      if (tempProject == "") {
        tempProject = res.id;
      } else {
        tempProject = tempProject + "," + res.id;
      }
    });


    //유저 리스트
    let tempUserchk ="";

    //전체,나의 업무 중 전체 선택
    if (workRange == "all") {

      //유저 선택 값이 있을 때
      if (chkdUserList.length > 0) {
        
        chkdUserList.map(res => {

          if (tempUserchk == "") {
            tempUserchk = res.id;
          } else {
            tempUserchk = tempUserchk + "," + res.id;
          }
        })
        //유저 선택 값이 없을 때
      } else {
        
        tempUserchk = "";
      }
      //나의 업무 선택
    } else {
      tempUserchk = userId;
    }


    //프로젝트 리스트 
    let tempTeam = "";

    chkdTeamList.map(res => {

      if (tempTeam == "") {
        tempTeam = res.tid;
      } else {
        tempTeam = tempTeam + "," + res.tid;
      }
    });

    let tempGroup = "";

    let tempINC = 'INC';

    if (chkdGroup.length > 0) {
      if (chkdGroup == 'I&C') {
        tempGroup = tempINC;
      } else if (chkdGroup == '전체') {
        tempGroup = '';
      } else {
        tempGroup = chkdGroup;
      }
    }

    let tempUser ="";

    if (tempUserchk) {
      tempUser = tempUserchk;
    }

    //검색 기준으로 엑셀 다운로드 데이터 셋팅
    //엑셀 다운로드 From, To 데이터 셋팅
    setExcelFromDate(fromDate.format("YYYY-MM-DD"));
    setExcelToDate(toDate.format("YYYY-MM-DD"));

    //엑셀 다운로드 Division 데이터 셋팅
    setExcelDivision(divisiondata)

    //엑셀 다운로드 Project 데이터 셋팅
    setExcelProject(tempProject)

    //엑셀 다운로드 Group
    setExcelGroup(tempGroup)

    //엑셀 다운로드 Issue
    setExcelIssue(tempIssue)

    //엑셀 다운로드 User
    setExcelUser(tempUser)

    //엑셀 다운로드 Team
    setExcelTeam(tempTeam.toString())

    //엑셀 다운로드 WorkRange
    setExcelWorkRange(workRange)
    

    let datas  = null;
    // 그리드 데이터 조회
    let tempRst = await post('select/getSelectList', {
      "division": divisiondata,
      "startDate": fromDate.format("YYYY-MM-DD"),
      "endDate": toDate.format("YYYY-MM-DD"),
      "pId": tempProject,
      "group": tempGroup,
      "jId": tempIssue,
      "userName": userId,
      "uId" : tempUser,
      "tId" : tempTeam.toString(),
      "workChk" : workRange
    })
      .then(res => {
        datas = res ?? [];
        if (_.isArray(res?.[0])) {
          datas = res[0];
        }

        //전체
        if (workRange == 'all') {
          
          //분류구분 근태 조회일 경우
          if (gridHeaderInfo === '팀 근태 작업량 관리') {

            //Row (하단 bottom 3개 제거)
            setRowData(datas.slice(0, datas.length - 3));

            //근태 작업 차이 > 타 분류구분과 순서가 다름
            //work Row (근태)
            setRowWorkData(datas[datas.length - 3]);

            //total Row (작업)
            setRowTotalData(datas[datas.length - 2]);

            //hrwork Row (차이)
            setRowHrWorkData(datas[datas.length - 1]);

            //Page size == row 
            setPageSize(datas.length - 3);

            //현재 페이지에 적용
            gridRef.current.api.paginationSetPageSize(Number(datas.length - 3));

          } else {
            //total Row 
            setRowTotalData(datas[datas.length - 1]);
            //Row
            setRowData(datas.slice(0, datas.length - 1));
            //hrwork Row
            setRowHrWorkData([]);
          }
          //나의 업무
        } else {



          //분류구분 근태 조회일 경우
          if (gridHeaderInfo === '나의 근태 작업량 관리') {
            
            //total Row 
            setRowTotalData(datas[datas.length - 3]);
            //Row
            setRowData(datas.slice(0, datas.length - 3));
            //work Row
            setRowWorkData(datas[datas.length - 2]);
            //hrwork Row
            setRowHrWorkData(datas[datas.length - 1]);
          } else {
            //total Row 
            setRowTotalData(datas[datas.length - 2]);
            //Row
            setRowData(datas.slice(0, datas.length - 2));
            //work Row
            setRowWorkData(datas[datas.length - 1]);
          }
        }

  
        // 프로그래스 타임아웃 인스턴스 clear
        clearTimeoutInstcs('progress');
        // 프로그래스 유지(1초간) 후 close 및 인스턴스 clear
        timeoutInstcs.progress = setTimeout(() => {
          setIsCallApi(false);
          clearTimeoutInstcs('progress');
        }, 1000);
      });
  }, [fromDate, toDate, gridHeaderInfo, chkdGroup, chkdUserList, chkdTeamList, chkdIssueList, chkdProjectList, rowData, workRange, userId, selectChk ]);

  // 페이지네이션 숫자 포맷터
  const paginationNumberFormatter = useCallback((params) => {
    return '[' + params.value.toLocaleString() + ']';
  }, []);

  // 그리드 페이지 사이즈 변경
  const onPageSizeChanged = useCallback((e) => {
    setPageSize(e.target.value);
    gridRef.current.api.paginationSetPageSize(Number(e.target.value));
  }, []);

  // 업무 리스트 범위 변경
  const changeWorkRange = useCallback((e) => {
    setWorkRange(e.target.value);
  }, []);

  const handleNewDtlModal = useCallback((e, rowDtlData) => {
    // alert dialog test
    setAlertDialogInfo({
      title: '시간 기록을 삭제하시겠습니까?',
      content: null,
      isOpen: true,
      isBackdropClose: false,
      btnInfo: {
        confirm: {
          name: '삭제',
          callback: async() => {
           
            let tempRst = await post('worklog/deleteWorklog', {
              "worklogId": rowDtlData[e.target.value].wid,
              "userName": userId
            });

            if (tempRst[0] === false) {
              alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
            } else {
              //상세보기 팝업창 닫기
              setDtlModalInfo({ isClose: true });
              getWlrList(1);
            }
         
          }
        },
        cancle: {
          name: '취소'
        }
      }
    });
  }, [userId, gridHeaderInfo, fromDate, toDate, workRange, chkdGroup, chkdUserList, chkdTeamList, chkdIssueList, chkdProjectList ]);

    const getRowStyle = useCallback((params) => {
      if (params.node.rowPinned) {
        return {
          'fontWeight': 'bold',
          'backgroundColor': '#E9EAF4',
        };
      }
  }, []);

  // 하단 고정 행 데이터 리턴(Total 및 근태 합에 대한 행 데이터)
  const pinnedBottomRowData = useMemo(() => {
    // pivot 일자 세팅
    let pivotDate = dayjs(fromDate);

    // from - to 일자에 의한 헤더 세팅
    let totalDaysHeaderInfo = {};
    let myWorkDaysHeaderInfo = {};
    let myhrWorkDaysHeaderInfo = {};
    while (pivotDate.isSameOrBefore(toDate, 'day')) {
      let tYear = pivotDate.format('YY');
      let tMonth = pivotDate.format('M');
      let tDay = pivotDate.format('D');

      //console.log(res['230524'])

      //total 행, 컬럼 별 데이터 세팅 일자별 총합 
      totalDaysHeaderInfo[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`] = rowTotalData[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`];

      //total 행, 컬럼 별 데이터 세팅 Total 총합
      totalDaysHeaderInfo[`logtime`] = rowTotalData.logtime;

      // 근태(SHRS) 행, 컬럼 별 데이터 세팅
      myWorkDaysHeaderInfo[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`] = rowWorkData[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`];

       //total 행, 컬럼 별 데이터 세팅 Total 총합
       myWorkDaysHeaderInfo[`logtime`] = rowWorkData.logtime;

      // HR 근태(SHRS) 행, 컬럼 별 데이터 세팅
      myhrWorkDaysHeaderInfo[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`] = rowHrWorkData[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`];

      //차이 시간 행, 컬럼 별 데이터 세팅 Total 총합
      myhrWorkDaysHeaderInfo[`logtime`] = rowHrWorkData.logtime;
    
      /*=== TO-DO 그리드 데이터 조회 시 하단 고정 행 데이터 세팅 로직 필요 ===*/
      // total 행, 컬럼 별 데이터 세팅
      //totalDaysHeaderInfo[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`] = null;

     
      // 근태(SHRS) 행, 컬럼 별 데이터 세팅
      //myWorkDaysHeaderInfo[`${tYear}${tMonth.padStart(2, '0')}${tDay.padStart(2, '0')}`] = 1;

      pivotDate = pivotDate.add(1, 'day');
    }

    let result = [];

    //나의 업무인 경우
    if (workRange == 'mine') {
      //분류구분 근태 조회일 경우   
      //분류구분이 근태인 경우
      if (gridHeaderInfo === '나의 근태 작업량 관리') {
        
        result.push({
          [gridHeaderIdMap.Issuekey]: 'Total 작업 시간',
          ...totalDaysHeaderInfo
        });

        result.push({
          [gridHeaderIdMap.Issuekey]: 'SHRS 근태 시간',
          ...myWorkDaysHeaderInfo
        });

        result.push({
          [gridHeaderIdMap.Issuekey]: '차이 시간',
          ...myhrWorkDaysHeaderInfo
        });
      } else {
        result.push({
          [gridHeaderIdMap.Project]: 'Total 작업 시간',
          ...totalDaysHeaderInfo
        });

        result.push({
          [gridHeaderIdMap.Project]: 'SHRS 근태 시간',
          ...myWorkDaysHeaderInfo
        });

      }

      //전체 업무인 경우
    } else {

      if (gridHeaderInfo === '팀 근태 작업량 관리') {
        result.push({
          [gridHeaderIdMap.Team]: 'Total', [gridHeaderIdMap.Management]: '근태 시간',
          ...myWorkDaysHeaderInfo
        });

        result.push({
           [gridHeaderIdMap.Management]: '작업 시간',
           ...totalDaysHeaderInfo
        });

        result.push({
          [gridHeaderIdMap.Management]: '차이 시간',
          ...myhrWorkDaysHeaderInfo
        });

      } else {
        result.push({
          [gridHeaderIdMap.Project]: 'Total 작업 시간',
          ...totalDaysHeaderInfo
        });
      }
    }

    return result;
    // }, [fromDate, toDate, workRange])
  }, [rowTotalData, rowWorkData, rowHrWorkData])

        
  // 디테일 모달 오픈
  const openDtlModal = useCallback(async (params) => {

    //분류구분이 팀별 근태면 return
    if(selectChk === 'pu') return;
    //console.log((_.includes(['issuekey', 'summary', 'startdate', 'enddate', 'worklogbody'], params.colDef.field)));
    //클릭한 CELL이 HR 근태이며 0보다 작은 경우는 근태 등록 화면 호출

    //분류 구분에 따라 field 체크 후 하위 bottom 팝업 노출 (분류구분+차이시간 : issuekey or 분류구분+나의업무 : pname)
    if ( (params.data.issuekey  == 'SHRS 근태 시간' &&  ((_.includes(['issuekey', 'summary', 'startdate', 'enddate', 'worklogbody'], params.colDef.field)) == false)) 
   || (params.data.pname  == 'SHRS 근태 시간' &&  ((_.includes(['issuekey', 'pname', 'jsummary', 'displayname', 'category','logtime'], params.colDef.field)) == false)) ){
      //근태 화면 호출

      //클릭한 날짜 포맷 
      const date_field = "20"+params.colDef.field;
      const datechk = date_field.substr(0, 4) + '-' + date_field.substr(4, 2) + '-' + date_field.substr(6, 2);

      //SHRS 근태 시간 클릭 체크
      const statechk = 'bottom';

      OpenWorkModal(statechk, datechk,'','', "",'','','','');
    }

    
    //bottom 고정 cell이면 return 
    if (params.node.rowPinned) return;
    
  //프로그래스 오픈
  setIsCallApi(true);

  //분류에 따른 division 값 체크
  let divisiondata = "";


		  //이슈 리스트 
      let tempIssue = "";

      chkdIssueList.map(res => {
  
        if (tempIssue == "") {
          tempIssue = res.id;
        } else {
          tempIssue = tempIssue + "," + res.id;
        }
      });

      
  //유저 리스트
  let tempUserchk ="";

  //전체,나의 업무 중 전체 선택
  if (workRange == "all") {

    //유저 선택 값이 있을 때
    if (chkdUserList.length > 0) {
      
      chkdUserList.map(res => {

        if (tempUserchk == "") {
          tempUserchk = res.id;
        } else {
          tempUserchk = tempUserchk + "," + res.id;
        }
      })
      //유저 선택 값이 없을 때
    } else {
      
      tempUserchk = "";
    }
    //나의 업무 선택
  } else {
    tempUserchk = userId;
  }


let tempUser ="";

  if (tempUserchk) {
    tempUser = tempUserchk;
  }


  let tempRst;


  if (selectChk ===  'p') {
    // 그리드 데이터 조회
    tempRst = await post('select/getDetailSelectList', {
      "division": selectChk,
      "date": params.colDef.field,
      "pId": params.data.pid,
      "jId": tempIssue,
      "cId": tempUser,
      "category": params.data.category,
      "workChk" : workRange,
      "wId" : ''
    });

  } else if (selectChk === 'pi') {
      // 그리드 데이터 조회
      tempRst = await post('select/getDetailSelectList', {
        "division": selectChk,
        "date": params.colDef.field,
        "pId": params.data.pid,
        "jId": params.data.jid,
        "cId": tempUser,
        "category": params.data.category,
        "workChk" : workRange,
        "wId" : ''
        
      });
  } else if (selectChk === 'piu') {
     // 그리드 데이터 조회
     tempRst = await post('select/getDetailSelectList', {
      "division": selectChk,
      "date": params.colDef.field,
      "pId": params.data.pid,
      "jId": params.data.jid,
      "cId": params.data.cid,
      "category": params.data.category,
      "workChk" : workRange,
      "wId" : ''
    });
  } else if (selectChk === 'piuc') {
     // 그리드 데이터 조회
      tempRst = await post('select/getDetailSelectList', {
      "division": selectChk,
      "date": params.colDef.field,
      "pId": params.data.pid,
      "jId": params.data.jid,
      "cId": params.data.cid,
      "category": params.data.category,
      "workChk" : workRange,
      "wId" : ''
    });
  } else if (selectChk === 'pui') {
     // 그리드 데이터 조회
     tempRst = await post('select/getDetailSelectList', {
      "division": selectChk,
      "date": params.colDef.field,
      "pId": params.data.pid,
      "jId": params.data.jid,
      "cId": params.data.cid,
      "category": params.data.category,
      "workChk" : workRange,
      "wId" : ''
    });
  } else{
    // 그리드 데이터 조회
    tempRst = await post('select/getDetailSelectList', {
     "division": selectChk,
     "date": params.colDef.field,
     "pId": params.data.projectId,
     "jId": '',
     "cId": '',
     "category": '',
     "workChk" : workRange,
     "wId" : params.data.worklogId
   });
  }


    let datas_select = tempRst ?? [];
    if (_.isArray(tempRst?.[0])) {
      datas_select = tempRst[0];
    }


    let tmpSeletList = _.map(datas_select, x => {
      return x;
    });

    setRowDtlData(tmpSeletList);

    if (tmpSeletList.length > 0) {
      //프로그래스 닫기
      setIsCallApi(false);
      // 디테일 모달 오픈
      setDtlModalInfo({
        isOpen: true,
        isClose: false,
        childNode: ( // 모달 내부 컴포넌트
          <div>
            {/* <h2>시간기록 상세보기</h2> */}
            <div className={styles.modalContent}>
              <table className="issue-dtl-table">
                <thead>
                  {tmpSeletList.length > 0 &&
                    <tr>
                      <th> 이슈 </th>
                      <th> 날짜 </th>
                      <th> 시간 </th>
                      <th  className="issue-dtl-tr-description"> 설명 </th>
                      <th > 작성자 </th>
                      <th> 작업시간 </th>
                      <th> 수정/삭제 </th>

                    </tr>
                  }
                </thead>
                <tbody>
                  {
                    //tmpSeletList.lenth > 0 && 
                    tmpSeletList.map(res => (
                      <tr key={res.rownum}>
                        <td title={res.issuekey} className="issue-dtl-td-link" onClick={() => window.open("https://jira.sinc.co.kr/browse/"+res.issuekey)}> {res.issuekey.length < 5 ? res.issuekey : res.issuekey.slice(0, 5) + '...'}</td>
                        <td> {res.startdate}</td>
                        <td> {res.starttime}</td>
                        <td className="issue-dtl-td-description" title={res.worklogbody}> { (res.worklogbody == null || res.worklogbody.length) < 24 ? res.worklogbody : res.worklogbody.slice(0, 24) + '...'}</td>
                        <td title={res.displayname}>  {res.displayname.length < 3
                          ? res.displayname
                          : res.displayname.slice(0, 3) + '...'}
                        </td>
                        <td> {res.timeworked}</td>
                        {
                          res.username == userId
                            ?
                            <td className="issue-dtl-td-btn">
                              <button className="update" value={res.rownum} onClick={(e) => openUpdateModal(e, tmpSeletList)}>수정</button>
                            <button className="delete" value={res.rownum} onClick={(e) => handleNewDtlModal(e, tmpSeletList)}>삭제</button>
                          </td>
                          : 
                          <td className="issue-dtl-td-btn">
                        </td>
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        ),
        isBackdropClose: true,
        sx: {} // 모달 style
      });
    } else {
      //프로그래스 닫기
      setIsCallApi(false);
      return;
    }

 
  }, [workRange, userId, gridHeaderInfo, fromDate, toDate, chkdGroup, chkdUserList, chkdTeamList, chkdIssueList, chkdProjectList, selectChk ]);
  // 시간기록 모달 오픈
  const openModal = () => {
    // from/to time list 세팅
    const [fromtimeList, totimeList] = getFromToTimeList();

    // Create Modal View 컴포넌트
    const CreateModalView = memo(({
      setAddInfo = (props) => {},
      chkInfo = null
    }) => {
      
      //현재 일자 
      let dateNow = new Date();

      const [newdate, setNewDate] = useState(dateNow);

      const [fromTime, setFromTime] = useState(fromtimeList[0].value);
      const [toTime, setToTime] = useState(totimeList[0].value);

      const [searchFromTime, setSearchFromTime] = useState("");
      const [searchToTime, setSearchToTime] = useState("");

      const [ctgValue, setCtgValue] = useState('');

      const [comTime, setComTime] = useState("");

      const [issueValue, setIssueValue] = useState("");

      const [cworklogbodyValue, setcWorklogbodyValue] = useState("");

      const [chkdIssue, setChkdIssue] = useState([]);

      const [totaltimechk, setTotaltimechk] = useState("");

      //업무구분 체크 YN
      const [divisionwork, setDivisionWork] = useState(true);

      //SHRS 근태 시간 (작업 기록)
      const [shrsWorkTimeList, setShrsWorkTimeList] = useState([]);

      //SHRS 근태 시간 (작업 기록)
      const [trWorkTimeList, settrWorkTimeList] = useState([]);

      //SHRS 근태, 작업량 시간 체크 
      const [shrstrTimeList, setShrsTrTimeList] = useState([]);

      // 등록된 시간 선택 가능 시간 클릭 이벤트
      const WorkTimeClick = useCallback((from, to) => {

        //from 시간 셋팅
        setFromTime(from);

        //to 시간 셋팅
        setToTime(to);

      }, []);



      //작업량 근태 체크 및 api 호출 
      const chkWorkTime = async () => {


        //작업량 근태 from,to 리스트 api 호출 
        let worktimechk = await post('etc/getFromTo', {
          "userName": String(userId),
          "date": dayjs(newdate).format("YYYY-MM-DD")
        });

        //작업, 근태 (0h)
        setShrsTrTimeList(worktimechk)

        //근태 시간 셋팅
        setShrsWorkTimeList(worktimechk)

        //작업 근태 시간 
        let workShrs = worktimechk.shrs;

        //작업 기록 시간
        let workTr = worktimechk.tr;

        //test langth 체크 
        for (let i = 0; i < workShrs.length; i++) {
          workShrs[i] = workShrs[i][0].substr(0, 2) + ":" + workShrs[i][0].substr(2, 4) + " ~ " + workShrs[i][1].substr(0, 2) + ":" + workShrs[i][1].substr(2, 4) + "  (" + workShrs[i][2] + ")";
        }

        //근태시간 셋팅
        setShrsWorkTimeList(workShrs)

        //작업시간 셋팅
        settrWorkTimeList(workTr)


      }


      //rendering 
      const rendering = () => {
        const result = [];


        for (let i = 0; i < trWorkTimeList.length; i++) {
          result.push(

            trWorkTimeList[i][2] == "선택가능"
              ?
              <MenuItem key={i} style={{ fontSize: '0.84615rem' }} title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3]} onClick={() => {
                WorkTimeClick(
                  (trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4))
                  ,
                  (trWorkTimeList[i][1].substr(0, 2) + ":" + trWorkTimeList[i][1].substr(2, 4))
                )
              }}>
                <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} (<b style={{color:"#28a745", fontWight:"200"}}>{trWorkTimeList[i][2]}</b>)&nbsp;&nbsp;</div>
                <div> {trWorkTimeList[i][3]}  </div>
              </MenuItem>
              :
              <div title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3] + ' ' + trWorkTimeList[i][4] + ' ' + trWorkTimeList[i][5]} >
                <MenuItem key={i} style={{ fontSize: '0.84615rem' }} disabled>
                  <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} ({trWorkTimeList[i][2]})&nbsp;</div>
                  <div > {trWorkTimeList[i][3]} </div>&nbsp;
                  <div>
                    {trWorkTimeList[i][4].length < 10 ? trWorkTimeList[i][4] : trWorkTimeList[i][4].slice(0, 10) + '...'}

                  </div>&nbsp;
                  <div>
                    {trWorkTimeList[i][5].length < 10 ? trWorkTimeList[i][5] : trWorkTimeList[i][5].slice(0, 10) + '...'}
                  </div>
                </MenuItem>
              </div>
          )

        }
        return result;
      };
  //rendering return






      //업무구분 체크 및 api 호출 
      const chkDivisionWork = async () => {

        //이슈 빈 값 체크 
        if (chkdIssue == null || chkdIssue.length < 1) {
        } else {
          //업무 구분 체크 api 호출 
          let YNcheck = await get('etc/divisionofwork', { "issueKey": chkdIssue.issuekey });

          if (YNcheck == "false") {
            //true일 경우 set false 설정
            setDivisionWork(false)
          } else {
            //true일 경우 set true 설정
            setDivisionWork(true)
          }
        }
      }

      useEffect(() => {
        //기본 chkInfo == false 이나 다른 작업량 생성 버튼 클릭 시, true 처리 
        if(chkInfo?.isChk === true) {
          chkWorkTime();
          rendering();
          chkInfo = null;
        }
      }, [chkInfo])

      //업무유형 구분 셋팅하기 위한 useEffect (divisionwork)
      useEffect(() => {
      }, [divisionwork]);


      //날짜 클릭 시 셋팅하기 위한 useEffect (newdate)
      useEffect(() => {
        chkWorkTime();
        rendering();
      }, [newdate]);

      //시간 입력 시 worked를 셋팅하기 위한 useEffect (fromTime)
      useEffect(() => {
        changeInputTimeValue("from", fromTime);
      }, [fromTime]);

            //시간 입력 시 worked를 셋팅하기 위한 useEffect (toTime)
            useEffect(() => {
              changeInputTimeValue("to", toTime);
            }, [toTime]);
      
      //이슈 클릭 시 카테고리 yn 여부 체크
      useEffect(() => {
        chkDivisionWork();
      }, [chkdIssue]);

      // comTime 변경 감지 훅
      useEffect(() => {

        setAddInfo({ totaltimechk, chkdIssue, ctgValue, cworklogbodyValue, newdate, fromTime, toTime, comTime, divisionwork });
      }, [totaltimechk, chkdIssue, ctgValue, cworklogbodyValue, newdate, fromTime, toTime, comTime, divisionwork]);
     

      const changeCtgValue = useCallback((event) => {
        setCtgValue(event)
      }, []);

      const changeWorklogbodyValue = useCallback((event) => {
        setcWorklogbodyValue(event.target.value)
      }, []);

      const changenewDateValue = useCallback((event) => {
        if (event == null) {
          return;
        }
        setNewDate(event)

        //api 호출

    
      }, []);


      const changeIssueValue = useCallback((event) => {
        setIssueValue(event)

      }, []);

      
 

      // from time input 값 변경 시, 호출
      const changeFromTimeInput = (val) => {
        setSearchFromTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // fromTime state 변경
        setFromTime(val);
        // time list 변경
        changeInputTimeValue("from", val);
      };

      // to time input 값 변경 시, 호출
      const changeToTimeInput = (val) => {
        setSearchToTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // toTime state 변경
        setToTime(val);
        // time list 변경
        changeInputTimeValue("to", val);
      };


      const checkToTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-insert-to-helper').style.display='none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-insert-to-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-insert-to-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setToTime(time);
            } else {
              time = hours + ":" + minute;
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-insert-to-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setToTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-insert-to-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else {
              time = replaceTime + ":00";
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setToTime(time);
          } else {
            time = "00:00";
            setToTime(time);
            document.getElementById('list-select-insert-to-helper').style.display='flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }

          // From, To 시간 데이터 가져오기
          const fromTimeValue = fromTime.replace(':', '');
          const toTimeValue = time.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue >= toTimeValue) {
            setFromTime(time);
            setComTime("");
          }
        }
      }


      //To 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkToTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-insert-to-helper').style.display='none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-insert-to-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-insert-to-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setToTime(time);
          } else {
            time = hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-insert-to-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setToTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-insert-to-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else {
            time = replaceTime + ":00";
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setToTime(time);
        } else {
          time = "00:00";
          setToTime(time);
          document.getElementById('list-select-insert-to-helper').style.display='flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(time);
          setComTime("");
        }
      }


      const checkWorkedKeydown = (e) => {

        if (e.key === 'Enter') {
          // 조건에 맞게 형변환
          checkWorkedTimeColon(e.target.value);
        };

      }

      //worked 시간 입력 시 체크 하는 로직 
      const checkWorkedTimeColon = (time) => {

        //값이 없으면 return
        if (time == "") return;

        document.getElementById('list-select-insert-from-helper').style.display='none';
        document.getElementById('list-select-insert-to-helper').style.display='none';

        let worked = time.replace(/h/i, "h");

        
        worked = time.replace(/m/i, "m");
        worked = time.replace(" ", "");

        // From Time 데이터값 가져오기
        const fromValue = fromTime;
        const fromValueArr = fromValue.toString().split(":");
        const fromValue_h = fromValueArr[0];
        const fromValue_m = fromValueArr[1];


        let worked_h = "";
        let worked_m = "";

        // 시간이 실수인 경우
        if (worked.match(/^\d{1,2}\.\d{1,2}$/)) {
          const worked_time = worked.toString().split(".");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1]
          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = worked_m * 6;        // 입력된 분
          } else {    // 입력된 분이 10분 이상인 경우
            worked_m = worked_m * 0.6;           // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}\:\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(":");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}\,\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(",");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}h\d{1,2}m$/)) {       // 시간, 분 모두 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = worked.substring(worked.indexOf("h") + 1, worked.indexOf("m"));

          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = "0" + worked_m;        // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}h$/)) {       // 시간만 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = 0;
        } else if (worked.match(/^\d{1,2}m$/)) {       // 분만 입력한 경우
          worked_h = 0;
          worked_m = worked.substring(0, worked.indexOf("m"));
        } else if (worked.match(/^\d{1,2}$/)) {       // 숫자만 입력한 경우 -> 시간으로 체크
          worked_h = worked;
          worked_m = 0;
        } else {
          alert("잘못된 값입니다.(Invalid value)");
          setComTime("");
          return false;
        }

        let time_click_to = new Date();
        time_click_to.setHours(fromValue_h, fromValue_m); // to 현재 값 date 초기화
        time_click_to.setHours(time_click_to.getHours() + parseInt(worked_h));
        time_click_to.setMinutes(time_click_to.getMinutes() + parseInt(worked_m));
        
        let toValue;

        //to 시간이 24:00 인 경우, Date에서는 00시로 표기되기 때문에 24시로 처리한다.
        if(time_click_to.getHours() == 0 && time_click_to.getMinutes() == 0){
          toValue = '24' + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
        //그 외는 그대로 처리한다.
        }else{
          toValue = ('0' + time_click_to.getHours()).slice(-2) + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
        }

        /* 24시간 이상 여부 체크 로직 */

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = toValue.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          let hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = hours + ":00";
            setToTime(toValue);
          } else {
            toValue = hours + ":" + minute;
            setToTime(toValue);
            compare_worked();
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          let hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = "0" + hours + ":00";
            setToTime(toValue);
          } else {
            toValue = "0" + hours + ":" + minute;
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else {
            toValue = replaceTime + ":00";
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          toValue = "0" + replaceTime + ":00";
          setToTime(toValue);
        } else {
          toValue = "00:00";
          setToTime(toValue);
          alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        let fromTimeValue = fromTime.replace(':', '');
        let toTimeValue = toValue.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(toValue);
          setComTime("");
        }
      }


      const checkFromTimeKeydown = (time) => {
             
        if (time.key === 'Enter') {
          document.getElementById('list-select-insert-from-helper').style.display='none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-insert-from-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-insert-from-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setFromTime(time);
            } else {
              time = hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-insert-from-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setFromTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-insert-from-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else {
              time = replaceTime + ":00";
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setFromTime(time);
          } else {
            time = "00:00"
            setFromTime(time);
            document.getElementById('list-select-insert-from-helper').style.display='flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }
          // From, To 시간 데이터 가져오기
          const fromTimeValue = time.replace(':', '');
          const toTimeValue = toTime.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue > toTimeValue) {
            setToTime(time);
            setComTime("");
          }
        }
      }


      //From 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkFromTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-insert-from-helper').style.display='none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-insert-from-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-insert-from-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setFromTime(time);
          } else {
            time = hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-insert-from-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setFromTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-insert-from-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else {
            time = replaceTime + ":00";
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setFromTime(time);
        } else {
          time = "00:00"
          setFromTime(time);
          document.getElementById('list-select-insert-from-helper').style.display='flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time);
          setComTime("");
        }
      }

      //select box From 시간 체크 
      const onchangeFromTime = (time) => {

        document.getElementById('list-select-insert-from-helper').style.display='none';

        setFromTime(time.value);
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.value.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time.value);
          setComTime("");
        }
      }


      //select box to 시간 체크 
      const onchangeToTime = (time) => {

        document.getElementById('list-select-insert-to-helper').style.display='none';

        setToTime(time.value);

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.value.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setFromTime(time.value);
          setComTime("");
        }
      }

      const changeInputTimeValue = (target, val) => {
        if (target === "from") {
          fromtimeList.length = 0;
        } else {
          totimeList.length = 0;
        }
        const time = val;

        const time_click = new Date();

        const timeArr = time.split(":");

        time_click.setHours(timeArr[0], timeArr[1]);

        for (let j = 0; j < 48; j++) {
          const op = {};
          //시간
          const time_slice = ('0' + time_click.getHours()).slice(-2) + ":" + ('0' + time_click.getMinutes()).slice(-2);
          time_click.setMinutes(time_click.getMinutes() + 30);

          op.value = time_slice;
          op.label = time_slice;

          if (target === "from") {
            fromtimeList.push(op);
            // e.target.defaultValue =fromtimeList[0].value;
          } else {
            totimeList.push(op);
          }
        }
        compare_worked();
      };

      // From, To 데이터 변경에 따른 Worked데이터 입력
      const compare_worked = () => {
       
        //from,to 시간 차이 계산
        let compare_from = fromTime.split(":");
        let compare_to = toTime.split(":");

        const compare_time_from = new Date();
        compare_time_from.setHours(compare_from[0], compare_from[1]);

        const compare_time_to = new Date();
        compare_time_to.setHours(compare_to[0], compare_to[1]);

        var compare_time = compare_time_to.getTime() - compare_time_from.getTime();
        var compare_time_hour = compare_time / 1000 / 60 / 60;
        var compare_time_min = compare_time / 1000 / 60 - (parseInt(compare_time_hour) * 60);


        if (parseInt(compare_time_hour) == 0 && parseInt(compare_time_min) == 0) {
          return;
        }
        else if (parseInt(compare_time_hour) != 0 && parseInt(compare_time_min) != 0) {
          setComTime(parseInt(compare_time_hour) + "h " + parseInt(compare_time_min) + "m");
        }
        else if (parseInt(compare_time_hour) == 0) {
          setComTime(parseInt(compare_time_min) + "m");
        }

        else {
          setComTime(parseInt(compare_time_hour) + "h");
        }
      };


      const customStyles = useMemo(
        () => ({
          control: (provided, state) => ({
            ...provided,
            background: '#F7F7F7',
            height: '2.46153rem',
            fontSize: '0.84615rem',
            boxShadow: state.isFocused ? null : null,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "rgba(25, 118, 210, 0.08)"
              : state.isFocused
                ? 'rgba(0, 0, 0, 0.04)'
                : null,
            color: '#222',
            fontSize: '0.84615rem'
          }),

          valueContainer: (provided, state) => ({
            ...provided,
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
          }),
        }),
        []
      );

      const DropdownIndicator = (props) => {
        const { menuIsOpen } = props.selectProps;
        if (menuIsOpen) {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        } else {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        }
      };

      return (
        <div className={styles.modalContent}>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>이슈</div>
            {/* 이슈 셀렉트 */}
            <SearchOneSelect
              id="issueOneSelect"
              label="이슈"
              inputLabel="이슈 선택"
              isEssential={false}
              mappingInfo={{ key: 'id', value: 'summary', preValue: 'issuekey', fId : 'fid' }}
              apiName="filter/getIssueList"
              isGetMethod={true}
              handleChkdList={setChkdIssue}
              oneUserId={oneselectuserid}
              formSx={{ m: 1, width: 400, margin: '8px 0px 0 8px' }}      
            />
          </div>
          {divisionwork == true &&
            <div className={styles.createRow}>
              <div className={styles.createLabel}>Category</div>
              <div className={styles.createCategory}>
                <ReactSelect
                  onChange={(e) => changeCtgValue(e.value)}
                  placeholder="카테고리 선택"
                  options={selectCtgOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
                <div className={styles.createcategoryhelper}>업무 구분이 (프로그램 개선/개발) 의 경우 선택 가능합니다.</div>
            </div>
              
            </div>
          }
          <div className={styles.createRow}>
            <div className={styles.createLabel}>Description</div>
            <textarea
              placeholder="여기에 입력하세요"
              value={cworklogbodyValue}
              className={styles.createTextArea}
              onChange={(e) => changeWorklogbodyValue(e)}
              rows='4'
            /*id='create-modal-select-textarea'*/
            ></textarea>
          </div>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>날짜</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <div className={styles.datePicker}>
                  <DatePicker
                    value={dayjs(newdate)}
                    onChange={changenewDateValue}
                    format="YYYY-MM-DD"
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '2.46153rem',
                        fontSize: '0.84615rem',
                        overflow: 'hidden',
                        width: '400px'
                      }
                    }}
                    shrink={false}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>근태 시간<br/>
              {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totalshrs+')'
                : ' (0h)'
              }
            </div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "100px" }}>
              {shrsWorkTimeList.length > 0
                ? shrsWorkTimeList.map((list) => (
                  <MenuItem style={{ fontSize: '0.84615rem' }} disabled>{list}</MenuItem>
                ))
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 근태 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>등록된 시간<br/>
            {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totaltr+')'
                : ' (0h)'
              }</div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "150px", fontSize: '0.84615rem' }} >
              {trWorkTimeList.length > 0
                ? rendering()
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 작업량 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>







          <div className={styles.createRow}>
            <div className={styles.createLabel}>시간 선택</div>
            <div className={styles.multiItem}>
            <div className={styles.createFlex}>
              <ReactSelect
                placeholder="시간 선택"
                options={fromtimeList}
                defaultValue={fromTime}
                inputValue={searchFromTime}
                value={{ label: fromTime, value: fromTime }}
                onInputChange={changeFromTimeInput}
                onKeyDown={checkFromTimeKeydown}
                onBlur={(e) => checkFromTimeBlur(e)}
                onChange={onchangeFromTime}
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
                <FormHelperText id="list-select-insert-from-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
              </div>             
              {/* <p>
              fromTime: <b>{fromTime}</b>
              </p> */}
              <span className={styles.span}>~</span>
              <div className={styles.createFlex}>
              <ReactSelect
                placeholder="시간 선택"
                options={totimeList}
                defaultValue={toTime}
                inputValue={searchToTime}
                value={{ label: toTime, value: toTime }}
                onInputChange={changeToTimeInput}
                onKeyDown={checkToTimeKeydown}
                onBlur={(e) => checkToTimeBlur(e)}
                onChange={onchangeToTime}
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
                <FormHelperText id="list-select-insert-to-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
            </div>
            </div>
          
          </div>



          <div className={styles.createRow}>
            <div className={styles.createLabel}>Worked</div>
            <input
              placeholder="0h"
              className={styles.createInput}
              onChange={(e) => setComTime(e.target.value)}
              onKeyDown={checkWorkedKeydown}
              onBlur={(e) => checkWorkedTimeColon(e.target.value)}
              value={comTime}
            />
          </div>
          <div className={styles.createRowWrap}>
          <div className={styles.createLabel}/>
            {/* <div className={styles.createhelper}>근태와 동일하게 등록 바랍니다.</div> */}
            <div className={styles.createhelper}>실제 근태와 지라 근태는 차이가 있을 수 있습니다. 실제 근태는 SHRS 시스템, 차이나는 이유는 HELP를 참고해주세요.</div>
         
            </div>
        </div>
      );
    });

     // 시간기록 모달 오픈
    setModalInfo({
      isOpen: true,
      isClose: false, 
      childNode: ({setAddInfo, chkInfo}) => <CreateModalView setAddInfo={setAddInfo} chkInfo={chkInfo} />,
      isBackdropClose: true,
      sx: {}, // 모달 style
      btnInfo: {
        confirm: {
          name: '등록',
          callback: async(props) => {

            let date = dayjs(props.addInfo.newdate);

           
            //업무구분 체크 여부가 false일 경우, 카테고리 기타(ETC) 처리 
            if (props.addInfo.divisionwork == false) {
              props.addInfo.ctgValue = "기타(ETC)";
            }

            //Description이 null이면 카테고리 값으로 처리
            let cworklogbodychk = props.addInfo.cworklogbodyValue;
            if (cworklogbodychk == "") {
              cworklogbodychk = props.addInfo.ctgValue;
            }

            //startdate 
            let fromdate = dayjs(props.addInfo.newdate);
            let fromchk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", 0).format("YYYY-MM-DD HH:mm:ss");

            //enddate
            let todate = dayjs(props.addInfo.newdate);
            let tochk; 


            //timeworked 시간 계산
            let timeworked = props.addInfo.comTime;
            let timework_h, timework_m;

            //timeworked 형변환
            if (timeworked.includes('h') && timeworked.includes('m')) {     // 시간, 분 모두 입력된 경우
              timeworked = timeworked.toString().split(" ");
              timework_h = timeworked[0].replace(/h/g, "");
              timework_m = timeworked[1].replace(/m/g, "");
              timeworked = ((parseInt(timework_h) * 60) * 60) + (parseInt(timework_m) * 60);
            } else if (timeworked.includes('h')) {      // 시간만 입력된 경우
              timework_h = timeworked.toString().split("h");
              timeworked = (parseInt(timework_h) * 60) * 60;
            } else {    // 분만 입력된 경우
              timework_m = timeworked.toString().split("m");
              timeworked = (parseInt(timework_m) * 60);
            }
            
            //tochk = fromTime + timeworked 
            tochk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", timeworked).format("YYYY-MM-DD HH:mm:ss");

            //유효성 체크 
            if (props.addInfo.chkdIssue == null) {
              alert("이슈 선택하세요.");
            } else if (props.addInfo.ctgValue == "") {
              alert("카테고리를 선택하세요.");
            } else if (props.addInfo.comTime == "0h" || props.addInfo.comTime == "") {
              alert("시간입력을 하세요.");
            } else {

          
              let datechk = date.format("YYYY-MM-DD");

              //근태 등록 체크 
              const statechk = 'insert';

              //근태 등록 from 
              let hrfromchk = props.addInfo.fromTime.substr(0, 2)+props.addInfo.fromTime.substr(3, 5);

              //근태 등록 to
              let hrtochk = props.addInfo.toTime.substr(0, 2)+props.addInfo.toTime.substr(3, 5);

              // 프로그레스 오픈
              setIsCallApi(true);


              //근태 등록 여부 체크 API 호출
              let tempRst = await post('etc/timecuchk', {
                "userName": userId,
                "date": datechk,
                "fromTime": hrfromchk,
                "toTime": hrtochk,
                "timeSpent" : timeworked,
                "division" : statechk,
                "updateWorklogId" : ""
              });

              // 프로그레스 닫기
              setIsCallApi(false);

              //근태 등록이 필요하면 호출 [Y(시간기록 가능), NSFT(근태시간 부족), NT(작업량 존재), NST(Total 시간 초과)]
              //totaltimechk == return 값 체크 [WORK(근태화면), STAY(팝업 그대로), OK(성공 - 등록/수정)]
              if (tempRst == 'NSFT') {
                alert("입력하신 시간은 근무 시간이 아닙니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk, "", props.addInfo.chkdIssue.issuekey, props.addInfo.ctgValue, encodeURI(cworklogbodychk), timeworked);
                return props.addInfo.totaltimechk="WORK";
              } else if (tempRst == 'NT') {
              
                alert("입력하신 시간에 이미 기록된 시간이 있습니다. 확인 후 입력 부탁 드립니다.")
                return  props.addInfo.totaltimechk="STAY"

              } else if (tempRst == 'NST') {
                
                alert("근태 시간이 부족합니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk, "", props.addInfo.chkdIssue.issuekey, props.addInfo.ctgValue, encodeURI(cworklogbodychk), timeworked);
                return props.addInfo.totaltimechk="WORK"
              }else{

                // 프로그레스 오픈
                setIsCallApi(true);

                let tempRstCreate = await post('worklog/createWorklog', {
                  "issueKey": props.addInfo.chkdIssue.issuekey,
                  "userName": userId,
                  "startDate": fromchk,
                  "endDate": tochk,
                  "category": props.addInfo.ctgValue,
                  "worklogBody": encodeURI(cworklogbodychk),
                  "timeSpent": timeworked
                });

                // 프로그레스 닫기
                setIsCallApi(false);

                if (tempRstCreate[0] === false) {
                  alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
                }
                else{
      
                   // 타임아웃 인스턴스 존재 체크
                if (!!timeoutInstcs.errAlert) return false;
                // errAlert 오픈
                setErrAlertInfo({ open: true, msg: '등록되었습니다.' });
                // errAlert에 대한 인스턴스 세팅
                timeoutInstcs.errAlert = setTimeout(() => {
                  // errAlert 닫기
                  setErrAlertInfo({ open: false, msg: null });
                  // clear 인스턴스
                  clearTimeoutInstcs('errAlert');
                }, 1200);
                
                //기존에 검색한 경우, 작업기록 등록처리 후 검색 처리
                if(searchChk == true) {
                  getWlrList(1);
                }
                
                return false;
                }
 
                return  props.addInfo.totaltimechk="OK";

              }
            }
          }
        },
        cancle: {
          name: 'Cancle'
        }
      },
    })
  }

  // 시간기록 수정 모달 오픈
  const openUpdateModal = (e, rowDtlData) => {
   

    // from/to time list 세팅
    const [fromtimeList, totimeList] = getFromToTimeList();

    // Update Modal View 컴포넌트
    const UpdateModalView = memo(({
      setAddInfo = (props) => { }
    }) => {

      const [fromTime, setFromTime] = useState(rowDtlData[e.target.value].starttime);
 
      const [toTime, setToTime] = useState(rowDtlData[e.target.value].endtime);

      const [searchFromTime, setSearchFromTime] = useState("");
      const [searchToTime, setSearchToTime] = useState("");

      const [ctgValue, setCtgValue] = useState(rowDtlData[e.target.value].category);

      const [comTime, setComTime] = useState(rowDtlData[e.target.value].timeworked);

      const [issueValue, setIssueValue] = useState(rowDtlData[e.target.value].summary);

      const [worklogbodyValue, setWorklogbodyValue] = useState(rowDtlData[e.target.value].worklogbody);

      const [dateValue, setDateValue] = useState(rowDtlData[e.target.value].startdate);

      const [totaltimechk, setTotaltimechk] = useState("");

      //업무구분 체크 YN
      const [divisionwork, setDivisionWork] = useState(false);

      //SHRS 근태 시간 (작업 기록)
      const [shrsWorkTimeList, setShrsWorkTimeList] = useState([]);

      //SHRS 근태 시간 (작업 기록)
      const [trWorkTimeList, settrWorkTimeList] = useState([]);

      //SHRS 근태, 작업량 시간 체크 
      const [shrstrTimeList, setShrsTrTimeList] = useState([]);

      // 등록된 시간 선택 가능 시간 클릭 이벤트
      const WorkTimeClick = useCallback((from, to) => {

        //from 시간 셋팅
        setFromTime(from);

        //to 시간 셋팅
        setToTime(to);

      }, []);
      
      
      
            //작업량 근태 체크 및 api 호출 
            const chkWorkTime = async () => {
      
      
              //작업량 근태 from,to 리스트 api 호출 
              let worktimechk = await post('etc/getFromTo', {
                "userName": String(userId),
                "date": dayjs(dateValue).format("YYYY-MM-DD")
              });

              //작업, 근태 (0h)
              setShrsTrTimeList(worktimechk)

              //근태 시간 셋팅
              setShrsWorkTimeList(worktimechk)
      
              //작업 근태 시간 
              let workShrs = worktimechk.shrs;
      
              //작업 기록 시간
              let workTr = worktimechk.tr;
      
              //test langth 체크 
              for (let i = 0; i < workShrs.length; i++) {
                workShrs[i] = workShrs[i][0].substr(0, 2) + ":" + workShrs[i][0].substr(2, 4) + " ~ " + workShrs[i][1].substr(0, 2) + ":" + workShrs[i][1].substr(2, 4) + "  (" + workShrs[i][2] + ")";
              }
      
              //근태시간 셋팅
              setShrsWorkTimeList(workShrs)
      
              //작업시간 셋팅
              settrWorkTimeList(workTr)
      
      
            }
      
      
            //rendering 
            const rendering = () => {
              const result = [];
      
      
              for (let i = 0; i < trWorkTimeList.length; i++) {
                result.push(
      
                  trWorkTimeList[i][2] == "선택가능"
                    ?
                    <MenuItem key={i} style={{ fontSize: '0.84615rem' }} title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3]} onClick={() => {
                      WorkTimeClick(
                        (trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4))
                        ,
                        (trWorkTimeList[i][1].substr(0, 2) + ":" + trWorkTimeList[i][1].substr(2, 4))
                      )
                    }}>
                      <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} (<b style={{color:"#28a745", fontWight:"200"}}>{trWorkTimeList[i][2]}</b>)&nbsp;&nbsp;</div>
                      <div> {trWorkTimeList[i][3]}  </div>
                    </MenuItem>
                    :
                    <div title={trWorkTimeList[i][0].substr(0, 2) + ":" + trWorkTimeList[i][0].substr(2, 4) + '~' + trWorkTimeList[i][1].substr(0, 2) + ':' + trWorkTimeList[i][1].substr(2, 4) + ' (' + trWorkTimeList[i][2] + ') ' + trWorkTimeList[i][3] + ' ' + trWorkTimeList[i][4] + ' ' + trWorkTimeList[i][5]} >
                      <MenuItem key={i} style={{ fontSize: '0.84615rem' }} disabled>
                        <div> {trWorkTimeList[i][0].substr(0, 2)}:{trWorkTimeList[i][0].substr(2, 4)} ~ {trWorkTimeList[i][1].substr(0, 2)}:{trWorkTimeList[i][1].substr(2, 4)} ({trWorkTimeList[i][2]})&nbsp;</div>
                        <div > {trWorkTimeList[i][3]} </div>&nbsp;
                        <div>
                          {trWorkTimeList[i][4].length < 10 ? trWorkTimeList[i][4] : trWorkTimeList[i][4].slice(0, 10) + '...'}
      
                        </div>&nbsp;
                        <div>
                          {trWorkTimeList[i][5].length < 10 ? trWorkTimeList[i][5] : trWorkTimeList[i][5].slice(0, 10) + '...'}
                        </div>
                      </MenuItem>
                    </div>
                )
      
              }
              return result;
            };
        //rendering return
          

      const chkDivisionWork = async () => {

        let YNcheck = await get('etc/divisionofwork', { "issueKey": rowDtlData[e.target.value].issuekey });


        if (YNcheck == "false") {
          //true일 경우 set false 설정
          setDivisionWork(false)
        } else {
          //true일 경우 set true 설정
          setDivisionWork(true)
        }
      }

      //업무유형 구분 셋팅하기 위한 useEffect (divisionwork)
      useEffect(() => {
        chkDivisionWork();
      }, [divisionwork]);

      //날짜 클릭 시 셋팅하기 위한 useEffect (newdate)
      useEffect(() => {
        chkWorkTime();
        rendering();
      }, [dateValue]);


      //시간 입력 시 worked를 셋팅하기 위한 useEffect (fromTime)
      useEffect(() => {
        changeInputTimeValue("from", fromTime);
      }, [fromTime]);

      //시간 입력 시 worked를 셋팅하기 위한 useEffect (toTime)
      useEffect(() => {
        changeInputTimeValue("to", toTime);
      }, [toTime]);

      const changeCtgValue = useCallback((event) => {
        setCtgValue(event)
      }, []);

      const changeupdateWorklogbodyValue = useCallback((event) => {
        setWorklogbodyValue(event.target.value)
      }, []);

      // comTime 변경 감지 훅
      useEffect(() => {

        setAddInfo({ totaltimechk, issueValue, ctgValue, worklogbodyValue, dateValue, fromTime, toTime, comTime, divisionwork });
      }, [totaltimechk, issueValue, ctgValue, worklogbodyValue, dateValue, fromTime, toTime, comTime, divisionwork]);

      const changenewDateValue = useCallback((event) => {
        if (event == null) {
          return;
        }
        setDateValue(event)

   

      }, []);



      // from time input 값 변경 시, 호출
      const changeFromTimeInput = (val) => {
        setSearchFromTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // fromTime state 변경
        setFromTime(val);
        // time list 변경
        changeInputTimeValue("from", val);
      };

      // to time input 값 변경 시, 호출
      const changeToTimeInput = (val) => {
        setSearchToTime(val);
        // 빈 값 체크
        if (val == "") return;
        // 자릿수 체크
        if (val.length != 5) return;
        // : 체크
        if (val.indexOf(':') == -1) return;

        // 시, 분 세팅
        let chkArr = val.split(':');
        let mkTimeArr = [new Number(chkArr[0]), new Number(chkArr[1])];

        // 시,분 둘다 숫자 인지 체크
        if (_.isNaN(mkTimeArr[0]) || _.isNaN(mkTimeArr[1])) return;
        // 시,분 둘다 숫자 인지 체크
        if (mkTimeArr[0] >= 24 || mkTimeArr[1] >= 60) return;

        // toTime state 변경
        setToTime(val);
        // time list 변경
        changeInputTimeValue("to", val);
      };


      const checkToTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-update-to-helper').style.display='none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-update-to-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-update-to-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setToTime(time);
            } else {
              time = hours + ":" + minute;
              setToTime(time);
              compare_worked();
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-update-to-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setToTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-update-to-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setToTime(time);
            } else {
              time = replaceTime + ":00";
              setToTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setToTime(time);
          } else {
            time = "00:00";
            setToTime(time);
            document.getElementById('list-select-update-to-helper').style.display='flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }

          // From, To 시간 데이터 가져오기
          const fromTimeValue = fromTime.replace(':', '');
          const toTimeValue = time.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue >= toTimeValue) {
            setFromTime(time);
            setComTime("");
          }
        }
      }

      //To 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkToTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-update-to-helper').style.display='none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-update-to-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-update-to-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setToTime(time);
          } else {
            time = hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-update-to-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setToTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-update-to-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setToTime(time);
          } else {
            time = replaceTime + ":00";
            setToTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setToTime(time);
        } else {
          time = "00:00";
          setToTime(time);
          document.getElementById('list-select-update-to-helper').style.display='flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(time);
          setComTime("");
        }
      }


      const checkWorkedKeydown = (e) => {

        if (e.key === 'Enter') {
          // 조건에 맞게 형변환
          checkWorkedTimeColon(e.target.value);
        };

      }

      //worked 시간 입력 시 체크 하는 로직 
      const checkWorkedTimeColon = (time) => {

        //값이 없으면 return
        if (time == "") return;

        document.getElementById('list-select-update-from-helper').style.display='none';
        document.getElementById('list-select-update-to-helper').style.display='none';

        let worked = time.replace(/h/i, "h");


        worked = time.replace(/m/i, "m");
        worked = time.replace(" ", "");

        // From Time 데이터값 가져오기
        const fromValue = fromTime;
        const fromValueArr = fromValue.toString().split(":");
        const fromValue_h = fromValueArr[0];
        const fromValue_m = fromValueArr[1];


        let worked_h = "";
        let worked_m = "";

        // 시간이 실수인 경우
        if (worked.match(/^\d{1,2}\.\d{1,2}$/)) {
          const worked_time = worked.toString().split(".");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1]
          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = worked_m * 6;        // 입력된 분
          } else {    // 입력된 분이 10분 이상인 경우
            worked_m = worked_m * 0.6;           // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}\:\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(":");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}\,\d{1,2}$/)) {       // 시간, 분 모두 입력한 경우
          const worked_time = worked.toString().split(",");
          worked_h = worked_time[0];          // 입력된 시간
          worked_m = worked_time[1];
        } else if (worked.match(/^\d{1,2}h\d{1,2}m$/)) {       // 시간, 분 모두 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = worked.substring(worked.indexOf("h") + 1, worked.indexOf("m"));

          // 입력된 분이 10분 미만인 경우
          if (worked_m.match(/^\d{1}$/)) {
            worked_m = "0" + worked_m;        // 입력된 분
          }
        } else if (worked.match(/^\d{1,2}h$/)) {       // 시간만 입력한 경우
          worked_h = worked.substring(0, worked.indexOf("h"));
          worked_m = 0;
        } else if (worked.match(/^\d{1,2}m$/)) {       // 분만 입력한 경우
          worked_h = 0;
          worked_m = worked.substring(0, worked.indexOf("m"));
        } else if (worked.match(/^\d{1,2}$/)) {       // 숫자만 입력한 경우 -> 시간으로 체크
          worked_h = worked;
          worked_m = 0;
        } else {
          alert("잘못된 값입니다.(Invalid value)");
          setComTime("");
          return false;
        }

        let time_click_to = new Date();
        time_click_to.setHours(fromValue_h, fromValue_m); // to 현재 값 date 초기화
        time_click_to.setHours(time_click_to.getHours() + parseInt(worked_h));
        time_click_to.setMinutes(time_click_to.getMinutes() + parseInt(worked_m));

        let toValue;

        //to 시간이 24:00 인 경우, Date에서는 00시로 표기되기 때문에 24시로 처리한다.
        if(time_click_to.getHours() == 0 && time_click_to.getMinutes() == 0){
          toValue = '24' + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
        //그 외는 그대로 처리한다.
        }else{
          toValue = ('0' + time_click_to.getHours()).slice(-2) + ":" + ('0' + time_click_to.getMinutes()).slice(-2);
        }

        /* 24시간 이상 여부 체크 로직 */

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = toValue.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          let hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = hours + ":00";
            setToTime(toValue);
          } else {
            toValue = hours + ":" + minute;
            setToTime(toValue);      
            compare_worked();        
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          let hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          let minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            alert("분은 60분을 넘길 수 없습니다.");
            toValue = "0" + hours + ":00";
            setToTime(toValue);
          } else {
            toValue = "0" + hours + ":" + minute;
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            alert("시간은 24시를 넘길 수 없습니다.");
            toValue = "24:00";
            setToTime(toValue);
          } else {
            toValue = replaceTime + ":00";
            setToTime(toValue);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          toValue = "0" + replaceTime + ":00";
          setToTime(toValue);
        } else {
          toValue = "00:00";
          setToTime(toValue);
          alert("잘못된 값입니다.(Invalid value)");
        }

        // From, To 시간 데이터 가져오기
        let fromTimeValue = fromTime.replace(':', '');
        let toTimeValue = toValue.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue >= toTimeValue) {
          setFromTime(toValue);
          setComTime("");
        }
      }


      const checkFromTimeKeydown = (time) => {

        if (time.key === 'Enter') {
          document.getElementById('list-select-update-from-helper').style.display='none';

          // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
          let replaceTime = time.target.value.replace(/\:/g, "");
          if (replaceTime.match(/^\d{4}$/)) {
            const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (hours > 24 || replaceTime > 2400) {
              document.getElementById('list-select-update-from-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
              document.getElementById('list-select-update-from-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = hours + ":00";
              setFromTime(time);
            } else {
              time = hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{3}$/)) {
            const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
            const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

            // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
            if (minute >= 60) {
              document.getElementById('list-select-update-from-helper').style.display='flex';
              //alert("분은 60분을 넘길 수 없습니다.");
              time = "0" + hours + ":00";
              setFromTime(time);
            } else {
              time = "0" + hours + ":" + minute;
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
            // 시간이 24시가 넘는지를 체크한다.
            if (replaceTime > 24) {
              document.getElementById('list-select-update-from-helper').style.display='flex';
              //alert("시간은 24시를 넘길 수 없습니다.");
              time = "24:00";
              setFromTime(time);
            } else {
              time = replaceTime + ":00";
              setFromTime(time);
            }
          } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
            time = "0" + replaceTime + ":00";
            setFromTime(time);
          } else {
            time = "00:00"
            setFromTime(time);
            document.getElementById('list-select-update-from-helper').style.display='flex';
            //alert("잘못된 값입니다.(Invalid value)");
          }
          // From, To 시간 데이터 가져오기
          const fromTimeValue = time.replace(':', '');
          const toTimeValue = toTime.replace(':', '');

          // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
          if (fromTimeValue > toTimeValue) {
            setToTime(time);
            setComTime("");
          }
        }
      }

      //From 시간 외부 클릭 시, 로직 체크 및 셋팅
      const checkFromTimeBlur = (time) => {

        //값이 없으면 return
        if (time.target.value == "") return;
        document.getElementById('list-select-update-from-helper').style.display='none';

        // replace 함수를 사용하여 콜론( : )을 공백으로 치환한다.
        let replaceTime = time.target.value.replace(/\:/g, "");
        if (replaceTime.match(/^\d{4}$/)) {
          const hours = replaceTime.substring(0, 2);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(2, 4);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (hours > 24 || replaceTime > 2400) {
            document.getElementById('list-select-update-from-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else if (minute >= 60) {     // 입력한 분의 값이 60분을 넘는지 체크한다.
            document.getElementById('list-select-update-from-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = hours + ":00";
            setFromTime(time);
          } else {
            time = hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{3}$/)) {
          const hours = replaceTime.substring(0, 1);      // 선언한 변수 hours에 시간값을 담는다.
          const minute = replaceTime.substring(1, 3);     // 선언한 변수 minute에 분을 담는다.

          // 두 변수의 시간과 분을 합쳐 입력한 시간이 24시가 넘는지를 체크한다.
          if (minute >= 60) {
            document.getElementById('list-select-update-from-helper').style.display='flex';
            //alert("분은 60분을 넘길 수 없습니다.");
            time = "0" + hours + ":00";
            setFromTime(time);
          } else {
            time = "0" + hours + ":" + minute;
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{2}$/)) {     // 시간만 입력했을 경우
          // 시간이 24시가 넘는지를 체크한다.
          if (replaceTime > 24) {
            document.getElementById('list-select-update-from-helper').style.display='flex';
            //alert("시간은 24시를 넘길 수 없습니다.");
            time = "24:00";
            setFromTime(time);
          } else {
            time = replaceTime + ":00";
            setFromTime(time);
          }
        } else if (replaceTime.match(/^\d{1}$/)) {     // 시간만 입력했을 경우
          time = "0" + replaceTime + ":00";
          setFromTime(time);
        } else {
          time = "00:00"
          setFromTime(time);
          document.getElementById('list-select-update-from-helper').style.display='flex';
          //alert("잘못된 값입니다.(Invalid value)");
        }
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time);
          setComTime("");
        }
      }

      //select box From 시간 체크 
      const onchangeFromTime = (time) => {

        document.getElementById('list-select-update-from-helper').style.display='none';

        setFromTime(time.value);
        // From, To 시간 데이터 가져오기
        const fromTimeValue = time.value.replace(':', '');
        const toTimeValue = toTime.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setToTime(time.value);
          setComTime("");
        }
      }


      //select box to 시간 체크 
      const onchangeToTime = (time) => {

        document.getElementById('list-select-update-to-helper').style.display='none';

        setToTime(time.value);

        // From, To 시간 데이터 가져오기
        const fromTimeValue = fromTime.replace(':', '');
        const toTimeValue = time.value.replace(':', '');

        // From Time 이 To Time보다 큰 경우 To Time 에 From Time의 데이터를 삽입
        if (fromTimeValue > toTimeValue) {
          setFromTime(time.value);
          setComTime("");
        }
      }

      const changeInputTimeValue = (target, val) => {
        if (target === "from") {
          fromtimeList.length = 0;
        } else {
          totimeList.length = 0;
        }
        const time = val;

        const time_click = new Date();

        const timeArr = time.split(":");

        time_click.setHours(timeArr[0], timeArr[1]);

        for (let j = 0; j < 48; j++) {
          const op = {};
          //시간
          const time_slice = ('0' + time_click.getHours()).slice(-2) + ":" + ('0' + time_click.getMinutes()).slice(-2);
          time_click.setMinutes(time_click.getMinutes() + 30);

          op.value = time_slice;
          op.label = time_slice;

          if (target === "from") {
            fromtimeList.push(op);
            // e.target.defaultValue =fromtimeList[0].value;
          } else {
            totimeList.push(op);
          }
        }
        compare_worked();
      };

      // From, To 데이터 변경에 따른 Worked데이터 입력
      const compare_worked = () => {
       
        //from,to 시간 차이 계산
        let compare_from = fromTime.split(":");
        let compare_to = toTime.split(":");

        const compare_time_from = new Date();
        compare_time_from.setHours(compare_from[0], compare_from[1]);

        const compare_time_to = new Date();
        compare_time_to.setHours(compare_to[0], compare_to[1]);

        var compare_time = compare_time_to.getTime() - compare_time_from.getTime();
        var compare_time_hour = compare_time / 1000 / 60 / 60;
        var compare_time_min = compare_time / 1000 / 60 - (parseInt(compare_time_hour) * 60);


        if (parseInt(compare_time_hour) == 0 && parseInt(compare_time_min) == 0) {
          return;
        }
        else if (parseInt(compare_time_hour) != 0 && parseInt(compare_time_min) != 0) {
          setComTime(parseInt(compare_time_hour) + "h " + parseInt(compare_time_min) + "m");
        }
        else if (parseInt(compare_time_hour) == 0) {
          setComTime(parseInt(compare_time_min) + "m");
        }

        else {
          setComTime(parseInt(compare_time_hour) + "h");
        }
      };



      const customStyles = useMemo(
        () => ({
          control: (provided, state) => ({
            ...provided,
            background: '#F7F7F7',
            height: '2.46153rem',
            minWidth: '6.667rem',
            fontSize: '0.84615rem',
            boxShadow: state.isFocused ? null : null,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "rgba(25, 118, 210, 0.08)"
              : state.isFocused
                ? 'rgba(0, 0, 0, 0.04)'
                : null,
            color: '#222',
            fontSize: '0.84615rem'
          }),

          valueContainer: (provided, state) => ({
            ...provided,
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
          }),
        }),
        []
      );

      const DropdownIndicator = (props) => {
        const { menuIsOpen } = props.selectProps;
        if (menuIsOpen) {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        } else {
          return (
            <components.DropdownIndicator {...props}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="#757575" viewBox="0 0 24 24">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </components.DropdownIndicator>
          );
        }
      };

      return (
        <div className={styles.modalContent}>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>이슈</div>
            {/* 이슈 셀렉트 */}
            <input
              label="이슈"
              value={issueValue}
              className={styles.createIssueInput}
              readOnly/>
          </div>
          {divisionwork == true &&
            <div className={styles.createRow}>
              <div className={styles.createLabel}>Category</div>
              <div className={styles.createCategory}>
                <ReactSelect
                  onChange={(e) => changeCtgValue(e.value)}
                  placeholder="카테고리 선택"
                  options={selectCtgOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  defaultValue={ctgValue}
                  value={{ label: ctgValue, value: ctgValue }}
                />
              </div>
            </div>
          }
          <div className={styles.createRow}>
            <div className={styles.createLabel}>Description</div>
            <textarea
              placeholder="여기에 입력하세요"
              type="text"
              defaultValue={worklogbodyValue}
              className={styles.createTextArea}
              onChange={(e) => changeupdateWorklogbodyValue(e)}
              rows='4'
            /*id='create-modal-select-textarea'*/
            ></textarea>
          </div>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>날짜</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <div className={styles.datePicker}>
                  <DatePicker
                    value={dayjs(dateValue)}
                    onChange={changenewDateValue}
                    format="YYYY-MM-DD"
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '2.46153rem',
                        fontSize: '0.84615rem',
                        overflow: 'hidden',
                        width: '400px'
                      }
                    }}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
          </div>

          
          <div className={styles.createRow}>
            <div className={styles.createLabel}>근태 시간<br/>
              {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totalshrs+')'
                : ' (0h)'
              }
            </div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "100px" }}>
              {shrsWorkTimeList.length > 0
                ? shrsWorkTimeList.map((list) => (
                  <MenuItem style={{ fontSize: '0.84615rem' }} disabled>{list}</MenuItem>
                ))
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 근태 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>

          <div className={styles.createRow}>
            <div className={styles.createLabel}>등록된 시간<br/>
            {Object.keys(shrstrTimeList).length > 0
                ? '('+shrstrTimeList.totaltr+')'
                : ' (0h)'
              }</div>
            <MenuList className={styles.createTextArea} style={{ overflowY: "scroll", height: "150px", fontSize: '0.84615rem' }} >
              {trWorkTimeList.length > 0
                ? rendering()
                : <MenuItem style={{ fontSize: '0.84615rem' }} disabled>등록된 작업량 데이터가 존재하지 않습니다.</MenuItem>
              }
            </MenuList>
          </div>








          <div className={styles.createRow}>
            <div className={styles.createLabel}>시간 선택</div>
            <div className={styles.multiItem}>
            <div className={styles.createFlex}>
              <ReactSelect
                placeholder="시간 선택"
                options={fromtimeList}
                defaultValue={fromTime}
                inputValue={searchFromTime}
                value={{ label: fromTime, value: fromTime }}
                onInputChange={changeFromTimeInput}
                onKeyDown={checkFromTimeKeydown}
                onBlur={(e) => checkFromTimeBlur(e)}
                onChange={onchangeFromTime}
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
                <FormHelperText id="list-select-update-from-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
              </div>
              {/* <p>
            fromTime: <b>{fromTime}</b>
            </p> */}
              <span className={styles.span}>~</span>
              <div className={styles.createFlex}>
              <ReactSelect
                placeholder="시간 선택"
                options={totimeList}
                defaultValue={toTime}
                inputValue={searchToTime}
                value={{ label: toTime, value: toTime }}
                onInputChange={changeToTimeInput}
                onKeyDown={checkToTimeKeydown}
                onBlur={(e) => checkToTimeBlur(e)}
                onChange={onchangeToTime}
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
                <FormHelperText id="list-select-update-to-helper"
                  sx={{ fontSize: '0.65rem', display: 'none', alignItems: 'flex-start', marginLeft: '0', color: 'red' }}
                >
                  잘못 입력했습니다
                </FormHelperText>
            </div>
            </div>
          </div>
          <div className={styles.createRow}>
            <div className={styles.createLabel}>Worked</div>
            <input
              placeholder="0h"
              className={styles.createInput}
              onChange={(e) => setComTime(e.target.value)}
              onKeyDown={checkWorkedKeydown}
              onBlur={(e) => checkWorkedTimeColon(e.target.value)}
              value={comTime}
            />
          </div>
          <div className={styles.createRowWrap}>
          <div className={styles.createLabel}/>
            {/* <div className={styles.createhelper}>근태와 동일하게 등록 바랍니다.</div> */}
            <div className={styles.createhelper}>실제 근태와 지라 근태는 차이가 있을 수 있습니다. 실제 근태는 SHRS 시스템, 차이나는 이유는 HELP를 참고해주세요.</div>
         
            </div>
        </div>
      );
    });

    // 시간기록 수정 모달 오픈
    setupModalInfo({
      isOpen: true,
      isClose: false, 
      childNode: ({setAddInfo}) => <UpdateModalView setAddInfo={setAddInfo} />,
      isBackdropClose: true,
      sx: {}, // 모달 style
      btnInfo: {
        confirm: {
          name: '수정',
          callback: async(props) => {
            let date = dayjs(props.addInfo.dateValue);

            //Description이 null이면 카테고리 값으로 처리
            let worklogbodychk = props.addInfo.worklogbodyValue;
            if (worklogbodychk == "") {
              worklogbodychk = props.addInfo.ctgValue;
            }

            //startdate 
            let fromdate = dayjs(props.addInfo.dateValue);
            let fromchk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", 0).format("YYYY-MM-DD HH:mm:ss");

            //enddate
            let todate = dayjs(props.addInfo.dateValue);
            let tochk;


            //timeworked 시간 계산
            let timeworked = props.addInfo.comTime;
            let timework_h, timework_m;

            //timeworked 형변환
            if (timeworked.includes('h') && timeworked.includes('m')) {     // 시간, 분 모두 입력된 경우
              timeworked = timeworked.toString().split(" ");
              timework_h = timeworked[0].replace(/h/g, "");
              timework_m = timeworked[1].replace(/m/g, "");
              timeworked = ((parseInt(timework_h) * 60) * 60) + (parseInt(timework_m) * 60);
            } else if (timeworked.includes('h')) {      // 시간만 입력된 경우
              timework_h = timeworked.toString().split("h");
              timeworked = (parseInt(timework_h) * 60) * 60;
            } else {    // 분만 입력된 경우
              timework_m = timeworked.toString().split("m");
              timeworked = (parseInt(timework_m) * 60);
            }

            //tochk = fromTime + timeworked 
            tochk = fromdate.set("h", props.addInfo.fromTime.substr(0, 2)).set("m", props.addInfo.fromTime.substr(3, 5)).set("s", timeworked).format("YYYY-MM-DD HH:mm:ss");


            if (props.addInfo.comTime == "0h" || props.addInfo.comTime == "") {
              alert("시간입력을 하세요.");
              return;
            } else {

              // 프로그레스 오픈
              setIsCallApi(true);

              let datechk = date.format("YYYY-MM-DD");

              //근태 등록 체크 
              const statechk = 'update';

              //근태 등록 from 
              let hrfromchk = props.addInfo.fromTime.substr(0, 2)+props.addInfo.fromTime.substr(3, 5);

              //근태 등록 to
              let hrtochk = props.addInfo.toTime.substr(0, 2)+props.addInfo.toTime.substr(3, 5);


              //근태 등록 여부 체크 API 호출
              let tempRst = await post('etc/timecuchk', {
                "userName": userId,
                "date": datechk,
                "fromTime": hrfromchk,
                "toTime": hrtochk,
                "timeSpent" : timeworked,
                "division" : statechk,
                "updateWorklogId" : rowDtlData[e.target.value].wid
              });


              // 프로그레스 닫기
              setIsCallApi(false);

              //근태 등록이 필요하면 호출 [Y(시간기록 가능), NSFT(근태시간 부족), NT(작업량 존재), NST(Total 시간 초과)]
              //totaltimechk == return 값 체크 [WORK(근태화면), STAY(팝업 그대로), OK(성공 - 등록/수정)]
              if (tempRst == 'NSFT') {
                alert("입력하신 시간은 근무 시간이 아닙니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk,rowDtlData[e.target.value].wid, rowDtlData[e.target.value].issuekey, props.addInfo.ctgValue, encodeURI(worklogbodychk), timeworked);
                return props.addInfo.totaltimechk="WORK";
              } else if (tempRst == 'NT') {
                alert("입력하신 시간에 이미 기록된 시간이 있습니다. 확인 후 입력 부탁 드립니다.")
                return props.addInfo.totaltimechk="STAY";
              } else if (tempRst == 'NST') {
                alert("근태 시간이 부족합니다. 근태를 먼저 등록해주시길 바랍니다.")
                OpenWorkModal(statechk, datechk, fromchk, tochk,rowDtlData[e.target.value].wid, rowDtlData[e.target.value].issuekey, props.addInfo.ctgValue, encodeURI(worklogbodychk), timeworked);
                return props.addInfo.totaltimechk="WORK";
              }else{

                

                
              // 프로그레스 오픈
              setIsCallApi(true);

              let tempRstUpdate = await post('worklog/updateWorklog', {
                "issueKey": rowDtlData[e.target.value].issuekey,
                "userName": userId,
                "startDate": fromchk,
                "endDate": tochk,
                "worklogId": rowDtlData[e.target.value].wid,
                "category": props.addInfo.ctgValue,
                "worklogBody": encodeURI(worklogbodychk),
                "timeSpent": timeworked
              });

              
                // 프로그레스 닫기
                setIsCallApi(false);          

              if (tempRstUpdate[0] === false) {
                alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
              } else {


                // 타임아웃 인스턴스 존재 체크
                if (!!timeoutInstcs.errAlert) return false;
                // errAlert 오픈
                setErrAlertInfo({ open: true, msg: '수정되었습니다.' });
                // errAlert에 대한 인스턴스 세팅
                timeoutInstcs.errAlert = setTimeout(() => {
                  // errAlert 닫기
                  setErrAlertInfo({ open: false, msg: null });
                  // clear 인스턴스
                  clearTimeoutInstcs('errAlert');
                }, 1200);
                //상세보기 팝업창 닫기
                setDtlModalInfo({ isClose: true });
                getWlrList(1);
                return false;
              }
              return props.addInfo.totaltimechk="OK";
              }
            }
          }
        },
        cancle: {
          name: '취소'
        }
      }
    })
}
const OpenWorkModal = (statechk,datechk,fromchk,tochk, wid, issuekey, ctgValue, worklogbody, timeworked) => {

  // Work Modal View 컴포넌트
  const WorkModalView = memo( ({
     setAddInfo = (props) => { }
    }) => {
      const [WorkData, setWorkData] = useState([]);
    const [WorkOption, setWorkOption] = useState('');

    const [totaltimechk, setTotaltimechk] = useState("");
    
    const [Date, setDate] = useState(datechk);

    // setAddInfo props 
    useEffect(() => {
      setAddInfo({totaltimechk, statechk, WorkData,Date,fromchk,tochk,wid,issuekey,ctgValue,worklogbody,timeworked});
    }, [totaltimechk, statechk, WorkData,Date,fromchk,tochk,wid,issuekey,ctgValue,worklogbody,timeworked]);


    useEffect(() => {
      workch();    
    }, []);


    const workch = async() => {

      // 프로그레스 오픈
      setIsCallApi(true);

      let tmpSeletList; 

        // 근태 시간 데이터 조회
        let tempRst = await post('hr/getBydatehrlogtime ', {
          "date": datechk,
          "userName": userId,
        });
    
        // 프로그레스 닫기
        setIsCallApi(false);

        let datas_select = tempRst ?? [];
        if (_.isArray(tempRst?.[0])) {
          datas_select = tempRst[0];
        }
    
         tmpSeletList = _.map(datas_select, x => {
          return x;
        });

        //근태 시간 데이터 셋팅
        setWorkData(tmpSeletList)
      }



    //if(WorkData.length >0) {
      
    //근태 시간 조회 API 값 정상여부 체크 
    if(WorkData) {
    return (
         <Work workDatacheck={WorkData} date={datechk} userId={userId} />
    );
    //근태 시간 조회 API 값 없을 시에 해당 메시지 호출
    }else {
      alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.")
    }
  });
   // 시간기록 모달 오픈
   setWorkModalInfo({
    isOpen: true,
    childNode: ({setAddInfo}) => <WorkModalView setAddInfo={setAddInfo} />,
    isBackdropClose: true,
    sx: {}, // 모달 style
    btnInfo: {
      confirm: {
        name: '작업량 등록',
        callback: async(props) => {  

          // 프로그레스 오픈
          setIsCallApi(true);

           //근태 등록 from 
           let hrfromchk = props.addInfo.fromchk.substr(11, 2)+props.addInfo.fromchk.substr(14, 2);

           //근태 등록 to
          let hrtochk = props.addInfo.tochk.substr(11, 2)+props.addInfo.tochk.substr(14, 2);

          
          //근태 등록 여부 체크 API 호출
          let tempRst = await post('etc/timecuchk', {
            "userName": userId,
            "date": props.addInfo.Date,
            "fromTime": hrfromchk,
            "toTime": hrtochk,
            "timeSpent": props.addInfo.timeworked,
            "division" : statechk,
            "updateWorklogId" : props.addInfo.wid
          });

          // 프로그레스 닫기
          setIsCallApi(false);

          //근태 등록이 필요하면 호출 [Y(시간기록 가능), NSFT(근태시간 부족), NT(작업량 존재), NST(Total 시간 초과)]
          //totaltimechk == return 값 체크 [WORK(근태화면), STAY(팝업 그대로), OK(성공 - 등록/수정)]
          if (tempRst == 'NSFT') {
            alert("입력하신 시간은 근무 시간이 아닙니다. 근태를 먼저 등록해주시길 바랍니다.")
            return props.addInfo.totaltimechk="STAY";
          } else if (tempRst == 'NT') {
            alert("입력하신 시간에 이미 기록된 시간이 있습니다. 확인 후 입력 부탁 드립니다.")
            return props.addInfo.totaltimechk="STAY";
          } else if (tempRst == 'NST') {
            alert("근태 시간이 부족합니다. 근태를 먼저 등록해주시길 바랍니다.")
            return props.addInfo.totaltimechk="STAY";
          } else {
           
          //시간 등록일 때, props.addInfo.wid  == '' 
          if(statechk == 'insert'){
 
          // 프로그레스 오픈
          setIsCallApi(true);

            let tempRst = await post('worklog/createWorklog', {
              "issueKey": props.addInfo.issuekey,
              "userName": userId,
              "startDate": props.addInfo.fromchk,
              "endDate": props.addInfo.tochk,
              "category": props.addInfo.ctgValue,
              "worklogBody": props.addInfo.worklogbody,
              "timeSpent": props.addInfo.timeworked

            });

            
              // 프로그레스 닫기
              setIsCallApi(false);

            if (tempRst[0] === false) {
              alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
            }else{

                // 타임아웃 인스턴스 존재 체크
                if (!!timeoutInstcs.errAlert) return false;
                // errAlert 오픈
                setErrAlertInfo({ open: true, msg: '등록되었습니다.' });
                // errAlert에 대한 인스턴스 세팅
                timeoutInstcs.errAlert = setTimeout(() => {
                  // errAlert 닫기
                  setErrAlertInfo({ open: false, msg: null });
                  // clear 인스턴스
                  clearTimeoutInstcs('errAlert');
                }, 1200);

              //등록 팝업창 닫기
              setModalInfo({ isClose: true });

              //기존에 검색한 경우, 작업기록 등록처리 후 검색 처리
              if (searchChk == true) {
                getWlrList(1);
              }

              return false;

            }

            
          // 시간 변경일 때, props.addInfo.wid  == 특정 값
          }else if(statechk == 'update'){

          // 프로그레스 오픈
          setIsCallApi(true);

            let tempRst = await post('worklog/updateWorklog', {
              "issueKey": props.addInfo.issuekey,
              "userName": userId,
              "startDate": props.addInfo.fromchk,
              "endDate": props.addInfo.tochk,
              "worklogId": props.addInfo.wid, //추가필요
              "category": props.addInfo.ctgValue,
              "worklogBody": props.addInfo.worklogbody,
              "timeSpent": props.addInfo.timeworked
            });

                        
              // 프로그레스 닫기
              setIsCallApi(false);

            if (tempRst[0] === false) {
              alert("데이터 처리에 실패했습니다. 담당자에게 문의주세요.");
            } else {

              // 타임아웃 인스턴스 존재 체크
              if (!!timeoutInstcs.errAlert) return false;
              // errAlert 오픈
              setErrAlertInfo({ open: true, msg: '수정되었습니다.' });
              // errAlert에 대한 인스턴스 세팅
              timeoutInstcs.errAlert = setTimeout(() => {
                // errAlert 닫기
                setErrAlertInfo({ open: false, msg: null });
                // clear 인스턴스
                clearTimeoutInstcs('errAlert');
              }, 1200);

              //수정 팝업창 닫기
              setupModalInfo({ isClose: true });

              //상세보기 팝업창 닫기
              setDtlModalInfo({ isClose: true });

              getWlrList(1);
            }

          }else{
            //차이 시간 클릭 후 근태 등록 시
          }
          //작업량 등록 --> 시간등록 시 입력한 데이터를 가지고 와서 처리 (props)
          return  props.addInfo.totaltimechk="OK";
        }
      },
      cancle: {
        name: 'Cancle'
      }
    },
  }
  })
}

//엑셀 다운로드 버튼 클릭 이벤트
const csvOnclick = async(e) =>{

  if(excelfromDate == null){
    alert("검색된 데이터가 존재하지 않습니다.")
    return;
  }

    //엑셀 다운로드 header api 연동
    let tempRstHeader = await post('excel/header', {
      "division": excelDivision,
      "startDate": excelfromDate,
      "endDate": execeltoDate,
    })

    //엑셀 다운로드 body api 연동
    let tempRstData = await post('excel/body', {
      "division": excelDivision,
      "startDate": excelfromDate,
      "endDate": execeltoDate,
      "pId": excelProject,
      "group": excelGroup,
      "jId": excelIssue,
      "userName": userId,
      "uId" : excelUser,
      "tId" : excelTeam,
      "workChk" : excelWorkRange
    })


  //api 데이터 set 
  setExcelHead(tempRstHeader);
  setExcelData(tempRstData);
};


  return (
    <>
      <div className={styles.bodyStyle}>
        {/* 검색 영역 */}
        <div className={styles.searchFilterArea}>
          {/* Form - to 날짜 */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <div className={styles.datePicker}>
                <DatePicker
                  label="시작일"
                  value={fromDate}
                  onChange={changeFromDate}
                  format="YYYY-MM-DD"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '2.46153rem',
                      fontSize: '0.84615rem',
                    },
                    margin: '0 8px 0 0'
                  }}
                />
                <FormHelperText
                  sx={{ fontSize: '0.65rem', display: 'flex', alignItems: 'flex-start', marginLeft: '0' }}
                >
                  시작일 선택해주세요
                </FormHelperText>
              </div>
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <div className={styles.datePicker}>
                <DatePicker
                  label="종료일"
                  value={toDate}
                  minDate={fromDate}
                  maxDate={maxDate}
                  onChange={(newValue) => setToDate(newValue)}
                  format="YYYY-MM-DD"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '2.46153rem',
                      fontSize: '0.84615rem',
                    },
                  }}
                />
                <FormHelperText
                  sx={{ fontSize: '0.65rem', display: 'flex', alignItems: 'flex-start', marginLeft: '0' }}
                >
                  종료일 선택해주세요
                </FormHelperText>
              </div>
            </DemoContainer>
          </LocalizationProvider>

          {/* 분류구분 선택 */}
          <FormControl sx={{ m: 1, width: 200, margin: '8px 0 0 8px' }} size='small'>
            <InputLabel id="list-select-helper-label"
              sx={{ fontSize: '0.84615rem' }}
              shrink={true}
            >
              분류구분 선택 *
            </InputLabel>
            <Select
              labelId="list-select-helper-label"
              id="list-select-helper"
              value={gridHeaderInfo}
              label="분류구분 선택 *"
              onChange={changeGridHeaderInfo}
              sx={{
                fontSize: '0.84615rem',
                minWidth: '6.667rem',
                height: '2.46153rem',
                alignItems: 'centerm',
              }}
              input={<OutlinedInput notched label='분류구분 선택 *' />}
            >
              {_.map(gridHeaderList, (x, idx) => (
                <MenuItem
                  key={idx}
                  value={x}
                  sx={{ fontSize: '0.84615rem' }}
                >
                  {x}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ fontSize: '0.65rem', display: 'flex', alignItems: 'flex-start', marginLeft: '0' }}>분류구분 선택해주세요</FormHelperText>
          </FormControl>

        <div id="select-work-mut">
           {/* 전체/나의 업무 셀렉트 */}
           <FormControl sx={{ m: 1, width: 200, margin: '8px 0 0 8px' }} size='small'>
            <InputLabel
              id="group-select-helper-label"
              sx={{ fontSize: '0.84615rem' }}
              shrink={true}
            >
              전체/나의 업무 선택
            </InputLabel>
            <Select
              labelId="group-select-helper-label"
              id="group-select-helper"
              value={workRange}
              label="전체/나의 업무 선택"
              onChange={changeWorkRange}
              sx={{
                fontSize: '0.84615rem',
                minWidth: '6.667rem',
                height: '2.46153rem',
                alignItems: 'centerm',
              }}
              input={<OutlinedInput notched label='전체/나의 업무 선택' />}
            >
              {_.map(workRangeList, (x, idx) => (
                <MenuItem
                  key={x.id}
                  value={x.id}
                  sx={{ fontSize: '0.84615rem' }}
                >
                  {x.nm}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              sx={{ fontSize: '0.65rem', display: 'flex', alignItems: 'flex-start', marginLeft: '0' }}
            >
              전체/나의 업무 선택해주세요
            </FormHelperText>
          </FormControl>
        </div>

          <div id="select-team-mut">
            {/* 팀별 근태 분류 - 팀 셀렉트 */}
            <SearchSelect
              id="teamSelect-test"
              label="팀"
              inputLabel="팀 선택"
              isEssential={false}
              mappingInfo={{ key: 'tid', value: 'tname' }}
              apiName="filter/getTeamList"
              isGetMethod={true}
              handleChkdList={setChkdTeamList}
              sendIsReadyCpnt={(isReady) => isReady && setSrchSltReadyList(prev => [...prev, 'teamSelect'])}
              initChkdList={initList}
            />
          </div>
          {/* 상세 버튼 */}
          <Box
            sx={{ '& button': { m: 1, margin: '10px 8px 0 8px' } }}
            className={styles.dtlBtnBox}
            id="select-detail-btn"
          >
            <Button
              className="detail small"
              onClick={toggleDtlOpen}
              style={{
                backgroundColor: isDtlOpen ? '#92C353' : null,
                color: isDtlOpen ? 'white' : null
              }}
            >
              {'상세'}
              {isDtlOpen
                ? <KeyboardArrowUpIcon />
                : <KeyboardArrowDownIcon />}

            </Button>
          </Box>

          {/* 검색 버튼 */}
          <Box
            sx={{ '& button': { m: 1, margin: '10px 8px 0 10px' } }}
            className={styles.searchBtnOutBox}
          >
            <Button
              className="search small"
              onClick={ (e) => (getWlrList(0))}
            >
              {'검색'}
              <SearchIcon />
            </Button>
          </Box>
          <Box
          sx={{ marginTop : '-13px', marginLeft: 'auto' }}
          > 
          {/* 개선요청 버튼 */}
          <Button style={{ display: 'inline-block', width:'85px', border: 'solid', color : '#4D585D' }}
            onClick={() => window.open("https://jira.sinc.co.kr/servicedesk/customer/portal/81/group/1607")}
          >
            {'개선요청'}
          </Button>
          </Box>
          <Box
          sx={{ margin: '1px 5px', marginTop : '-13px' }}
          > 
          {/* 도움말 버튼 */}
          <Button style={{ display: 'inline-block', width:'80px', border: 'solid', color : 'red' }}
            onClick={() => window.open("https://confl.sinc.co.kr/pages/viewpage.action?pageId=236169639")}
          >
            {'help'}
          </Button>
          </Box>
        </div>

        {/* 상세검색 영역 */}
        <div
          className={styles.dtlArea}
          style={{
            display: isDtlOpen ? 'block' : 'none'
          }}
        >
          {/* <div className={styles.dtlAreaTitle}>
            <p className={styles.dtlAreaTitleFont}>상세</p>
          </div> */}
          {/* 그룹 셀렉트 */}
          <FormControl sx={{ m: 1, width: 200, margin: '8px 8px 8px 0' }} size='small'>
            <InputLabel
              id="group-select-helper-label"
              sx={{ fontSize: '0.84615rem' }}
              shrink={true}
            >
              그룹 선택
            </InputLabel>
            <Select
              labelId="group-select-helper-label"
              id="group-select-helper"
              value={chkdGroup}
              label="그룹 선택"
              onChange={changeGroup}
              sx={{
                fontSize: '0.84615rem',
                minWidth: '6.667rem',
                height: '2.46153rem',
                alignItems: 'centerm',
              }}
              input={<OutlinedInput notched label='그룹 선택' />}
            >
              {_.map(groupList, (x, idx) => (
                <MenuItem
                  key={idx}
                  value={x}
                  sx={{ fontSize: '0.84615rem' }}
                >
                  {x}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              sx={{ fontSize: '0.65rem', display: 'flex', alignItems: 'flex-start', marginLeft: '0' }}
            >
              그룹 선택해주세요
            </FormHelperText>
          </FormControl>

          {/* 팀 셀렉트 */}
          <SearchSelect
            id="teamSelect"
            label="팀"
            inputLabel="팀 선택"
            isEssential={false}
            mappingInfo={{ key: 'tid', value: 'tname' }}
            apiName="filter/getTeamList"
            isGetMethod={true}
            handleChkdList={setChkdTeamList}
            sendIsReadyCpnt={(isReady) => isReady && setSrchSltReadyList(prev => [...prev, 'teamSelect'])}
            initChkdList={initList}
          />

          {/* 프로젝트 셀렉트 */}
          <SearchSelect
            id="projectSelect"
            label="프로젝트"
            inputLabel="프로젝트 선택"
            isEssential={false}
            mappingInfo={{ key: 'id', value: 'pname', preValue: 'pkey' }}
            apiName="filter/getProjectList"
            isGetMethod={true}
            handleChkdList={setChkdProjectList}
            sendIsReadyCpnt={(isReady) => isReady && setSrchSltReadyList(prev => [...prev, 'projectSelect'])}
            initChkdList={initList}
          />

          {/* 이슈 셀렉트 */}
          <SearchSelect
            id="issueSelect"
            label="이슈"
            inputLabel="이슈 선택"
            isEssential={false}
            mappingInfo={{ key: 'id', value: 'summary', preValue: 'issuekey' }}
            apiName="filter/getIssueList"
            isGetMethod={true}
            handleChkdList={setChkdIssueList}
            sendIsReadyCpnt={(isReady) => isReady && setSrchSltReadyList(prev => [...prev, 'issueSelect'])}
            initChkdList={initList}
          />

          {/* 유저 셀렉트 */}
          <SearchSelect
            id="userSelect"
            label="유저"
            inputLabel="유저 선택"
            isEssential={false}
            mappingInfo={{ key: 'id', value: 'displayname', postValue: 'username' }}
            apiName="filter/getUserList"
            isGetMethod={true}
            handleChkdList={setChkdUserList}
            sendIsReadyCpnt={(isReady) => isReady && setSrchSltReadyList(prev => [...prev, 'userSelect'])}
            initChkdList={initList}
          />
        </div>

        {/* 그리드 top 영역 */}
        <div className="grid-top-area">
          {/* 페이지 사이즈 셀렉트 */}
          <div className="grid-page-size-select-area" >
            {/* excel download 버튼 */}
            <Button
              className="create small"
              onClick={csvOnclick}>
              엑셀 다운로드
            </Button>
            <CSVLink
              data={excelData}
              headers={excelHead}
              ref={csvLink}
              className='hiddenExcel'
              filename='allview_report'
            />

            <div className="grid-page-size-select-area-btn" id="grid-page-size-select">
              <p className="page-size-select-title">Page Size:</p>
              <select
                value={pageSize}
                onChange={onPageSizeChanged}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>        

          <div className="grid-top-btn">
            {/* create 버튼 */}
            <Box
              sx={{ '& button': { m: 1 } }}
            >
              <Button
                className="create small"
                onClick={openModal}
              >
                + 작업 기록
              </Button>
            </Box>
          </div>
        </div>
        <div className="ag-theme-alpine list-grid-div">
          <AgGridReact
            className="ag-grid"
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            animateRows="true"
            rowSelection="single"
            enableRangeSelection="true"
            suppressRowClickSelection="true"
            rowHeight="38"
            pagination="true"
            paginationPageSize={10}
            //rowspan buffer 설정
            rowBuffer="300"
            paginationNumberFormatter={paginationNumberFormatter}
            /*onRowClicked={openDtlModal}*/
            onCellClicked={openDtlModal}
            getRowStyle={getRowStyle}
            pinnedBottomRowData={pinnedBottomRowData}
            //rowspan 병합 처리를 위한 true 설정
            suppressRowTransform="true"
          > 
          </AgGridReact>
          {/* { workRange == 'all' && 
          <div className={styles.workComment}> * 근태는 전체/나의 업무 선택 필터에서 "나의 업무" 선택 후 확인 가능합니다. </div>} */}
          {<div className={styles.workComment}> * 실제 근태와 지라 근태는 차이가 있을 수 있습니다. 실제 근태는 SHRS 시스템, 차이나는 이유는 HELP를 참고해주세요. </div>}
        </div>      
      </div>
    </>
  );
};

export default memo(Main);