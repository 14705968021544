import React, { useState, useEffect, useMemo, memo } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// Css
import styles from './DetailModal.module.css';

// Util
import _ from 'lodash';

const DetailModal = ({
  isOpen = false, 
  isClose = false,
  childNode = null,
  isBackdropClose = true, 
  onClose,
  sx = {
    width: '60%',
    height: '60%',
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '1.5rem'
  }
}) => {
  /* states */
  const [open, setOpen] = React.useState(false);
    
  /* Hooks */
  // props > isOpen 감지
  useEffect(() => {
    if(!isOpen) return;
    handleOpen();
  }, [isOpen]);

  useEffect(() => {
    if(!isClose) return;
    handleClose();
  }, [isClose]);

  
  /* 로컬 함수 */
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose(true);
    };

  return (
    <Modal
        className="common-modal"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box 
          sx={
            _.assign({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '850px',
              height: '70%',
              bgcolor: '#F7F7F7',
              boxShadow: 24,
              borderRadius: '0.5rem',
              overflowY: 'auto'
            }, sx)
          }
         >
          <div className="header">
            <div>시간기록 상세보기</div>
            <svg onClick={handleClose} xmlns="http://www.w3.org/2000/svg" width="1.23076rem" height="1.23076rem" fill="#fff" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
          {childNode}
        </Box>
    </Modal>
  );
};

export default memo(DetailModal);