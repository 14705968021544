import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// Css
import styles from './WorkModal.module.css';

// Util
import _ from 'lodash';
import { Button } from '@mui/material';

const WorkModal = ({
  isOpen = false, 
  childNode = (addInfo) => {},
  isBackdropClose = true, 
  onClose,
  sx = {
    width: '60%',
    height: '60%',
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '1.5rem'
  },
  btnInfo = {
    confirm: {
      name: null,
      callback: null
    },
    cancle: {
      name: null
    }
  } 
}) => {
  /* states */
  const [open, setOpen] = React.useState(false);
  const [addInfo, setAddInfo] = React.useState(null);
    
  /* Hooks */
  // props > isOpen 감지
  useEffect(() => {
    if(!isOpen) return;
    handleOpen();
  }, [isOpen]);

  /* 로컬 함수 */
    const handleOpen = useCallback(() => {

      /*
      if(addInfo == null ) return

      if(addInfo.statechk == 'bottom'){
        btnInfo.confirm = false
      }
*/
      //console.log(childNode)

     // debugger
      setOpen(true)
    },[addInfo]);


    const handleClose = () => {
        setOpen(false);
        onClose(true);
    };

  // confirm 버튼 동작 함수
  const handleConfirm = useCallback(async() => {
    await  btnInfo.confirm.callback({
      "addInfo": addInfo
    });

    
    //근태 관련 유효성 체크 (api - timecuchk)
    if (addInfo.totaltimechk == "WORK"){

    }else if(addInfo.totaltimechk == "STAY"){
      
    return 
    }else{
               


      handleClose();
    }


/*
    //유효성 체크 
    if (addInfo.chkdIssue == null) {
      return;
    } else if (addInfo.ctgValue == "") {
      return;
    } else if (addInfo.comTime == "0h" || addInfo.comTime == "") {
      return;
    } else {
      handleClose();
    }
    */
    handleClose();
  }, [addInfo]);

  const addInfochk = () => {

    if(!addInfo) return;

   //차이 시간 클릭 시, 작업량 등록 버튼 미노출
    if(addInfo.statechk == 'bottom'){
      return (
        <div className="footer">
          
        <Button
          className="cancel xsmall"
          onClick={handleClose}
        >
          {'취소'}
        </Button>
      </div>
      )
    }else{
    return (
      <div className="footer">
      {btnInfo?.confirm?.name && (
        <Button
          className="search xsmall"
          onClick={handleConfirm}
        >
          {btnInfo?.confirm?.name}
        </Button>
      )}
      <Button
        className="cancel xsmall"
        onClick={handleClose}
      >
        {'취소'}
      </Button>
    </div>
    )
  }
  
}

  return (
    <Modal
        className="common-modal"
        open={open}
        //onClose={isBackdropClose && handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box 
          sx={
            _.assign({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '550px',
              bgcolor: '#F7F7F7',
              boxShadow: 24,
              borderRadius: '0.5rem',
            }, sx)
          }
         >
          <div className="header">
            <div>근태시간 등록</div>
            <svg onClick={handleClose} xmlns="http://www.w3.org/2000/svg" width="1.23076rem" height="1.23076rem" fill="#fff" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
        {childNode && childNode?.({ setAddInfo: setAddInfo })}
          {addInfochk()}  
      </Box>
    </Modal>
  );
};

export default memo(WorkModal);