import React, { useState, useEffect, memo, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Css
import './AlertDialog.css';
import styles from './AlertDialog.module.css';

// Util
import _ from 'lodash';

const AlertDialog = ({
  title = '', 
  content = null, 
  isOpen = false, 
  isBackdropClose = false, 
  onClose = (isOpen) => null,
  btnInfo = {
    confirm: {
      name: null,
      callback: null
    },
    cancle: {
      name: null
    }
  }
}) => {
  /* state */
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(!isOpen) return;
    handleOpen();
  }, [isOpen]);

  // alert dialog 오픈
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  // alert dialog 닫기
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.(true);
  }, []);
  
  // confirm 버튼 동작 함수
  const handleConfirm = useCallback(() => {
    btnInfo?.confirm?.callback?.();
    handleClose();
  }, [btnInfo]);

  return (
    <Dialog
      open={open}
      onClose={() => isBackdropClose && handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ m: 0, p: 2 }}
    >
      <div className="dialog-ctt">
        <DialogTitle id="alert-dialog-title" sx={{fontSize: '1.07692rem'}}>
          {title}
        </DialogTitle>
        {content && <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>}
        <DialogActions>
          {btnInfo?.cancle?.name && (
            <Button 
              variant="outlined"
              color='error'
              className="cancleBtn" 
              onClick={handleClose}
            >
              {btnInfo.cancle?.name}
            </Button>
          )}
          {btnInfo?.confirm?.name && (
            <Button 
              variant="outlined"
              className="confirmBtn" 
              onClick={handleConfirm} 
              autoFocus
            >
              {btnInfo?.confirm?.name}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default memo(AlertDialog);